import { config, appFetch } from './appFetch';

export const addGeometricLayerGroup = (json, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer_group`, config('POST', json, language), onSuccess, onErrors);

export const modifyGeometricLayerGroup = (id, json, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer_group/${id}`, config('POST', json, language), onSuccess, onErrors);

export const findAllGeometricLayerGroup = (page, size, sortField, sortOrder, language, onSuccess) => {
    let path = `/geometricLayers/geometric_layer_group?page=${page}&size=${size}`;

    if (sortField !== null) {
        path += `&sortField=${sortField}`;
    }

    if (sortOrder !== null) {
        path += `&sortOrder=${sortOrder}`;
    }
    appFetch(path, config('GET', null, language), onSuccess);
}
export const findListAllGeometricLayerGroup = (language, onSuccess) =>
    appFetch(`/geometricLayers/geometric_layer_group/all`, config('GET', null, language), onSuccess);

export const deleteGeometricLayerGroup = (id, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer_group/${id}`, config('DELETE', null, language), onSuccess, onErrors);

export const addGeometricLayer = (json, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer`, config('POST', json, language), onSuccess, onErrors);

export const modifyGeometricLayer = (id, json, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer/${id}`, config('POST', json, language), onSuccess, onErrors);

export const findAllGeometricLayer = (page, size, sortField, sortOrder, language, onSuccess) => {
    let path = `/geometricLayers/geometric_layer?page=${page}&size=${size}`;

    if (sortField !== null) {
        path += `&sortField=${sortField}`;
    }

    if (sortOrder !== null) {
        path += `&sortOrder=${sortOrder}`;
    }
    appFetch(path, config('GET', null, language), onSuccess);
}

export const deleteGeometricLayer = (id, language, onSuccess, onErrors) =>
    appFetch(`/geometricLayers/geometric_layer/${id}`, config('DELETE', null, language), onSuccess, onErrors);