export const FIND_GEOMETRIC_ELEMENT_TYPE_BY_ID_COMPLETED = "elementType/findGeometricElementTypeByIdCompleted";
export const FIND_ADMIN_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED = "elementType/findAdminGeometricElementsByElementTypeIdCompleted";
export const FIND_USER_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED = "elementType/findUserGeometricElementsByElementTypeIdCompleted";
export const FIND_PUBLIC_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED = "elementType/findPublicGeometricElementsByElementTypeIdCompleted";
export const FIND_ALL_CODES_COMPLETED = "elementType/findAllCodesCompleted";
export const FIND_ALL_GEOMETRIC_ELEMENT_TYPE = "elementType/findAllGeometricElementTypeCompleted";
export const FIND_ALL_ATTIBUTE_TYPE = "elementType/findAllAttributeTypeCompleted";
export const FIND_LIST_ALL_ATTRIBUTE_TYPE_COMPLETED = "geometricElements/findListAllAttributeTypeCompleted";
export const FIND_LIST_ALL_GEOMETRIC_ELEMENT_TYPE_COMPLETED = "geometricElements/findListAllGeometricElementTypeCompleted";
export const INSERT_GEOMETRIC_ELEMENT_TYPE_COMPLETED = "geometricElements/insertGeometricElementTypeCompleted";
export const INSERT_ATTRIBUTE_TYPE_COMPLETED = "geometricElements/insertGeometricElementTypeCompleted";
export const LIST_PROVINCES = "geometricElements/listProvinces";
export const LIST_COUNCILS = "geometricElements/listCouncils";
export const LIST_PARISHES = "geometricElements/listParishes";
export const LIST_COUNTIES = "geometricElements/listCounties";
export const LIST_ENTITY_POPULATIONS = "geometricElements/listEntityPopulations";
export const FIND_LIST_ALL_GEOMETRIC_LAYER_GROUP_COMPLETED = "geometricLayers/findListAllGeometricLayerGroupCompleted";
export const FIND_ALL_GEOMETRIC_LAYER_GROUP_COMPLETED = "geometricLayers/findAllGeometricLayerGroupCompleted";
export const FIND_ALL_GEOMETRIC_LAYER_COMPLETED = "geometricLayers/findAllGeometricLayerCompleted";
export const FIND_TOTAL_GEOMETRIC_ELEMENT_TYPE_COMPLETED = "elementType/findTotalGeometricElementTypeCompleted";
export const FIND_TOTAL_ATTRIBUTE_TYPE_COMPLETED = "elementType/findTotalAttributeTypeCompleted";
export const FIND_TOTAL_GEOMETRIC_LAYER_GROUP_COMPLETED = "geometricLayers/findTotalGeometricLayerGroupCompleted";
export const FIND_TOTAL_GEOMETRIC_LAYER_COMPLETED = "geometricLayers/findTotalGeometricLayerCompleted";
export const MAP_CURRENT_EXTENT = "openlayersmap/mapCurrentExtent";