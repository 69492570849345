import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import geometricElements from '../../geometricElements';
import users from '../../users';
import configurationParameter from '../../configurationParameter';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import * as localeMessages from '../../../i18n/messages';
import * as actions from '../actions';
import * as selectors from '../selectors';
import jQuery from 'jquery';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "ol-layerswitcher/src/ol-layerswitcher.css"
import '../../globalstyles.css';
import 'autocompleter/autocomplete.css';

const App = () => {

    const dispatch = useDispatch();
    const user = useSelector(users.selectors.getUser);
    const language = useSelector(selectors.getLanguage);
    const listAllGeometricLayerGroup = useSelector(geometricElements.selectors.getListAllGeometricLayerGroup);
    const allCodes = useSelector(geometricElements.selectors.getAllCodes);
    const userInterfaceConfigurationParameters = useSelector(configurationParameter.selectors.getUserInterfaceConfigurationParameters);
    const listAllAttributeType = useSelector(geometricElements.selectors.getListAllAttributeType);
    const listAllGeometricElementType = useSelector(geometricElements.selectors.getListAllGeometricElementType);
    const [reloadImages, setReloadImages] = useState(new Date().getTime());

    let locale = "gl";
    let messages;

    useEffect(() => {
        dispatch(actions.loading());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        dispatch(users.actions.tryLoginFromServiceToken(
            () => {
                dispatch(users.actions.logout());
            }
        ));

        if (!allCodes) {
            dispatch(geometricElements.actions.findAllCodes(() => { }));
        }
        if (!userInterfaceConfigurationParameters) {
            dispatch(configurationParameter.actions.findUserInterfaceConfigurationParameters());
        }
        if (!listAllAttributeType) {
            dispatch(geometricElements.actions.findListAllAttributeType());
        }
        if (!listAllGeometricElementType) {
            dispatch(geometricElements.actions.findListAllGeometricElementType());
        }
        dispatch(actions.language(locale));
        if (!listAllGeometricLayerGroup) {
            dispatch(geometricElements.actions.findListAllGeometricLayerGroup());
        }
    });

    useEffect(() => {
        fetch('/datos.json', {})
            .then(function (response) {
                return response.json()
            }).then(function (json) {
                let provinces = json.filter(pro => pro.t === "PR");
                let councils = json.filter(coun => coun.t === "MU");
                let parishes = json.filter(par => par.t === "PA");
                let entityPopulations = json.filter(ep => ep.t === "EP");
                let counties = json.filter(ep => ep.t === "CO");
                function sort(prop) {
                    return function (a, b) {
                        if (a[prop] > b[prop]) {
                            return 1
                        } else if (a[prop] < b[prop]) {
                            return -1
                        }
                        return 0
                    }
                }
                provinces.sort(sort('c'));
                councils.sort(sort('c'));
                parishes.sort(sort('c'));
                entityPopulations.sort(sort('c'));
                counties.sort(sort('c'));
                dispatch(geometricElements.actions.listProvinces(provinces));
                dispatch(geometricElements.actions.listCouncils(councils));
                dispatch(geometricElements.actions.listParishes(parishes));
                dispatch(geometricElements.actions.listEntityPopulations(entityPopulations));
                dispatch(geometricElements.actions.listCounties(counties));
                dispatch(actions.loaded());
            });
        // eslint-disable-next-line
    }, []);

    if (language) {
        locale = language;
        locale = locale.split("-")[0];
        messages = localeMessages.default[locale];
    }

    if (user) {
        locale = user.locale;
        jQuery('#languageSelector').val(user.locale);
        locale = locale.split("-")[0];
        dispatch(actions.language(locale));
        messages = localeMessages.default[locale];
    }

    let appTitle;
    let appFavicon;
    let footerInfo;
    let footerImage1;
    let footerText1;
    let footerImage2;
    let footerText2;
    let footerImage3;
    let footerText3;
    let footerImage4;
    let footerText4;
    let footerImage5;
    let footerText5;
    let footerImage6;
    let footerText6;
    let footerImage7;
    let footerText7;
    let footerImage8;
    let footerText8;
    if (userInterfaceConfigurationParameters) {
        appTitle = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("APP_TITLE") !== -1)[0].value;
        appFavicon = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("APP_FAVICON") !== -1)[0].value;
        footerInfo = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_INFO") !== -1)[0].value;
        footerImage1 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_IMAGE_1") !== -1)[0].value;
        footerText1 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_TEXT_1") !== -1)[0].value;
        footerImage2 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_IMAGE_2") !== -1)[0].value;
        footerText2 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_TEXT_2") !== -1)[0].value;
        footerImage3 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_IMAGE_3") !== -1)[0].value;
        footerText3 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_TEXT_3") !== -1)[0].value;
        footerImage4 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_IMAGE_4") !== -1)[0].value;
        footerText4 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_TEXT_4") !== -1)[0].value;
        footerImage5 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_IMAGE_5") !== -1)[0].value;
        footerText5 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_TEXT_5") !== -1)[0].value;
        footerImage6 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_IMAGE_6") !== -1)[0].value;
        footerText6 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_TEXT_6") !== -1)[0].value;
        footerImage7 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_IMAGE_7") !== -1)[0].value;
        footerText7 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_TEXT_7") !== -1)[0].value;
        footerImage8 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_IMAGE_8") !== -1)[0].value;
        footerText8 = Object.values(userInterfaceConfigurationParameters).filter(parameter =>
            parameter.code.indexOf("FOOTER_TEXT_8") !== -1)[0].value;
    }

    return (
        <IntlProvider locale={locale} messages={messages}>
            <Helmet>
                <title>
                    {appTitle}
                </title>
                <link rel="icon" href={`${appFavicon}?${reloadImages}`} />
                <link rel="apple-touch-icon" href={`${appFavicon}?${reloadImages}`} />
            </Helmet>
            <Router>
                <header>
                    <Header 
                        reloadImages={reloadImages}
                    />
                </header>
                <main role="main" className="flex-shrink-0">
                    <Body
                        reloadImages={reloadImages}
                        setReloadImages={setReloadImages}
                    />
                </main>
                <Footer
                    footerInfo={footerInfo ? footerInfo : "&copy;2020"}
                    footerImage1={footerImage1 ? footerImage1 : ""}
                    footerText1={footerText1 ? footerText1 : ""}
                    footerImage2={footerImage2 ? footerImage2 : ""}
                    footerText2={footerText2 ? footerText2 : ""}
                    footerImage3={footerImage3 ? footerImage3 : ""}
                    footerText3={footerText3 ? footerText3 : ""}
                    footerImage4={footerImage4 ? footerImage4 : ""}
                    footerText4={footerText4 ? footerText4 : ""}
                    footerImage5={footerImage5 ? footerImage5 : ""}
                    footerText5={footerText5 ? footerText5 : ""}
                    footerImage6={footerImage6 ? footerImage6 : ""}
                    footerText6={footerText6 ? footerText6 : ""}
                    footerImage7={footerImage7 ? footerImage7 : ""}
                    footerText7={footerText7 ? footerText7 : ""}
                    footerImage8={footerImage8 ? footerImage8 : ""}
                    footerText8={footerText8 ? footerText8 : ""}
                    reloadImages={reloadImages}
                />
            </Router>
        </IntlProvider>
    );
};

export default App;