import {config, appFetch} from './appFetch';

export const findUserInterfaceConfigurationParameter = (onSuccess, language) => {
    appFetch('/configuration_parameter/user_interface', config('GET', null, language), onSuccess);
}

export const findAllConfigurationParameter = (page, size, sortField, sortOrder, onSuccess, language) => {
    let path = `/configuration_parameter/all?page=${page}&size=${size}`;

    if (sortField) {
        path += `&sortField=${sortField}`;
    }

    if (sortOrder) {
        path += `&sortOrder=${sortOrder}`;
    }

    appFetch(path, config('GET', null, language), onSuccess);
}

export const updateConfigurationParameter = (id, json, language, onSuccess, onErrors) => {
    appFetch(`/configuration_parameter/update/${id}`, config('POST', json, language), onSuccess, onErrors);
}