import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as geometricElementsSelector from '../../geometricElements/selectors';
import * as actions from '../actions';
import { withRouter } from 'react-router-dom';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import UpdateConfigurationParameter from './UpdateConfigurationParameter';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        user: userSelectors.getUser(state),
        allConfigurationParameters: selectors.getAllConfigurationParameters(state),
        allCodes: geometricElementsSelector.getAllCodes(state),
        totalConfigurationParameter: selectors.getTotalConfigurationParameters(state)
    }
}

class AllConfigurationparameters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allConfigurationParameters: null,
            listTotalConfigurationParameters: null,
            currentPage: 1,
            currentSize: 10,
            currentSortOrder: 'id',
            currentSortField: 'asc',
            filters: {},
            backendErrors: null,
            updateConfigurationParameterModalShow: false,
            configurationParameterToUpdate: null
        }
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        if (this.props.user) {

            let handleTableChange = (page, sizePerPage, sortField, sortOrder, filters) => {
                if (Object.entries(filters).length > 0) {
                    let result = this.state.listTotalConfigurationParameters.items.filter((row) => {
                        let valid = true;
                        for (const dataField in filters) {
                            const { filterVal, filterType, comparator } = filters[dataField];

                            if (filterType === 'TEXT') {
                                if (comparator === Comparator.LIKE) {
                                    if (dataField.includes("description")) {
                                        valid = getInternationalization(
                                            this.props.language,
                                            row.code,
                                            this.props.allCodes).toString().toLocaleLowerCase()
                                            .indexOf(filterVal.toLocaleLowerCase()) > -1;
                                    } else {
                                        valid = row[dataField].toString().toLocaleLowerCase().indexOf(
                                            filterVal.toLocaleLowerCase()) > -1;
                                    }
                                } else {
                                    valid = row[dataField].toLocaleLowerCase() === filterVal.toLocaleLowerCase();
                                }
                            }

                            if (filterType === 'SELECT') {
                                if (dataField.includes('dataType')) {
                                    valid = row.attributeType.dataType === filterVal;
                                } else if (dataField.includes('userInterface')) {
                                    valid = row[dataField].toString() === filterVal;
                                }
                            }

                            if (!valid) break;
                        }
                        return valid;
                    });
                    if (sortOrder === 'asc') {
                        result = result.sort((a, b) => {
                            if (a[sortField] > b[sortField]) {
                                return 1;
                            } else if (b[sortField] > a[sortField]) {
                                return -1;
                            }
                            return 0;
                        });
                    } else {
                        result = result.sort((a, b) => {
                            if (a[sortField] > b[sortField]) {
                                return -1;
                            } else if (b[sortField] > a[sortField]) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    let newList = this.state.allConfigurationParameters;
                    const currentIndex = (page - 1) * sizePerPage;
                    newList.result.totalItems = result.length;
                    newList.result.items = result.slice(currentIndex, currentIndex + sizePerPage);
                    this.setState({ allConfigurationParameters: newList });
                } else {
                    this.props.dispatch(actions.findAllConfigurationParameters(
                        page <= 0 ? 0 : page - 1, sizePerPage, sortField, sortOrder, () => {
                            this.setState({
                                allConfigurationParameters: this.props.allConfigurationParameters,
                                listTotalConfigurationParameters: this.props.totalConfigurationParameter
                            });
                        }));
                }
            }

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                jQuery(function () {
                    jQuery('.actionButton').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                    jQuery('.actionButton').tooltip().click(function () {
                        jQuery('.actionButton').tooltip("hide");
                    });
                });

                jQuery(function () {
                    jQuery('.valueTooltip').tooltip({
                        html: true,
                        placement: 'left',
                        trigger: 'click',
                        // Prevent placement flip
                        fallbackPlacement: ['left'],
                        // Show tables and custom styles inside tooltip
                        sanitize: false,
                        boundary: 'window',
                        template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                            '</div><div class="help-tooltip tooltip-inner"></div></div>'
                    });

                    //hide it when clicking anywhere else
                    jQuery('body').on('click', function (e) {
                        jQuery('.valueTooltip').each(function () {
                            //the 'is' for buttons that trigger popups
                            //the 'has' for icons within a button that triggers a popup
                            if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                                jQuery(this).tooltip('hide');
                            }
                        });
                    });
                });

                let dataTypeSelectOptions = [];
                let allDataType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('DATA_TYPE') !== -1);
                allDataType.forEach((dataType, index) => {
                    dataTypeSelectOptions[index] = {
                        value: dataType.code,
                        label: getInternationalization(this.props.language, dataType.code, this.props.allCodes)
                    }
                });
                let isUserInterfaceSelectOptions = [{
                    value: true,
                    label: this.props.intl.formatMessage({ id: 'project.common.yes' })
                }, {
                    value: false,
                    label: this.props.intl.formatMessage({ id: 'project.common.no' })
                }];

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    headerAlign: 'left',
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" })
                    })
                },
                {
                    dataField: 'description',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.configurationParameter.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.code} listInternationalization={this.props.allCodes} />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.configurationParameter.name" }).toLocaleLowerCase() })
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.code, this.props.allCodes)
                },
                {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "project.configurationParameter.code" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.configurationParameter.code" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'attributeType.dataType',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }).toLocaleLowerCase() }),
                        options: dataTypeSelectOptions
                    })
                }, {
                    dataField: 'value',
                    text: this.props.intl.formatMessage({ id: "project.configurationParameter.value" }),
                    headerAlign: 'left',
                    formatExtraData: this.props.reloadImages,
                    formatter: (cellContent, row, rowIndex, formatExtraData) => (
                        row.attributeType.dataType === "IMAGE" ?
                            <img src={`${cellContent}?${formatExtraData}`} className="img-thumbnail" alt={""} />
                            :
                            cellContent
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.configurationParameter.value" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'userInterface',
                    text: this.props.intl.formatMessage({ id: "project.configurationParameter.userInterface" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> : <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.configurationParameter.userInterface" }).toLocaleLowerCase() }),
                        options: isUserInterfaceSelectOptions
                    })
                },
                {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <div className="text-left">
                            <span className="btn-link actionButton"
                                onClick={() =>
                                    this.setState({
                                        backendErrors: null,
                                        updateConfigurationParameterModalShow: true,
                                        configurationParameterToUpdate: row
                                    })
                                }
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modify" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            {row.attributeType.dataType === "FORMATTED_TEXT" && row.value && row.value !== "" ?
                                <span className="btn-link valueTooltip"
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"value" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-html={true}
                                    title={row.value}
                                    data-original-title={row.value}
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </span>
                                : ""
                            }
                        </div>
                    )
                },
                ];

                return (

                    <div className="card card-body">
                        <br />
                        <h3><FormattedMessage id="project.app.Header.admin.configurationParameters" /></h3>

                        {/* Update configuration parameter dialog */}
                        <UpdateConfigurationParameter
                            modalShow={this.state.updateConfigurationParameterModalShow}
                            configurationParameterToUpdate={this.state.configurationParameterToUpdate}
                            backendErrors={this.state.backendErrors}
                            setBackendErrors={setBackendErrors}
                            handleSubmit={() => {
                                this.setState({ updateConfigurationParameterModalShow: false });
                                handleTableChange(this.state.currentPage, this.state.currentSize, this.state.currentSortField,
                                    this.state.currentSortOrder, this.state.filters)
                                this.props.setReloadImages(new Date().getTime());
                                this.props.dispatch(actions.findUserInterfaceConfigurationParameters());
                            }}
                            hideModalWindow={() => this.setState({ updateConfigurationParameterModalShow: false })}
                        />

                        <PaginationProvider
                            pagination={paginationFactory({
                                custom: true,
                                totalSize: this.state.allConfigurationParameters ?
                                    this.state.allConfigurationParameters.result.totalItems : 0,
                                paginationTotalRenderer: (from, to, size) => {
                                    return <FormattedMessage id="project.common.tables.totalElements"
                                        values={{
                                            from: from,
                                            to: to,
                                            total: size
                                        }
                                        }
                                    />
                                }
                            })}
                        >
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                        <div>
                                            <BootstrapTable
                                                bootstrap4
                                                keyField="id"
                                                columns={columns}
                                                data={this.state.allConfigurationParameters ?
                                                    this.state.allConfigurationParameters.result.items : []
                                                }
                                                noDataIndication={this.state.allConfigurationParameters ?
                                                    this.props.intl.formatMessage({ id: "project.configurationParameter.noDataIndication" }) :
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only"></span>
                                                    </div>
                                                }
                                                defaultSorted={[{ dataField: 'id', order: 'asc' }]}
                                                rowClasses="text-left"
                                                filter={filterFactory()}
                                                filterPosition={"top"}
                                                condensed
                                                striped
                                                remote
                                                onTableChange={(type, { page, sizePerPage, sortField, sortOrder, filters }) => {
                                                    this.setState({
                                                        currentPage: page,
                                                        currentSize: sizePerPage,
                                                        currentSortField: sortField,
                                                        currentSortOrder: sortOrder,
                                                        filters: filters
                                                    });
                                                    handleTableChange(page, sizePerPage, sortField, sortOrder, filters);
                                                }}
                                                {...paginationTableProps}
                                            />
                                            <div className="text-right">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                />
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </div>
                                    )
                            }
                        </PaginationProvider>
                        <br />
                    </div>
                );
            }

            if (this.props.user && this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }
            return (
                null
            );
        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }

}

export default withRouter(connect(mapStateToProps)(injectIntl(AllConfigurationparameters)));