export default {
    "project.global.exceptions.NetworkError": 'Could not connect to server',
    'project.app.Header.viewer': 'Geographical viewer',
    'project.app.Header.geometricElements': 'Element tables',
    'project.app.Header.admin': 'Administration',
    'project.app.Header.admin.users': 'Users',
    'project.app.Header.admin.elementType': 'Element types',
    'project.app.Header.admin.attributeType': 'Attribute types',
    'project.app.Header.admin.configurationParameters': 'Configuration parameters',
    'project.app.Header.admin.layer': 'Layers',
    'project.app.Header.admin.layerGroup': 'Layer groups',
    'project.app.Header.myContributions': 'My contributions',
    'project.app.Header.addGeometricElement': 'Add new element',
    'project.app.Header.login': 'Login',
    'project.app.Header.logout': 'Logout',
    'project.app.Header.signUp': 'Sign Up',
    'project.app.Header.password': 'Password',
    'project.app.Body.search': 'Search',
    'project.app.Body.accept': 'Accept',
    'project.app.Body.cancel': 'Cancel',
    'project.app.Body.back': 'Back',
    'project.app.Body.next': 'Next',
    'project.app.Body.save': 'Save',
    'project.app.Body.return': '← Back to home',
    'project.app.Body.field.required': 'Field "{field}" is required',
    'project.app.Body.field.positive': 'Field "{field}" must be positive',

    'project.configurationParameter.name': 'Name',
    'project.configurationParameter.code': 'Code',
    'project.configurationParameter.value': 'Value',
    'project.configurationParameter.userInterface': 'Is user interface?',
    'project.configurationParameter.noDataIndication': 'There are no configuration parameters',

    'project.common.close': 'Close',
    'project.common.ErrorDialog.title': 'Error',
    'project.common.permissionDenied': 'You don\'t have permission to view this page',
    'project.common.mustLogin': 'You must login to view this page',
    'project.common.action': 'Actions',
    'project.common.modify': 'Modify',
    'project.common.center': 'Center',
    'project.common.modifyOnViewer': 'Modify on geographical viewer',
    'project.common.centerOnViewer': 'See on map',
    'project.common.selectOneOption': 'Select one option',
    'project.common.viewElements': 'View elements',
    'project.common.attributes': 'Attributes',
    'project.common.delete': 'Delete',
    'project.common.deleteSomething': 'Delete {something}',
    'project.common.download': 'Download',
    'project.common.viewFiles': 'View photos',
    'project.common.deleteFile': 'Delete file',
    'project.common.addFilesButton': 'Add files',
    'project.common.addFiles': 'Add files to element {id}',
    'project.common.yes': 'Yes',
    'project.common.no': 'No',
    'project.common.filters': 'Filters',
    'project.common.filters.clear': 'Delete all filters',
    'project.common.filepond.labelIdle': 'Drag and drop your files or <span class="filepond--label-action">Browse</span>',
    'project.common.filepond.labelTapCancel': 'Tap to cancel',
    'project.common.filepond.labelLoading': 'Loading',
    'project.common.filepond.labelWaitForSize': 'Waiting for size',
    'project.common.tables.totalElements': 'Showing from {from} to {to} of {total} ',
    'project.common.tables.filter.text.placeholder': 'Search {column}',
    'project.common.tables.filter.select.placeholder': 'Select {column}',

    'project.elements.new': 'Create {element}',
    'project.elements.myElements.message': "This table shows all public elements. See those created by you at {link}",
    'project.elements.anonymous.message': "You must {link} to create new element",
    'project.elements.noElements': 'There are no elements for the selected type',
    'project.elements.types.noElements': 'There are no element types',
    'project.elements.attributes.noAttributes': 'This geometric element typ has no attribute types',
    'project.elements.map.fitToExtentTip': 'See the maximum extension',
    'project.elements.map.zoomToExtentTip': 'Zoom by box',
    'project.elements.map.popupTip': 'Consult information about elements by clicking',
    'project.elements.map.measureLengthTip': 'Measure distances',
    'project.elements.map.measureAreaTip': 'Measure areas',
    'project.elements.map.addElementTip': 'Add new element',
    'project.elements.map.fullScreenTip': 'Full screen',
    'project.elements.map.background': 'Background',
    'project.elements.map.otherLayers': 'Other layers',
    'project.elements.map.myElements': 'My contributions',
    'project.elements.map.elements': 'Elements',
    'project.elements.map.orthophotos': 'Orthophotos',
    'project.elements.map.administrativeLimits': 'Divisions',
    'project.elements.map.cityCouncils': 'City councils',
    'project.elements.map.hygrography': 'Hydrography',
    'project.elements.map.hydrography.channels': 'Channels',
    'project.elements.map.hydrography.reservoirs': 'Reservoirs',
    'project.elements.map.hydrography.lagoons': 'Lagoons',
    'project.elements.map.hydrography.rivers': 'Rivers',
    'project.elements.map.provinces': 'Provinces',
    'project.elements.map.councils': 'Councils',
    'project.elements.map.parishes': 'Parishes',
    'project.elements.map.entityPopulations': 'Entity populations',
    'project.elements.map.counties': 'Counties',
    'project.elements.map.selectProvince': '-- Select province --',
    'project.elements.map.selectCouncil': '-- Select council --',
    'project.elements.map.selectParish': '-- Select parish --',
    'project.elements.map.selectEntityPopulation': '-- Select entity population --',
    'project.elements.map.searchEntityPopulation': 'Search',
    'project.elements.map.progressiveCenter': 'Progressive search',
    'project.elements.map.searchCenter': 'Direct search',
    'project.elements.map.searchType': 'Search type',
    'project.elements.modify': 'Modify {element}',
    'project.elements.selectOneOption': 'Select one option',
    'project.elements.files': 'Associated files',
    'project.elements.files.hideShow': 'Show/Hide associated files',
    'project.elements.files.noFiles': 'This element has no associated files',
    'project.elements.values': 'Values',
    'project.elements.name': 'Name',
    'project.elements.geometryType': 'Geometry type',
    'project.elements.pointStyleName': 'Point style name',
    'project.elements.lineStyleName': 'Line style name',
    'project.elements.polygonStyleName': 'Polygon style name',
    
    'project.elements.description': 'Description',
    'project.elements.geom': 'Geometry',
    'project.elements.geom.LineString': 'Line',
    'project.elements.geom.Point': 'Point',
    'project.elements.geom.Polygon': 'Polygon',
    'project.elements.geom.LinearRing': 'Linear Ring',
    'project.elements.geom.MultiPoint': 'Multipoint',
    'project.elements.geom.MultiLineString': 'MultiLine',
    'project.elements.geom.MultiPolygon': 'Multipolygon',
    'project.elements.geom.Circle': 'Circle',
    'project.elements.files.filename': 'Name',
    'project.elements.files.format': 'Format',
    'project.elements.files.type': 'Mime type',
    'project.elements.files.size': 'Size',
    'project.elements.values.attribute': 'Attribute',
    'project.elements.values.value': 'Value',
    'project.elements.values.order': 'Order',
    'project.elements.creationDate': 'Introduction',
    'project.elements.modificationDate': 'Last modification',
    'project.elements.lessCreationDate': 'Introduction date equal or before than',
    'project.elements.lessModificationDate': 'Modification date equal or before than',
    'project.elements.moreCreationDate': 'Introduction date equal or later than',
    'project.elements.moreModificationDate': 'Modification date equal or later than',
    'project.elements.isPublic': 'Is public?',
    'project.elements.isReviewed': 'Is reviewed?',
    'project.elements.internalComments': 'Internal obs.',
    'project.elements.owner': 'Author',
    'project.elements.type.add': 'Add element type',
    'project.elements.type.add.name': 'Element type name',
    'project.elements.type.modify': 'Modify element type',
    'project.elements.type.modify.name': 'Element type name',
    'project.elements.type.add.attribute': 'Attribute',
    'project.elements.type.showAttributes': 'Show/Hide associated attributes',
    'project.elements.delete.message': 'You\'re going to delete "{element}", Do you want to continue?',
    "project.elements.files.fileNoExistsHeader": 'File does not exist',
    "project.elements.files.fileNoExistsBody": 'File not found',

    'project.elements.attributes.noElements': 'There are no attribute types',
    'project.elements.attributes.dataType': 'Data type',
    'project.elements.attributes.dataType.pick': 'Select the data type from the list',
    'project.elements.attributes.attributeOrder': 'Order',
    'project.elements.attributes.code': 'Code',
    'project.elements.attributes.values': 'Possible values',
    'project.elements.attributes.showHideValues': 'Show/Hide possible values',
    'project.elements.attributes.add': 'Add attribute type',
    'project.elements.attributes.modify': 'Modify attribute type',
    'project.elements.attributes.attributeName': 'Attribute name',
    'project.elements.attributes.help': 'Help',
    'project.elements.attributes.translations': 'Internationalization',
    'project.elements.attributes.maxSize': 'Max size',
    'project.elements.attributes.mandatory': 'Mandatory',
    'project.elements.attributes.notMandatory': 'Not mandatory',
    'project.elements.attributes.type': 'Attribute type',
    'project.elements.attributes.description': 'Description',
    'project.elements.attributes.name': 'Name',

    'project.elements.geometricLayer.layerGroup.name': 'Public name',
    'project.elements.geometricLayer.layerGroup.includesInsideLayerGroup': '"Other layers" subgroup?',
    'project.elements.geometricLayer.layerGroup.order': 'Order',
    'project.elements.geometricLayer.layerGroup.initiallyOpen': 'Initially open?',
    'project.elements.geometricLayer.layerGroup.showLayers': 'Show/Hide associated layers',
    'project.elements.geometricLayer.layerGroup.add': 'Add layer group',
    'project.elements.geometricLayer.layerGroup.deleteMessage': 'You\'re going to delete layer group "{element}", Do you want to continue?',
    'project.elements.geometricLayer.layerGroup.noElements': 'There are no layer groups',
    'project.elements.geometricLayer.layer.internalName': 'Internal name',
    'project.elements.geometricLayer.layer.name': 'Public name',
    'project.elements.geometricLayer.layer.order': 'Order',
    'project.elements.geometricLayer.layer.initiallyVisible': 'Initially visible?',
    'project.elements.geometricLayer.layer.serverUrl': 'Server URL',
    'project.elements.geometricLayer.layer.type': 'Type',
    'project.elements.geometricLayer.layer.internalComments': 'Internal observations',
    'project.elements.geometricLayer.layer.geometricLayerGroup': 'Layer group',
    'project.elements.geometricLayer.layer.add': 'Add layer',
    'project.elements.geometricLayer.layer.modify': 'Modify layer',
    'project.elements.geometricLayer.layer.noElements': 'There are no layers',
    'project.elements.geometricLayer.layer.deleteMessage': 'You\'re going to delete layer "{element}", Do you want to continue?',

    'project.users.password': 'Password',
    'project.users.locale.pick': 'Select a language from the list',
    'project.users.confirmPassword': 'Confirm password',
    'project.users.confirmPassword.error': "Passwords don't match",
    'project.users.locale': 'Language',
    'project.users.locale.galician': 'Galician',
    'project.users.locale.spanish': 'Spanish',
    'project.users.locale.english': 'English',
    'project.users.currentPassword': 'Current password',
    'project.users.newPassword': 'New password',
    'project.users.confirmNewPassword': 'Confirm new password',
    'project.users.modal.modifyUser': '{user} {login}',
    'project.users.login': 'Username',
    'project.users.lock': 'Lock account',
    'project.users.user': 'User',
    'project.users.username': "Username",
    'project.users.locked': "Is locked?",
    'project.users.role': "Role",
    'project.users.changePassword': 'Change password',
    'project.users.createUser': 'Create user',
    'project.users.noDataIndication': 'There are no user accounts'
}