import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import * as selectors from '../selectors'
import * as userSelector from '../../users/selectors';
import * as userActions from '../../users/actions'
import * as parameterSelector from '../../configurationParameter/selectors';
import { useDispatch, connect } from 'react-redux';
import * as geometricElementsSelector from '../../geometricElements/selectors';
import * as geometricElementsActions from '../../geometricElements/actions';
import * as actions from '../actions';
import '../../globalstyles.css'
import InternationalizationRender from './InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Login, SignUp, ChangePassword } from '../../users';

const mapStateToProps = function (state) {

    return {
        language: selectors.getLanguage(state),
        user: userSelector.getUser(state),
        parameters: parameterSelector.getUserInterfaceConfigurationParameters(state),
        listGeometricElementType: geometricElementsSelector.getAllGeometricElementType(state),
        allCodes: geometricElementsSelector.getAllCodes(state),
        allGeometricElementType: geometricElementsSelector.getAllGeometricElementType(state),
        listAllGeometricElementType: geometricElementsSelector.getListAllGeometricElementType(state)
    }
}

const Header = ({ reloadImages, ...props }) => {

    const dispatch = useDispatch();
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [loginInsertFromNavbar, setLoginInsertFromNavbar] = useState(null);
    const [signUpModalShow, setSignUpModalShow] = useState(false);
    const [passwordModalShow, setPasswordModalShow] = useState(false);
    const [backendErrors, setBackendErrors] = useState(null);
    const handleOpenLoginModalWindow = (insertFromNavbar) => {
        setBackendErrors(null);
        setLoginModalShow(true);
        setLoginInsertFromNavbar(insertFromNavbar);
    }
    const handleOpenSignUpModalWindow = () => {
        setBackendErrors(null);
        setLoginModalShow(false);
        setSignUpModalShow(true);
    }
    const handleOpenPasswordModalWindow = () => {
        setBackendErrors(null);
        setPasswordModalShow(true)
    }
    let intl = useIntl();

    let appTitle;
    let navbarLogo;
    let navbarLink;

    if (props.parameters && props.listAllGeometricElementType && props.allCodes) {
        appTitle = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("APP_TITLE") !== -1);
        navbarLogo = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("NAVBAR_LOGO") !== -1);
        navbarLink = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("NAVBAR_IMAGE_LINK") !== -1);
        if (appTitle.length !== 0)
            appTitle = appTitle[0].value;
        if (navbarLogo.length !== 0)
            navbarLogo = navbarLogo[0].value;
        if (navbarLink.length !== 0)
            navbarLink = navbarLink[0].value;

        let resetMapCurrentExtent = () => {
            if(props.history.location.pathname!=="/"){
                dispatch(geometricElementsActions.mapCurrentExtent(null))
            }
        }

        return (
            <div>
                <Login
                    modalShow={loginModalShow}
                    insertFromNavBar={loginInsertFromNavbar}
                    backendErrors={backendErrors}
                    setBackendErrors={setBackendErrors}
                    hideModalWindow={() => setLoginModalShow(false)}
                    handleOpenSignUpModalWindow={handleOpenSignUpModalWindow}
                />
                <SignUp
                    modalShow={signUpModalShow}
                    backendErrors={backendErrors}
                    handleAddNewUser={null}
                    setBackendErrors={setBackendErrors}
                    insertFromNavBar={loginInsertFromNavbar}
                    hideModalWindow={() => setSignUpModalShow(false)}
                />
                <ChangePassword
                    modalShow={passwordModalShow}
                    backendErrors={backendErrors}
                    user={props.user}
                    setBackendErrors={setBackendErrors}
                    hideModalWindow={() => setPasswordModalShow(false)}
                />
                <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">

                    {navbarLink !== "" ?
                        <a className="navbar-brand" href={navbarLink}>
                            <img src={`${navbarLogo}?${reloadImages}`} alt={appTitle ? appTitle : ""} style={{ maxHeight: "4vh" }} />
                        </a>
                        :
                        <Link className="navbar-brand" to="/"
                            onClick={() => {
                                dispatch(geometricElementsActions.mapCurrentExtent(null));
                            }}
                        >
                            {navbarLogo && navbarLogo !== "" ?
                                <img src={`${navbarLogo}?${reloadImages}`} alt={appTitle ? appTitle : ""} style={{ maxHeight: "4vh" }} />
                                :
                                <span>{appTitle}</span>
                            }

                        </Link>
                    }

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapseContent"
                        aria-controls="navbarCollapseContent" aria-expanded="false" aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarCollapseContent">

                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item" id="geographicaViewerButton">
                                <Link className="nav-link" to="/"
                                    onClick={() => {
                                        dispatch(geometricElementsActions.mapCurrentExtent(null));
                                    }}
                                >
                                    <FormattedMessage id="project.app.Header.viewer" />
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" data-toggle="dropdown" id="geometricElementsByType">
                                    <FormattedMessage id="project.app.Header.geometricElements" />
                                </a>
                                <div className="dropdown-menu">
                                    {props.listAllGeometricElementType.length === 0 ?
                                        <span className="dropdown-item disabled">
                                            <FormattedMessage id="project.elements.types.noElements" />
                                        </span>
                                        :
                                        props.listAllGeometricElementType.map((geometricElement, index) =>
                                            <Link className="dropdown-item"
                                                to={"/geometric_elements/" + geometricElement.id}
                                                id={"elementType" + index}
                                                key={geometricElement.id}
                                            >
                                                <InternationalizationRender
                                                    locale={props.language}
                                                    value={geometricElement.code}
                                                    listInternationalization={props.allCodes}
                                                />
                                            </Link>
                                        )}
                                </div>
                            </li>

                            {props.user ?
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/" data-toggle="dropdown" id="myGeometricElements">
                                        <FormattedMessage id="project.app.Header.myContributions" />
                                    </a>
                                    <div className="dropdown-menu">
                                        {props.listAllGeometricElementType.length === 0 ?
                                            <span className="dropdown-item disabled">
                                                <FormattedMessage id="project.elements.types.noElements" />
                                            </span>
                                            :
                                            props.listAllGeometricElementType.map((geometricElement, index) =>
                                                <Link className="dropdown-item"
                                                    to={"/geometric_elements/" + geometricElement.id + "/myElements"}
                                                    key={geometricElement.id}
                                                    id={"myElementsFromType" + index}
                                                >
                                                    <InternationalizationRender
                                                        locale={props.language}
                                                        value={geometricElement.code}
                                                        listInternationalization={props.allCodes}
                                                    />
                                                </Link>
                                            )}
                                    </div>
                                </li>
                                : ""}

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" data-toggle="dropdown" id="addGeometricElement"
                                    onClick={() => {
                                        resetMapCurrentExtent();
                                    }}
                                >
                                    <FormattedMessage id="project.app.Header.addGeometricElement" />
                                </a>
                                <div className="dropdown-menu">
                                    {props.listAllGeometricElementType.length === 0 ?
                                        <span className="dropdown-item disabled">
                                            <FormattedMessage id="project.elements.types.noElements" />
                                        </span>
                                        :
                                        props.listAllGeometricElementType.map((geometricElement) =>
                                            geometricElement.geometryType === "ANY_GEOMETRY" ?
                                                <div key={geometricElement.id + "Geometry"}>
                                                    <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                            if (!props.user) {
                                                                handleOpenLoginModalWindow(geometricElement.id + "-Point");
                                                            } else {
                                                                props.history.push({
                                                                    pathname: "/insertFromNavBar",
                                                                    state: {
                                                                        insertFromNavBar: geometricElement.id + "-Point"
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        key={geometricElement.id + "-Point"}
                                                        id={geometricElement.id + '-Point'}
                                                    >
                                                        <InternationalizationRender
                                                            locale={props.language}
                                                            value={geometricElement.code}
                                                            listInternationalization={props.allCodes}
                                                        />
                                                        &nbsp;-&nbsp;
                                                        <FormattedMessage id="project.elements.geom.Point" />
                                                    </span>
                                                    <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        key={geometricElement.id + '-LineString'}
                                                        id={geometricElement.id + '-LineString'}
                                                        onClick={() => {
                                                            dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                            if (!props.user) {
                                                                handleOpenLoginModalWindow(geometricElement.id + "-LineString");
                                                            } else {
                                                                props.history.push({
                                                                    pathname: "/insertFromNavBar",
                                                                    state: {
                                                                        insertFromNavBar: geometricElement.id + "-LineString"
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <InternationalizationRender
                                                            locale={props.language}
                                                            value={geometricElement.code}
                                                            listInternationalization={props.allCodes}
                                                        />
                                                        &nbsp;-&nbsp;
                                                        <FormattedMessage id="project.elements.geom.LineString" />
                                                    </span>
                                                    <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        key={geometricElement.id + "-Polygon"}
                                                        id={geometricElement.id + '-Polygon'}
                                                        onClick={() => {
                                                            dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                            if (!props.user) {
                                                                handleOpenLoginModalWindow(geometricElement.id + "-Polygon");
                                                            } else {
                                                                props.history.push({
                                                                    pathname: "/insertFromNavBar",
                                                                    state: {
                                                                        insertFromNavBar: geometricElement.id + "-Polygon"
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <InternationalizationRender
                                                            locale={props.language}
                                                            value={geometricElement.code}
                                                            listInternationalization={props.allCodes}
                                                        />
                                                        &nbsp;-&nbsp;
                                                        <FormattedMessage id="project.elements.geom.Polygon" />
                                                    </span>
                                                </div>
                                                : geometricElement.geometryType === "POINT_POLYGON" ?
                                                    <div key={geometricElement.id + "Geometry"}>
                                                        <span className="dropdown-item"
                                                            style={{ cursor: 'pointer' }}
                                                            key={geometricElement.id + "-Point"}
                                                            id={geometricElement.id + '-Point'}
                                                            onClick={() => {
                                                                dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                if (!props.user) {
                                                                    handleOpenLoginModalWindow(geometricElement.id + "-Point");
                                                                } else {
                                                                    props.history.push({
                                                                        pathname: "/insertFromNavBar",
                                                                        state: {
                                                                            insertFromNavBar: geometricElement.id + "-Point"
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <InternationalizationRender
                                                                locale={props.language}
                                                                value={geometricElement.code}
                                                                listInternationalization={props.allCodes}
                                                            />
                                                            &nbsp;-&nbsp;
                                                            <FormattedMessage id="project.elements.geom.Point" />
                                                        </span>
                                                        <span className="dropdown-item"
                                                            style={{ cursor: 'pointer' }}
                                                            key={geometricElement.id + "-Polygon"}
                                                            id={geometricElement.id + '-Polygon'}
                                                            onClick={() => {
                                                                dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                if (!props.user) {
                                                                    handleOpenLoginModalWindow(geometricElement.id + "-Polygon");
                                                                } else {
                                                                    props.history.push({
                                                                        pathname: "/insertFromNavBar",
                                                                        state: {
                                                                            insertFromNavBar: geometricElement.id + "-Polygon"
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <InternationalizationRender
                                                                locale={props.language}
                                                                value={geometricElement.code}
                                                                listInternationalization={props.allCodes}
                                                            />
                                                            &nbsp;-&nbsp;
                                                            <FormattedMessage id="project.elements.geom.Polygon" />
                                                        </span>
                                                    </div> :
                                                    geometricElement.geometryType === "POINT_LINE" ?
                                                        <div key={geometricElement.id + "Geometry"}>
                                                            <span className="dropdown-item"
                                                                style={{ cursor: 'pointer' }}
                                                                key={geometricElement.id + "-Point"}
                                                                id={geometricElement.id + '-Point'}
                                                                onClick={() => {
                                                                    dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                    if (!props.user) {
                                                                        handleOpenLoginModalWindow(geometricElement.id + "-Point");
                                                                    } else {
                                                                        props.history.push({
                                                                            pathname: "/insertFromNavBar",
                                                                            state: {
                                                                                insertFromNavBar: geometricElement.id + "-Point"
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <InternationalizationRender
                                                                    locale={props.language}
                                                                    value={geometricElement.code}
                                                                    listInternationalization={props.allCodes}
                                                                />
                                                                &nbsp;-&nbsp;
                                                                <FormattedMessage id="project.elements.geom.Point" />
                                                            </span>
                                                            <span className="dropdown-item"
                                                                style={{ cursor: 'pointer' }}
                                                                key={geometricElement.id + '-LineString'}
                                                                id={geometricElement.id + '-LineString'}
                                                                onClick={() => {
                                                                    dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                    if (!props.user) {
                                                                        handleOpenLoginModalWindow(geometricElement.id + "-LineString");
                                                                    } else {
                                                                        props.history.push({
                                                                            pathname: "/insertFromNavBar",
                                                                            state: {
                                                                                insertFromNavBar: geometricElement.id + "-LineString"
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <InternationalizationRender
                                                                    locale={props.language}
                                                                    value={geometricElement.code}
                                                                    listInternationalization={props.allCodes}
                                                                />
                                                                &nbsp;-&nbsp;
                                                                <FormattedMessage id="project.elements.geom.LineString" />
                                                            </span>
                                                        </div> :
                                                        geometricElement.geometryType === "LINE_POLYGON" ?
                                                            <div key={geometricElement.id + "Geometry"}>
                                                                <span className="dropdown-item"
                                                                    style={{ cursor: 'pointer' }}
                                                                    key={geometricElement.id + '-LineString'}
                                                                    id={geometricElement.id + '-LineString'}
                                                                    onClick={() => {
                                                                        dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                        if (!props.user) {
                                                                            handleOpenLoginModalWindow(geometricElement.id + "-LineString");
                                                                        } else {
                                                                            props.history.push({
                                                                                pathname: "/insertFromNavBar",
                                                                                state: {
                                                                                    insertFromNavBar: geometricElement.id + "-LineString"
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    <InternationalizationRender
                                                                        locale={props.language}
                                                                        value={geometricElement.code}
                                                                        listInternationalization={props.allCodes}
                                                                    />
                                                                    &nbsp;-&nbsp;
                                                                    <FormattedMessage id="project.elements.geom.LineString" />
                                                                </span>
                                                                <span className="dropdown-item"
                                                                    style={{ cursor: 'pointer' }}
                                                                    key={geometricElement.id + "-Polygon"}
                                                                    id={geometricElement.id + '-Polygon'}
                                                                    onClick={() => {
                                                                        dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                        if (!props.user) {
                                                                            handleOpenLoginModalWindow(geometricElement.id + "-Polygon");
                                                                        } else {
                                                                            props.history.push({
                                                                                pathname: "/insertFromNavBar",
                                                                                state: {
                                                                                    insertFromNavBar: geometricElement.id + "-Polygon"
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    <InternationalizationRender
                                                                        locale={props.language}
                                                                        value={geometricElement.code}
                                                                        listInternationalization={props.allCodes}
                                                                    />
                                                                    &nbsp;-&nbsp;
                                                                    <FormattedMessage id="project.elements.geom.Polygon" />
                                                                </span>
                                                            </div> : geometricElement.geometryType === "POINT" ?
                                                                <div key={geometricElement.id + "Geometry"}>
                                                                    <span className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                        key={geometricElement.id + "-Point"}
                                                                        id={geometricElement.id + '-Point'}
                                                                        onClick={() => {
                                                                            dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                            if (!props.user) {
                                                                                handleOpenLoginModalWindow(geometricElement.id + "-Point");
                                                                            } else {
                                                                                props.history.push({
                                                                                    pathname: "/insertFromNavBar",
                                                                                    state: {
                                                                                        insertFromNavBar: geometricElement.id + "-Point"
                                                                                    }
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <InternationalizationRender
                                                                            locale={props.language}
                                                                            value={geometricElement.code}
                                                                            listInternationalization={props.allCodes}
                                                                        />
                                                                        &nbsp;-&nbsp;
                                                                        <FormattedMessage id="project.elements.geom.Point" />
                                                                    </span>
                                                                </div> :
                                                                geometricElement.geometryType === "LINE" ?
                                                                    <div key={geometricElement.id + "Geometry"}>
                                                                        <span className="dropdown-item"
                                                                            style={{ cursor: 'pointer' }}
                                                                            key={geometricElement.id + '-LineString'}
                                                                            id={geometricElement.id + '-LineString'}
                                                                            onClick={() => {
                                                                                dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                                if (!props.user) {
                                                                                    handleOpenLoginModalWindow(geometricElement.id + "-LineString");
                                                                                } else {
                                                                                    props.history.push({
                                                                                        pathname: "/insertFromNavBar",
                                                                                        state: {
                                                                                            insertFromNavBar: geometricElement.id + "-LineString"
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            <InternationalizationRender
                                                                                locale={props.language}
                                                                                value={geometricElement.code}
                                                                                listInternationalization={props.allCodes}
                                                                            />
                                                                            &nbsp;-&nbsp;
                                                                            <FormattedMessage id="project.elements.geom.LineString" />
                                                                        </span>
                                                                    </div> :
                                                                    geometricElement.geometryType === "POLYGON" ?
                                                                        <div key={geometricElement.id + "Geometry"}>
                                                                            <span className="dropdown-item"
                                                                                style={{ cursor: 'pointer' }}
                                                                                key={geometricElement.id + "-Polygon"}
                                                                                id={geometricElement.id + '-Polygon'}
                                                                                onClick={() => {
                                                                                    dispatch(geometricElementsActions.findGeometricElementTypeById(geometricElement.id));
                                                                                    if (!props.user) {
                                                                                        handleOpenLoginModalWindow(geometricElement.id + "-Polygon");
                                                                                    } else {
                                                                                        props.history.push({
                                                                                            pathname: "/insertFromNavBar",
                                                                                            state: {
                                                                                                insertFromNavBar: geometricElement.id + "-Polygon"
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <InternationalizationRender
                                                                                    locale={props.language}
                                                                                    value={geometricElement.code}
                                                                                    listInternationalization={props.allCodes}
                                                                                />
                                                                                &nbsp;-&nbsp;
                                                                                <FormattedMessage id="project.elements.geom.Polygon" />
                                                                            </span>
                                                                        </div> : <div></div>
                                        )
                                    }
                                </div>
                            </li>

                            {props.user ? props.user.userRoleDto.code === "ADMIN" ?
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/" data-toggle="dropdown" id="adminDropdown">
                                        <FormattedMessage id="project.app.Header.admin" />
                                    </a>
                                    <div className="dropdown-menu">
                                        <Link className="dropdown-item" to={"/admin/users"} id="adminUsers">
                                            <FormattedMessage id="project.app.Header.admin.users" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/elementType"} id="adminGeometricElementType">
                                            <FormattedMessage id="project.app.Header.admin.elementType" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/attributeType"} id="adminAttributeType">
                                            <FormattedMessage id="project.app.Header.admin.attributeType" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/configurationParameter"} id="adminConfigurationParameter">
                                            <FormattedMessage id="project.app.Header.admin.configurationParameters" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/layerGroup"} id="adminLayerGroup">
                                            <FormattedMessage id="project.app.Header.admin.layerGroup" />
                                        </Link>
                                        <Link className="dropdown-item" to={"/admin/layers"} id="adminLayers">
                                            <FormattedMessage id="project.app.Header.admin.layer" />
                                        </Link>
                                    </div>
                                </li>
                                : "" : ""}
                        </ul>

                        <ul className="navbar-nav ml-auto">
                            {props.user ?
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/" data-toggle="dropdown" id="userdropdown">
                                        <FontAwesomeIcon icon={faUser} />
                                    &nbsp;
                                    {props.user.login}
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <span className="dropdown-item"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                handleOpenPasswordModalWindow();
                                            }}
                                            id="myPasswordButton"
                                        >
                                            <FormattedMessage id="project.app.Header.password" />
                                        </span>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to='/users/logout' id="logoutButton">
                                            <FormattedMessage id="project.app.Header.logout" />
                                        </Link>
                                    </div>
                                </li>
                                :
                                <li className="nav-item">
                                    <span className="nav-link"
                                        style={{ cursor: 'pointer' }}
                                        id="loginbutton"
                                        onClick={() =>
                                            handleOpenLoginModalWindow(null)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faUserPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.app.Header.login" />
                                    </span>
                                </li>
                            }
                            <li className="nav-item">
                                <select className="custom-select" id="languageSelector" data-width="fit" onChange={() => {
                                    let value = document.getElementById("languageSelector").value;
                                    dispatch(actions.language(value.split("-")[0]));
                                    if (props.user) {
                                        props.user.locale = value;
                                        dispatch(userActions.changeLanguage(props.user));
                                    }
                                }}>
                                    <option value="gl-ES">
                                        {intl.formatMessage({ id: "project.users.locale.galician" })}
                                    </option>
                                    <option value="es-ES">
                                        {intl.formatMessage({ id: "project.users.locale.spanish" })}
                                    </option>
                                    <option value="en-EN">
                                        {intl.formatMessage({ id: "project.users.locale.english" })}
                                    </option>
                                </select>
                            </li>
                        </ul>
                    </div>

                </nav>

            </div>
        );
    } else {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
                <Link className="navbar-brand" to="/">
                    <img src={`${navbarLogo}?${reloadImages}`} alt={appTitle ? appTitle : ""} style={{ maxHeight: "4vh" }} />
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapseContent"
                    aria-controls="navbarCollapseContent" aria-expanded="false" aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapseContent">

                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item" id="geographicaViewerButton">
                            <Link className="nav-link" to="/">
                                <FormattedMessage id="project.app.Header.viewer" />
                            </Link>
                        </li>
                    </ul>
                </div>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <select className="custom-select" id="languageSelector" data-width="fit" onChange={() => {
                            let value = document.getElementById("languageSelector").value;
                            dispatch(actions.language(value.split("-")[0]));
                            if (props.user) {
                                props.user.locale = value;
                                dispatch(userActions.changeLanguage(props.user));
                            }
                        }}>
                            <option value="gl-ES">
                                {intl.formatMessage({ id: "project.users.locale.galician" })}
                            </option>
                            <option value="es-ES">
                                {intl.formatMessage({ id: "project.users.locale.spanish" })}
                            </option>
                            <option value="en-EN">
                                {intl.formatMessage({ id: "project.users.locale.english" })}
                            </option>
                        </select>
                    </li>
                </ul>
            </nav>
        );
    }
};

export default withRouter(connect(mapStateToProps)(Header));