import { config, appFetch } from './appFetch';

export const getElementTypeById = (id, onSuccess, language) =>
    appFetch(`/elements/element_type/${id}`, config('GET', null, language), onSuccess);

export const findGeometricElementById = (id, language, onSuccess, onErrors) =>
    appFetch(`/elements/geometric_element/${id}`, config('GET', null, language), onSuccess, onErrors);

export const addGeoemtricElement = (json, language, onSuccess, onErrors) =>
    appFetch('/elements/geometric_element/', config('POST', json, language), onSuccess, onErrors);

export const modifyGeometricElement = (id, json, language, onSuccess, onErrors) =>
    appFetch(`/elements/geometric_element/${id}`, config('POST', json, language), onSuccess, onErrors);

export const findPublicGeometricElementsByGeometricElementType = (id, page, size, sortField, sortOrder,
    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate,
    attributes, language, onSuccess) => {
    let path = `/elements/geometric_element_type/${id}/geometric_elements?page=${page}&size=${size}`;

    if (sortField && sortField !== "") {
        path += `&sortField=${sortField}`;
    }

    if (sortOrder && sortOrder !== "") {
        path += `&sortOrder=${sortOrder}`;
    }

    if (geometricElementId && geometricElementId !== "") {
        path += `&geometricElementId=${geometricElementId}`
    }

    if (description && description !== "") {
        path += `&description=${description}`
    }

    if (beforeCreationDate && beforeCreationDate !== "") {
        path += `&beforeCreationDate=${beforeCreationDate}`
    }

    if (laterCreationDate && laterCreationDate !== "") {
        path += `&laterCreationDate=${laterCreationDate}`
    }

    if (beforeModificationDate && beforeModificationDate !== "") {
        path += `&beforeModificationDate=${beforeModificationDate}`
    }

    if (laterModificationDate && laterModificationDate !== "") {
        path += `&laterModificationDate=${laterModificationDate}`
    }

    if (attributes && Object.keys(attributes).length > 0) {
        Object.keys(attributes).forEach(attributeId => {
            path += `&${attributeId}=${attributes[attributeId]}`
        })
    }

    appFetch(path, config('GET', null, language), onSuccess);
}

export const findAdminGeometricElementsByGeometricElementType = (id, page, size, sortField, sortOrder, ownerId, isPublic, geometricElementId,
    description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed, internalComments, ownerLogin,
    attributes, language, onSuccess) => {

    let path = `/elements/geometric_element_type/${id}/geometric_elements/admin?page=${page}&size=${size}`;

    if (sortField && sortField !== "") {
        path += `&sortField=${sortField}`;
    }

    if (sortOrder && sortOrder !== "") {
        path += `&sortOrder=${sortOrder}`;
    }

    if (ownerId && ownerId !== "") {
        path += `&ownerId=${ownerId}`;
    }

    if (isPublic && isPublic !== "") {
        path += `&isPublic=${isPublic}`;
    }

    if (geometricElementId && geometricElementId !== "") {
        path += `&geometricElementId=${geometricElementId}`
    }

    if (description && description !== "") {
        path += `&description=${description}`
    }

    if (beforeCreationDate && beforeCreationDate !== "") {
        path += `&beforeCreationDate=${beforeCreationDate}`
    }

    if (laterCreationDate && laterCreationDate !== "") {
        path += `&laterCreationDate=${laterCreationDate}`
    }

    if (beforeModificationDate && beforeModificationDate !== "") {
        path += `&beforeModificationDate=${beforeModificationDate}`
    }

    if (laterModificationDate && laterModificationDate !== "") {
        path += `&laterModificationDate=${laterModificationDate}`
    }

    if (isReviewed && isReviewed !== "") {
        path += `&isReviewed=${isReviewed}`
    }

    if (internalComments && internalComments !== "") {
        path += `&internalComments=${internalComments}`
    }

    if (ownerLogin && ownerLogin !== "") {
        path += `&ownerLogin=${ownerLogin}`
    }

    if (attributes && Object.keys(attributes).length > 0) {
        Object.keys(attributes).forEach(attributeId => {
            path += `&${attributeId}=${attributes[attributeId]}`
        })
    }

    appFetch(path, config('GET', null, language), onSuccess);
}

export const findUserGeometricElementsByGeometricElementType = (id, page, size, sortField, sortOrder, ownerId, isPublic,
    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed,
    attributes, language, onSuccess) => {

    let path = `/elements/geometric_element_type/${id}/geometric_elements/user?page=${page}&size=${size}`;

    if (sortField && sortField !== "") {
        path += `&sortField=${sortField}`;
    }

    if (sortOrder && sortOrder !== "") {
        path += `&sortOrder=${sortOrder}`;
    }

    if (ownerId && ownerId !== "") {
        path += `&ownerId=${ownerId}`;
    }

    if (isPublic && isPublic !== "") {
        path += `&isPublic=${isPublic}`;
    }

    if (geometricElementId && geometricElementId !== "") {
        path += `&geometricElementId=${geometricElementId}`
    }

    if (description && description !== "") {
        path += `&description=${description}`
    }

    if (beforeCreationDate && beforeCreationDate !== "") {
        path += `&beforeCreationDate=${beforeCreationDate}`
    }

    if (laterCreationDate && laterCreationDate !== "") {
        path += `&laterCreationDate=${laterCreationDate}`
    }

    if (beforeModificationDate && beforeModificationDate !== "") {
        path += `&beforeModificationDate=${beforeModificationDate}`
    }

    if (laterModificationDate && laterModificationDate !== "") {
        path += `&laterModificationDate=${laterModificationDate}`
    }

    if (isReviewed && isReviewed !== "") {
        path += `&isReviewed=${isReviewed}`
    }

    if (attributes && Object.keys(attributes).length > 0) {
        Object.keys(attributes).forEach(attributeId => {
            path += `&${attributeId}=${attributes[attributeId]}`
        })
    }

    appFetch(path, config('GET', null, language), onSuccess);
}

export const findAllCodes = (onSuccess, language) =>
    appFetch('/internationalization/codes/', config('GET', null, language), onSuccess);

export const findAllGeometricElementType = (page, size, sortField, sortOrder, language, onSuccess, onErrors) => {
    let path = `/elements/geometric_element_type?page=${page}&size=${size}`;
    if (sortField !== null) {
        path += `&sortField=${sortField}`;
    }

    if (sortOrder !== null) {
        path += `&sortOrder=${sortOrder}`;
    }
    appFetch(path, config('GET', null, language), onSuccess, onErrors);
}

export const findListGeometricElementType = (language, onSuccess) =>
    appFetch(`/elements/geometric_element_type/all/`, config('GET', null, language), onSuccess);

export const deleteGeometricElementType = (id, onSuccess, onErrors, language) => {
    appFetch(`/elements/element_type/${id}`, config('DELETE', null, language), onSuccess, onErrors);
}

export const deleteGeometricElement = (id, language, onSuccess, onErrors) => {
    appFetch(`/elements/geometric_element/${id}`, config('DELETE', null, language), onSuccess, onErrors);
}

export const findAllAttributeType = (page, size, sortField, sortOrder, language, onSuccess, onErrors) => {
    let path = `/attributes/attribute_type/?page=${page}&size=${size}`;

    if (sortField) {
        path += `&sortField=${sortField}`;
    }

    if (sortOrder) {
        path += `&sortOrder=${sortOrder}`;
    }

    appFetch(path, config('GET', null, language), onSuccess, onErrors);
}

export const findListAllAttributeType = (language, onSuccess) =>
    appFetch(`/attributes/attribute_type/all/`, config('GET', null, language), onSuccess);

export const deleteAttributeType = (id, language, onSuccess, onErrors) => {
    appFetch(`/attributes/attribute_type/${id}`, config('DELETE', null, language), onSuccess, onErrors);
}

export const addAttributeType = (json, language, onSuccess, onErrors) => {
    appFetch(`/attributes/attribute_type/`, config('POST', json, language), onSuccess, onErrors);
}

export const addGeometricElementType = (json, language, onSuccess, onErrors) => {
    appFetch(`/elements/element_type/`, config('POST', json, language), onSuccess, onErrors);
}

export const modifyAttributeType = (id, json, language, onSuccess, onErrors) =>
    appFetch(`/attributes/attribute_type/${id}`, config('POST', json, language), onSuccess, onErrors);

export const modifyGeometricElementType = (id, json, language, onSuccess, onErrors) => {
    appFetch(`/elements/element_type/${id}`, config('POST', json, language), onSuccess, onErrors);
}

export const deleteGeometricElementFile = (uuid, language, onSuccess, onErrors) => {
    appFetch(`/elements/geometric_element/files/${uuid}`, config('DELETE', null, language), onSuccess, onErrors);
}
