import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as selectors from '../selectors';
import * as geometricElementSelectors from '../../geometricElements/selectors';

const mapStateToProps = function (state) {

    return {
        locale: selectors.getLanguage(state),
        listInternationalization: geometricElementSelectors.getAllCodes(state)
    }
}

export function getInternationalization(locale, value, listInternationalization) {
    if (listInternationalization) {
        let listTranslations = Object.values(listInternationalization).filter(internationalization =>
            internationalization.code === value)
        if (listTranslations.length !== 0) {
            listTranslations = listTranslations[0].listInternationalizationDto;
        } else {
            return value
        }
        let description = Object.values(listTranslations)
            .filter(translation => translation.language.indexOf(locale) !== -1)[0].description;
        return description;
    }
    return value;
}

class InternationalizationRender extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            locale: props.locale,
            listInternationalization: props.listInternationalization
        }
    }

    render() {

        let { locale, value, listInternationalization } = this.props;

        return getInternationalization(locale, value, listInternationalization)
    }
}

export default withRouter(connect(mapStateToProps)(InternationalizationRender));