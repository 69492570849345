import React, { Component } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import OSM from "ol/source/OSM";
import TileWMS from "ol/source/TileWMS";
import ImageWMS from "ol/source/ImageWMS";
import LayerSwitcher from "ol-layerswitcher";
import Group from 'ol/layer/Group';
import XYZ from 'ol/source/XYZ';
import { Modify } from 'ol/interaction';
import {
  AddGeometricElementController, AreaMeasureController,
  LengthMeasureController, ZoomToExtentController, PopupController, EntityPopulationCenterControl
} from './OpenlayersCustomControllers';
import { defaults as defaultControls, FullScreen, ZoomSlider, OverviewMap, ZoomToExtent } from 'ol/control';
import './popup.css';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Overlay, Collection, Feature } from "ol";
import jQuery from 'jquery';
import { GeoJSON, WFS } from 'ol/format';
import { equalTo as equalToFilter } from 'ol/format/filter';
import { boundingExtent, buffer, getHeight } from 'ol/extent';
import { transform } from 'ol/proj';
import * as userSelectors from '../../users/selectors'
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddGeometricElement from './AddGeometricElement';
import * as elementService from '../../../backend/elementService';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import { injectIntl } from "react-intl";
import * as actions from '../actions';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js'
import { Circle as CircleStyle, Style, Fill, Stroke } from "ol/style";
import GeometricElementFileGallery from "./GeometricElementFileGallery";

const MAP_ID = "map";
const CLICK_PIXEL_TOLERANCE = 10;

const mapStateToProps = function (state) {
  return {
    user: userSelectors.getUser(state),
    locale: appSelectors.getLanguage(state),
    allCodes: selectors.getAllCodes(state),
    geometricElementType: selectors.getGeometricElementType(state),
    allGeometricElementType: selectors.getListAllGeometricElementType(state),
    parameters: configurationParameterSelectors.getUserInterfaceConfigurationParameters(state),
    listProvinces: selectors.getListProvinces(state),
    listCouncils: selectors.getListCouncils(state),
    listParishes: selectors.getListParishes(state),
    listEntityPopulations: selectors.getListEntityPopulations(state),
    listCounties: selectors.getListCounties(state),
    listAllGeometricLayerGroup: selectors.getListAllGeometricLayerGroup(state),
    mapCurrentExtent: selectors.getMapCurrentExtent(state)
  }
}

let hideAllOverlays = (map) => {
  map.getOverlays().forEach(overlay => {
    overlay.setPosition(null);
  });
}

class OlMapTiledWms extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      center: [0, 0],
      zoom: 1,
      geom: null,
      insertFromNavBar: props.location.state && props.location.state.insertFromNavBar ? props.location.state.insertFromNavBar : null,
      featureToModify: props.location.state && props.location.state.featureToModify ? props.location.state.featureToModify : null,
      centerFeature: props.location.state && props.location.state.centerFeature ? props.location.state.centerFeature : null,
      user: props.user,
      locale: props.locale,
      geometricElementFileGalleryModalShow: false,
      geometricElementFileGalleryFiles: null,
      drawInteraction: null,
      dragZoomInteraction: null,
      modifyInteraction: null,
      snapInteraction: null
    };

    this.drawSource = new VectorSource({
      format: new GeoJSON(),
      wrapX: false
    });

    this.handleGeom = geom => {
      this.setState({ geom: geom })
    };

    this.resetModifyFeature = () => {
      this.setState({ featureToModify: null })
    }

    this.resetInsertFromNavBar = () => {
      this.setState({ insertFromNavBar: null })
    }

    this.elementsGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.elements.map.elements' }),
      fold: 'open'
    })

    this.myElementsGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.elements.map.myElements' }),
      fold: 'open'
    })

    this.layerSwitcherControl = new LayerSwitcher({
      activationMode: 'click',
      groupSelectStyle: 'children',
      startActive: true
    });

    this.zoomToExtentImage = document.createElement("span");
    this.zoomToExtentImage.innerHTML = '<img src="/galicia.svg" style="width:1em;height:1em">'

    this.otherLayersGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.elements.map.otherLayers' }),
      fold: 'open',
      layers: [
        new Group({
          title: this.props.intl.formatMessage({ id: 'project.elements.map.hygrography' }),
          fold: 'close',
          layers: [
            new TileLayer({
              title: this.props.intl.formatMessage({ id: 'project.elements.map.hydrography.channels' }),
              visible: false,
              source: new TileWMS({
                projection: 'EPSG:3857',
                url: 'http://ideg.xunta.es/servizos/services/Hidrografia/Hidrografia/MapServer/WmsServer',
                params: { 'layers': ['1', '2', '3', '4'] },
              })
            }),
            new TileLayer({
              title: this.props.intl.formatMessage({ id: 'project.elements.map.hydrography.reservoirs' }),
              visible: false,
              source: new TileWMS({
                projection: 'EPSG:3857',
                url: 'http://ideg.xunta.es/servizos/services/Hidrografia/Hidrografia/MapServer/WmsServer',
                params: { 'layers': ['11', '12', '13', '14', '15', '16', '17'] },
              })
            }),
            new TileLayer({
              title: this.props.intl.formatMessage({ id: 'project.elements.map.hydrography.lagoons' }),
              visible: false,
              source: new TileWMS({
                projection: 'EPSG:3857',
                url: 'http://ideg.xunta.es/servizos/services/Hidrografia/Hidrografia/MapServer/WmsServer',
                params: { 'layers': ['6', '7', '8', '9'] },
              })
            }),
            new TileLayer({
              title: this.props.intl.formatMessage({ id: 'project.elements.map.hydrography.rivers' }),
              visible: false,
              source: new TileWMS({
                projection: 'EPSG:3857',
                url: 'http://ideg.xunta.es/servizos/services/Hidrografia/Hidrografia/MapServer/WmsServer',
                params: {
                  'layers': ['19', '21', '23', '24', '26', '27', '28', '30', '32', '33', '34', '35', '36', '37', '38',
                    '39', '40', '41', '42', '43', '44', '45', '46'
                  ]
                },
              })
            }),
          ]
        }),
      ],
    });

    this.olmap = new Map({
      controls: defaultControls().extend([
        new FullScreen({ tipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.fullScreenTip' }) }),
        new ZoomSlider(),
        new ZoomToExtent({
          extent: this.props.maxExtentParameter,
          label: this.zoomToExtentImage,
          tipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.fitToExtentTip' })
        })
      ]),
      target: null,
      layers: [
        new Group({
          title: this.props.intl.formatMessage({ id: 'project.elements.map.background' }),
          fold: 'open',
          layers: [
            new TileLayer({
              title: 'Google Maps',
              type: 'base',
              projection: 'EPSG:3857',
              source: new XYZ({
                url: 'http://mt0.google.com/vt/lyondrawend openlayersrs=m&hl=gl&x={x}&y={y}&z={z}'
              })
            }),
            new TileLayer({
              title: 'IGN',
              type: 'base',
              source: new TileWMS({
                projection: 'EPSG:3857',
                url: 'http://www.ign.es/wms-inspire/mapa-raster/wms/MTN-Raster/MTN-Raster',
                params: { 'layers': 'mtn_rasterizado' },
              })
            }),
            new TileLayer({
              title: 'OpenStreetMap',
              type: 'base',
              projection: 'EPSG:3857',
              source: new OSM(),
            })
          ]
        }),
        new Group({
          title: this.props.intl.formatMessage({ id: 'project.elements.map.orthophotos' }),
          fold: 'open',
          layers: [
            new TileLayer({
              title: 'PNOA',
              visible: false,
              opacity: 0.7,
              source: new TileWMS({
                projection: 'EPSG:3857',
                url: 'http://www.ign.es/wms-inspire/pnoa-ma',
                params: { 'layers': 'OI.OrthoimageCoverage' },
              })
            }),
          ]
        }),
        this.otherLayersGroup,
        new Group({
          title: this.props.intl.formatMessage({ id: 'project.elements.map.administrativeLimits' }),
          fold: 'close',
          layers: [
            new ImageLayer({
              title: this.props.intl.formatMessage({ id: 'project.elements.map.provinces' }),
              visible: false,
              source: new ImageWMS({
                projection: 'EPSG:3857',
                url: 'http://ideg.xunta.es/servizos/services/LimitesAdministrativos/LimitesAdministrativos/MapServer/WmsServer',
                params: { 'layers': ['16', '17', '18', '19'] },
              })
            }),
            new ImageLayer({
              title: this.props.intl.formatMessage({ id: 'project.elements.map.counties' }),
              visible: true,
              source: new ImageWMS({
                projection: 'EPSG:3857',
                url: 'http://ideg.xunta.es/servizos/services/LimitesAdministrativos/LimitesAdministrativos/MapServer/WmsServer',
                params: { 'layers': ['13', '14'] },
              })
            }),
            new ImageLayer({
              title: this.props.intl.formatMessage({ id: 'project.elements.map.cityCouncils' }),
              visible: false,
              source: new ImageWMS({
                projection: 'EPSG:3857',
                url: 'http://ideg.xunta.es/servizos/services/LimitesAdministrativos/LimitesAdministrativos/MapServer/WmsServer',
                params: { 'layers': ['7', '8', '9', '10', '11'] },
              })
            }),
            new ImageLayer({
              title: this.props.intl.formatMessage({ id: 'project.elements.map.parishes' }),
              visible: false,
              source: new ImageWMS({
                projection: 'EPSG:3857',
                url: 'http://ideg.xunta.es/servizos/services/LimitesAdministrativos/LimitesAdministrativos/MapServer/WmsServer',
                params: { 'layers': ['1', '2', '3', '4', '5'] },
              })
            }),
          ]
        }),
        this.elementsGroup
      ],
      view: new View({
        projection: 'EPSG:3857',
        center: this.state.center,
        zoom: this.state.zoom,
        extent: this.props.maxExtentParameter
      })
    });

  }

  getInternationalization = (locale, value, listInternationalization) => {
    if (listInternationalization) {
      let listTranslations = Object.values(listInternationalization).filter(internationalization =>
        internationalization.code === value)
      if (listTranslations.length !== 0) {
        listTranslations = listTranslations[0].listInternationalizationDto;
      } else {
        return value
      }
      let description = Object.values(listTranslations)
        .filter(translation => translation.language.indexOf(locale) !== -1)[0].description;
      return description;
    }
    return value;
  }

  componentDidMount() {

    this.olmap.on('moveend', () => {
      this.props.dispatch(actions.mapCurrentExtent(this.olmap.getView().calculateExtent()));
    })

    this.removeAllAddedInteractions = () => {
      this.olmap.removeInteraction(this.state.drawInteraction);
      this.olmap.removeInteraction(this.state.dragZoomInteraction);
      this.olmap.removeInteraction(this.state.modifyInteraction);
      this.olmap.removeInteraction(this.state.snapInteraction);
    }

    this.setInteractions = (drawInteraction, dragZoomInteraction, modifyInteraction, snapInteraction) => {
      this.setState({
        drawInteraction, dragZoomInteraction, modifyInteraction, snapInteraction
      });
    }

    this._isMounted = true;
    var map = this.olmap;
    var allCodes = this.props.allCodes;
    let getInternationalization = this.getInternationalization;
    this.olmap.setTarget("map");
    let openAddGeometricElementForm = this.props.openAddGeometricElementForm

    let allGeometricElementType = this.props.allGeometricElementType;

    var container = document.getElementById('popup');
    var content = document.getElementById('popup-content');
    var closer = document.getElementById('popup-closer');

    var overlay = new Overlay({
      element: container,
      autoPan: true,
      offset: [0, -10],
      positioning: 'top-right'
    });

    map.addOverlay(overlay);

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    }

    this.clearDrawSource = () => {
      this.drawSource.clear({ fast: true })
    }

    var drawVector = new VectorLayer({
      source: this.drawSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(200, 0, 0, 0.5)',
        }),
        stroke: new Stroke({
          color: 'rgba(180, 0, 0, 1)',
          width: 3
        }),
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: 'rgba(200, 0, 0, 0.5)',
          }),
          stroke: new Stroke({
            color: 'rgba(180, 0, 0, 1)',
            width: 2
          })
        })
      })
    });

    var measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    var measureTooltipOverlay = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });

    map.addOverlay(measureTooltipOverlay);

    this.handleGeom = geom => {
      this.setState({ geom: geom })
    }

    this.handleModifyFeature = (modifyFeature) => {
      this.setState({ featureToModify: modifyFeature })
    }

    let handleGeom = this.handleGeom;
    let handleModifyFeature = this.handleModifyFeature;
    let locale = this.state.locale;
    let user = this.props.user;

    let modifyFeature = function (map, source, feature) {
      if (jQuery('.layer-switcher').hasClass('shown'))
        jQuery('.layer-switcher button').click();
      if (jQuery('#popupcontrollerdiv').hasClass('ol-control-active'))
        jQuery("#poupcontrollerbutton").click();

      if (!(feature instanceof Feature)) {
        var featureRequest = new WFS().writeGetFeature({
          srsName: 'EPSG:4326',
          featureNS: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          featureTypes: ['geometric_element_point', 'geometric_element_line', 'geometric_element_polygon'],
          outputFormat: 'application/json',
          geometryName: 'EPSG:4326',
          filter: equalToFilter('id', feature.id)
        });

        let configFetch = {
          method: 'POST',
          body: new XMLSerializer().serializeToString(featureRequest)
        }

        if (configFetch.headers) {
          configFetch.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
        } else {
          configFetch.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
        }

        fetch(`${process.env.REACT_APP_BACKEND_URL}/mapserver?SERVICE=WFS`, configFetch)
          .then(function (response) {
            return response.json();
          }).then(function (json) {
            var features = new GeoJSON().readFeatures(json);
            let featureToModify = features[0];
            let clonedFeature = featureToModify.clone();
            clonedFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
            let featureCollection = new Collection([]);
            featureCollection.getArray().splice(0, 0, clonedFeature);
            let modifyInteraction = new Modify({
              source: source
            });
            map.addInteraction(modifyInteraction);
            source.addFeature(clonedFeature);
            modifyInteraction.on('modifyend', function (event) {
              var features = event.features;
              var featureToTransform = features.array_[0].clone()
              var geometry = featureToTransform.getGeometry();
              geometry.transform('EPSG:3857', 'EPSG:4326')
              handleGeom({
                "type": `${geometry.getType()}`,
                "coordinates": geometry.getCoordinates()
              });
            });
            openAddGeometricElementForm(map, clonedFeature.getGeometry());
          });
      } else {
        let clonedFeature = feature.clone();
        clonedFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
        let featureCollection = new Collection([]);
        featureCollection.getArray().splice(0, 0, clonedFeature);
        let modifyInteraction = new Modify({
          source: source
        });
        map.addInteraction(modifyInteraction);
        source.addFeature(clonedFeature);
        modifyInteraction.on('modifyend', function (event) {
          var features = event.features;
          var featureToTransform = features.array_[0].clone();
          var geometry = featureToTransform.getGeometry();
          geometry.transform('EPSG:3857', 'EPSG:4326');
          handleGeom({
            "type": `${geometry.getType()}`,
            "coordinates": geometry.getCoordinates()
          });
        });
        elementService.findGeometricElementById(clonedFeature.get('id'), locale,
          (json) => {
            handleModifyFeature(json);
            openAddGeometricElementForm(map, clonedFeature.getGeometry());
          }, () => console.log("Error"));
      }
    };

    let removeCenterFeature = () => {
      this.setState({ centerFeature: null });
    }

    let centerFeature = function (map, feature) {
      let geometry = null;
      if (feature instanceof Feature) {
        geometry = feature.clone().getGeometry();
      } else {
        geometry = new GeoJSON().readGeometry(feature.geom);
      }
      geometry.transform('EPSG:4326', 'EPSG:3857')

      if (geometry.getType() === 'Point') {
        map.getView().fit(
          buffer(geometry.getExtent(), 50),
          {
            size: map.getSize(),
            duration: 100,
            callback: function () {
              overlay.panIntoView({ margin: 20 });
            }
          }
        );
      } else {
        map.getView().fit(
          buffer(geometry.getExtent(), getHeight(geometry.getExtent())),
          {
            size: map.getSize(),
            duration: 100,
            callback: function () {
              overlay.panIntoView({ margin: 20 });
            }
          }
        );
      }
      removeCenterFeature()
    };

    this.showPopup = (evt, formatMessage, findGeometricElementType, history) => {
      if (!(jQuery('#popupcontrollerdiv').hasClass('ol-control-active'))) {
        return;
      }
      var extent = map.getView().calculateExtent();
      var offsetWidth = document.getElementById(MAP_ID).offsetWidth;
      var metersPerPixel = Math.abs(extent[0] - extent[2]) / offsetWidth;
      var tolerance = this.props.clickPixelTolerance ? this.props.clickPixelTolerance * metersPerPixel : CLICK_PIXEL_TOLERANCE * metersPerPixel;
      let setGeometricElementFileGalleryModalShow = (modalShow) => {
        this.setState({ geometricElementFileGalleryModalShow: modalShow });
      }
      let setGeometricElementFileGalleryFiles = (files) => {
        this.setState({ geometricElementFileGalleryFiles: files });
      }
      var featureRequest = new WFS().writeGetFeature({
        srsName: 'EPSG:4326',
        featureNS: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
        featureTypes: ['geometric_element_point', 'geometric_element_line', 'geometric_element_polygon'],
        outputFormat: 'application/json',
        geometryName: 'EPSG:4326',
        bbox: boundingExtent(
          [
            transform([
              evt.coordinate[0] - tolerance,
              evt.coordinate[1] - tolerance
            ],
              'EPSG:3857', 'EPSG:4326'),
            transform([
              evt.coordinate[0] + tolerance,
              evt.coordinate[1] + tolerance
            ],
              'EPSG:3857', 'EPSG:4326')
          ]
        ),
      });

      let configFetch = {
        method: 'POST',
        body: new XMLSerializer().serializeToString(featureRequest)
      }

      let authorization = {
        method: 'GET',
      }

      if (this.state.user) {
        if (configFetch.headers) {
          configFetch.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
        } else {
          configFetch.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
        }

        if (authorization.headers) {
          authorization.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
        } else {
          authorization.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
        }
      }

      let source = this.drawSource;

      fetch(`${process.env.REACT_APP_BACKEND_URL}/mapserver?SERVICE=WFS`,
        configFetch
      ).then(function (response) {
        return response.json();
      }).then(function (json) {
        var features = new GeoJSON().readFeatures(json);

        if (features.length !== 0) {

          let popupContent = "";
          if (features.length === 1) {
            jQuery(content).html('<div id="accordion">');
            var feature = features[0];
            fetch(`${process.env.REACT_APP_BACKEND_URL}/elements/geometric_element/${feature.get('id')}`,
              authorization
            ).then(function (response) {
              return response.json();
            }).then(function (json) {
              let geometricElement = json;
              popupContent = '<div class="card">' +
                '<div class="card-header text-center" id="heading' + feature.get('id') + '">' +
                '<h5 class="card-title">';
              var geometricElementType = allGeometricElementType.filter(geometricElementType =>
                geometricElementType.id === feature.get("geometric_element_type_id"));
              popupContent += getInternationalization(locale, geometricElementType[0].code, allCodes) +
                " " + feature.get('id') + "<br/>" + feature.get('description') +
                '</h5>' +
                '</div>' +
                '<div class="card-body">' +
                '<div class="justify-content-start">';

              let values = feature.get('value');
              let key = null;
              if (values[0] !== null) {
                values.forEach(value => {
                  key = Object.keys(value)[0];
                  popupContent += '<div class="text-left">' +
                    '<b class="card-title">' + getInternationalization(locale, key, allCodes)
                    + '</b>';
                  popupContent += '<p class="card-text">';
                  value[key].forEach((value, index, array) => {
                    popupContent += getInternationalization(locale,
                      value, allCodes);
                    popupContent += array.length - 1 !== index ? ', ' : '';
                  });
                  popupContent += '</p></div>';
                });
              }

              let internalComments = feature.get('internal_comments');
              internalComments = internalComments && internalComments !== "" ? internalComments : "-";

              if (user) {
                if (user.userRoleDto.code === "ADMIN") {
                  popupContent += '<b class="card-title">' + formatMessage({ id: 'project.elements.internalComments' }) + '</b>';
                  popupContent += '<p class="card-text">' + internalComments + '</p>';
                }
              }

              popupContent += '</div>';

              if (geometricElement) {
                if (geometricElement.listGeometricElementFileDto && geometricElement.listGeometricElementFileDto.filter(
                  file => file.contentType.startsWith("image/")).length) {
                  popupContent += '<span class="btn-link" id="viewFiles' + feature.get('id') + '"data-toggle="tooltip"' +
                    'data-placement="right" title="' + formatMessage({ id: "project.common.viewFiles" }) + '">' +
                    '<i class="fas fa-images"></i>' +
                    '</span>';
                }
              }

              popupContent +=
                '<span class="btn-link" id="center' + feature.get('id') + '" data-toggle="tooltip"' +
                'data-placement="right" title=' + formatMessage({ id: "project.common.center" }) + '>' +
                '<i class="fas fa-map-marker-alt"></i>' +
                '</span>';

              if (user) {
                if ((user.id === feature.get("user_account_id") && !feature.get('is_reviewed')) || user.userRoleDto.code === "ADMIN") {
                  popupContent += '<span class="btn-link" id="modify' + feature.get('id') + '"data-toggle="tooltip"' +
                    'data-placement="right" title=' + formatMessage({ id: "project.common.modify" }) + '>' +
                    '<i class="fas fa-edit"></i>' +
                    '</span>';
                }
              }

              popupContent += '</div>' +
                '</div>';
              jQuery("#accordion").append(popupContent);
              jQuery(function () {
                jQuery('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
              });

              jQuery(function () {
                jQuery('[data-toggle="tooltip"]').tooltip().click(function () {
                  jQuery('[data-toggle="tooltip"]').tooltip("hide");
                });
              });
              jQuery("#center" + feature.get('id')).bind("click", () => centerFeature(map, feature));
              jQuery("#modify" + feature.get('id')).bind("click", () => {
                findGeometricElementType(feature.get('geometric_element_type_id'))
                modifyFeature(map, source, feature)
              });
              jQuery("#viewFiles" + feature.get('id')).bind("click", () => {
                setGeometricElementFileGalleryModalShow(true);
                setGeometricElementFileGalleryFiles(geometricElement.listGeometricElementFileDto);
              });
            });
          } else {
            jQuery(content).html('<div id="accordion">');
            features.forEach(feature => {
              fetch(`${process.env.REACT_APP_BACKEND_URL}/elements/geometric_element/${feature.get('id')}`,
                authorization
              ).then(function (response) {
                return response.json();
              }).then(function (json) {
                let geometricElement = json;
                popupContent = '<div class="card">' +
                  '<div class="card-header" id="heading' + feature.get('id') + '">' +
                  '<h5 class="mb-0">' +
                  '<button class="btn btn-link collapsed" data-toggle="collapse" ' +
                  'data-target="#collapse' + feature.get('id') + '" ' +
                  'aria-expanded="false" ' +
                  'aria-controls="collapse' + feature.get('id') + '">';
                var geometricElementType = allGeometricElementType.filter(geometricElementType =>
                  geometricElementType.id === feature.get("geometric_element_type_id")
                );
                popupContent += getInternationalization(locale, geometricElementType[0].code, allCodes) +
                  " " + feature.get('id') + "<br/>" + feature.get('description') +
                  '</button>' +
                  '</h5>' +
                  '</div>' +
                  '<div id="collapse' + feature.get('id') + '" class="collapse" ' +
                  'aria-labelledby="heading' + feature.get('id') + '"' +
                  'data-parent="#accordion">' +
                  '<div class="card-body">' +
                  '<div class="justify-content-start">';

                let values = feature.get('value');
                let key = null;
                if (values[0] !== null) {
                  values.forEach(value => {
                    key = Object.keys(value)[0];
                    popupContent += '<div class="text-left">' +
                      '<b class="card-title">' + getInternationalization(locale, key, allCodes)
                      + '</b>';
                    popupContent += '<p class="card-text">';
                    value[key].forEach((value, index, array) => {
                      popupContent += getInternationalization(locale, value, allCodes);
                      popupContent += array.length - 1 !== index ? "," : "";
                    });
                    popupContent += '</p></div>'
                  });
                }

                let internalComments = feature.get('internal_comments');
                internalComments = internalComments && internalComments !== "" ? internalComments : "-";

                if (user) {
                  if (user.userRoleDto.code === "ADMIN") {
                    popupContent += '<b class="card-title">' + formatMessage({ id: 'project.elements.internalComments' }) + '</b>';
                    popupContent += '<p class="card-text">' + internalComments + '</p>';
                  }
                }

                popupContent += '</div>';

                if (geometricElement) {
                  if (geometricElement.listGeometricElementFileDto && geometricElement.listGeometricElementFileDto.filter(
                    file => file.contentType.startsWith("image/")).length) {
                    popupContent += '<span class="btn-link" id="viewFiles' + feature.get('id') + '"data-toggle="tooltip"' +
                      'data-placement="right" title="' + formatMessage({ id: "project.common.viewFiles" }) + '">' +
                      '<i class="fas fa-images"></i>' +
                      '</span>';
                  }
                }

                popupContent +=
                  '<span class="btn-link" id="center' + feature.get('id') + '"data-toggle="tooltip"' +
                  'data-placement="right" title=' + formatMessage({ id: "project.common.center" }) + '>' +
                  '<i class="fas fa-map-marker-alt"></i>' +
                  '</span>';

                if (user) {
                  if (user.id === feature.get("user_account_id") || user.userRoleDto.code === "ADMIN") {
                    popupContent += '<span class="btn-link" id="modify' + feature.get('id') + '" data-toggle="tooltip"' +
                      'data-placement="right" title=' + formatMessage({ id: "project.common.modify" }) + '>' +
                      '<i class="fas fa-edit"></i>' +
                      '</span>';
                  }
                }

                popupContent += '</div></div>' +
                  '</div>';
                jQuery("#accordion").append(popupContent);
                jQuery(function () {
                  jQuery('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                  jQuery('[data-toggle="tooltip"]').tooltip().click(function () {
                    jQuery('[data-toggle="tooltip"]').tooltip("hide");
                  });
                });
                jQuery("#center" + feature.get('id')).bind("click", () => centerFeature(map, feature));
                jQuery("#modify" + feature.get('id')).bind("click", () => {
                  findGeometricElementType(feature.get('geometric_element_type_id'))
                  modifyFeature(map, source, feature);
                });
                jQuery("#viewFiles" + feature.get('id')).bind("click", () => {
                  setGeometricElementFileGalleryModalShow(true);
                  setGeometricElementFileGalleryFiles(geometricElement.listGeometricElementFileDto);
                });
              });
            });
          }

          overlay.setPosition(evt.coordinate);
          map.getView().setCenter(evt.coordinate);
        }
      });
    }

    let findGeometricElementType = (id) => {
      this.props.dispatch(actions.findGeometricElementTypeById(id));
    }

    map.addControl(new PopupController({
      overlay: overlay,
      content: content,
      source: this.drawSource,
      allGeometricElementType: allGeometricElementType,
      allCodes: allCodes,
      getInternationalization: getInternationalization,
      showPopup: this.showPopup,
      findGeometricElementType: findGeometricElementType,
      locale: this.state.locale,
      user: this.state.user,
      disableAllInteractions: this.removeAllAddedInteractions,
      hideAllOverlays: hideAllOverlays,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      openAddGeometricElementForm: openAddGeometricElementForm,
      handleGeom: this.handleGeom,
      handleModifyFeature: this.handleModifyFeature,
      formatMessage: this.props.intl.formatMessage,
      history: this.props.history
    }));
    map.addControl(new AreaMeasureController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      overlay: measureTooltipOverlay,
      overlayElement: measureTooltipElement,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new LengthMeasureController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      overlay: measureTooltipOverlay,
      overlayElement: measureTooltipElement,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new ZoomToExtentController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new OverviewMap({
      layers: [new TileLayer({
        source: new OSM()
      })]
    }));
    map.addControl(new EntityPopulationCenterControl({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage,
      listProvinces: this.props.listProvinces,
      listCouncils: this.props.listCouncils,
      listParishes: this.props.listParishes,
      listEntityPopulations: this.props.listEntityPopulations,
      listCounties: this.props.listCounties,
      provincesBuffer: this.props.provincesBuffer,
      councilsBuffer: this.props.councilsBuffer,
      parishesBuffer: this.props.parishesBuffer,
      entityPopulationsBuffer: this.props.entityPopulationsBuffer,
      countiesBuffer: this.props.countiesBuffer
    }));

    if (this.state.user) {
      if (jQuery("#addGeometricElementRootDiv").length === 0) {
        map.addControl(new AddGeometricElementController({
          source: this.drawSource,
          setInteractions: this.setInteractions,
          allGeometricElementType: this.props.allGeometricElementType,
          handleGeom: this.handleGeom,
          openAddGeometricElementForm: openAddGeometricElementForm,
          closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
          dispatch: this.props.dispatch,
          disableAllInteractions: this.removeAllAddedInteractions,
          hideAllOverlays: hideAllOverlays,
          allCodes: this.props.allCodes,
          locale: this.state.locale,
          getInternationalization: this.getInternationalization,
          formatMessage: this.props.intl.formatMessage
        }));
      }
    }

    let addAuthenticationWMSFunction = function (tile, src) {
      var client = new XMLHttpRequest();
      client.open('GET', src);
      client.responseType = "arraybuffer";
      if (sessionStorage.getItem("serviceToken")) {
        client.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("serviceToken"));
      }
      client.onload = function () {
        const arrayBufferView = new Uint8Array(this.response);
        const blob = new Blob([arrayBufferView], { type: 'image/png' });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        tile.getImage().src = imageUrl;
      }
      client.send();
    }

    //One layer per geometric element type
    let layersCollection = new Collection([]);
    //One layer per geometric element type (My elements)
    let myLayersColletion = new Collection([]);

    this.props.allGeometricElementType.forEach((geometricElementType, index) => {
      layersCollection.insertAt(index, new ImageLayer({
        title: this.getInternationalization(
          this.state.locale,
          geometricElementType.code,
          this.props.allCodes
        ),
        source: new ImageWMS({
          url: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          params: {
            'LAYERS': [geometricElementType.code],
            'VERSION': '1.3.0',
            'REQUEST': 'GetMap',
            'TILED': true,
            'STYLES': [
              geometricElementType.pointStyleName ? geometricElementType.pointStyleName : "",
              geometricElementType.lineStyleName ? geometricElementType.lineStyleName : "",
              geometricElementType.polygonStyleName ? geometricElementType.polygonStyleName : "",
            ]
          },
          serverType: 'qgis',
          projection: 'EPSG:3587',
          crossOrigin: 'anonymous',
          imageLoadFunction: addAuthenticationWMSFunction
        }),
        projection: 'EPSG:3587'
      }))

      myLayersColletion.insertAt(index, new ImageLayer({
        title: this.getInternationalization(
          this.state.locale,
          geometricElementType.code,
          this.props.allCodes
        ),
        source: new ImageWMS({
          url: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          params: {
            'LAYERS': ["MY_" + geometricElementType.code],
            'VERSION': '1.3.0',
            'REQUEST': 'GetMap',
            'TILED': true,
            'STYLES': [
              geometricElementType.pointStyleName ? geometricElementType.pointStyleName : "",
              geometricElementType.lineStyleName ? geometricElementType.lineStyleName : "",
              geometricElementType.polygonStyleName ? geometricElementType.polygonStyleName : "",
            ]
          },
          serverType: 'qgis',
          projection: 'EPSG:3587',
          crossOrigin: 'anonymous',
          imageLoadFunction: addAuthenticationWMSFunction
        }),
        projection: 'EPSG:3587'
      }))
    });

    this.elementsGroup.setLayers(layersCollection);

    if (this.state.user) {
      this.olmap.addLayer(this.myElementsGroup);
      this.myElementsGroup.setLayers(myLayersColletion);
    }

    let positiveAllGeometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(layerGroup => layerGroup.order >= 0);
    let negativeAllGeometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(layerGroup => layerGroup.order < 0);
    positiveAllGeometricLayerGroup = positiveAllGeometricLayerGroup.sort((a, b) => {
      return a.order - b.order;
    });

    negativeAllGeometricLayerGroup = negativeAllGeometricLayerGroup.sort((a, b) => {
      return b.order - a.order;
    });

    positiveAllGeometricLayerGroup.forEach(geometricLayerGroup => {
      let otherLayersPosition;
      this.olmap.getLayers().forEach((layer, index) => {
        if (layer.get("title") === this.props.intl.formatMessage({ id: 'project.elements.map.otherLayers' })) {
          otherLayersPosition = index;
        }
      });
      if (geometricLayerGroup.listGeometricLayer.length) {
        let group = this.elementsGroup = new Group({
          title: this.getInternationalization(this.props.locale, geometricLayerGroup.code.code, this.props.allCodes),
          fold: geometricLayerGroup.initiallyOpen ? 'open' : 'close'
        });
        geometricLayerGroup.includeInsideGroupLayer ?
          this.otherLayersGroup.getLayers().insertAt(0, group)
          :
          this.olmap.getLayers().insertAt(otherLayersPosition + 1, group)
        geometricLayerGroup.listGeometricLayer.forEach(geometricLayer => {
          if (geometricLayer.type.code === 'WMS') {
            let layer = new TileLayer({
              title: this.getInternationalization(this.props.locale, geometricLayer.code.code, this.props.allCodes),
              visible: geometricLayer.initiallyVisible ? true : false,
              source: new TileWMS({
                projection: 'EPSG:3857',
                url: geometricLayer.serverUrl,
                params: { 'layers': [geometricLayer.internalName] }
              })
            });
            group.getLayers().insertAt(geometricLayer.order, layer)
          }
        });
      }
    })

    negativeAllGeometricLayerGroup.forEach(geometricLayerGroup => {
      let lastLayerPosition = this.olmap.getLayers().getLength();
      if (geometricLayerGroup.listGeometricLayer.length) {
        let group = this.elementsGroup = new Group({
          title: this.getInternationalization(this.props.locale, geometricLayerGroup.code.code, this.props.allCodes),
          fold: geometricLayerGroup.initiallyOpen ? 'open' : 'close'
        });
        let otherLayersLength = this.otherLayersGroup.getLayers().getLength();
        geometricLayerGroup.includeInsideGroupLayer ?
          this.otherLayersGroup.getLayers().insertAt(otherLayersLength + 1, group)
          :
          this.olmap.getLayers().insertAt(lastLayerPosition + 1, group)
        geometricLayerGroup.listGeometricLayer.forEach(geometricLayer => {
          if (geometricLayer.type.code === 'WMS') {
            let layer = new TileLayer({
              title: this.getInternationalization(this.props.locale, geometricLayer.code.code, this.props.allCodes),
              visible: geometricLayer.initiallyVisible ? true : false,
              source: new TileWMS({
                projection: 'EPSG:3857',
                url: geometricLayer.serverUrl,
                params: { 'layers': [geometricLayer.internalName] }
              })
            });
            group.getLayers().insertAt(geometricLayer.order, layer)
          }
        });
      }
    })

    this.refreshLayersCollection = () => {
      layersCollection.forEach(layer => {
        let params = layer.getSource().getParams();
        params.t = new Date().getMilliseconds();
        layer.getSource().updateParams(params);
      });
      myLayersColletion.forEach(layer => {
        let params = layer.getSource().getParams();
        params.t = new Date().getMilliseconds();
        layer.getSource().updateParams(params);
      });
    }

    map.addLayer(drawVector);

    this.olmap.addControl(this.layerSwitcherControl);

    if (this.state.insertFromNavBar !== null && this.state.insertFromNavBar === this.props.location.state.insertFromNavBar && this.state.user) {
      jQuery("#addGeometricElementSelector").val(this.state.insertFromNavBar).change();
      if (!jQuery("#addGeometricElementDiv").hasClass("ol-control-active")) {
        jQuery("#addGeometricElementButton").click();
      }
    }

    if(this.props.mapCurrentExtent) {
      map.getView().fit(this.props.mapCurrentExtent);
    } else {
      if (this.props.initialExtendParameter) {
        map.getView().fit(this.props.initialExtendParameter);
      }
    }

    if (this.state.centerFeature) {
      centerFeature(this.olmap, this.state.centerFeature)
    }

    if (this.state.featureToModify && this.state.user) {
      modifyFeature(this.olmap, this.drawSource, this.state.featureToModify);
      centerFeature(this.olmap, this.state.featureToModify);
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.location.state) {
      if (typeof prevProps.location.state === 'undefined') {
        if (this.props.location.state.insertFromNavBar) {
          this.setState({ insertFromNavBar: this.props.location.state.insertFromNavBar });
        }
        if (this.props.location.state.featureToModify) {
          this.setState({ featureToModify: this.props.location.state.featureToModify });
        }
      } else {
        if (prevProps.location.state.insertFromNavBar !== this.props.location.state.insertFromNavBar) {
          this.setState({ insertFromNavBar: this.props.location.state.insertFromNavBar });
        }
        if (prevProps.location.state.featureToModify !== this.props.location.state.featureToModify) {
          this.setState({ featureToModify: this.props.location.state.featureToModify });
        }
      }
    }

    if (this.state.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }

    if (this.state.locale !== this.props.locale) {
      this.setState({ locale: this.props.locale });
    }
  }

  render() {

    return (
      <div id="map-gallery-form">
        {/* View photos gallery dialog */}
        <GeometricElementFileGallery
          modalShow={this.state.geometricElementFileGalleryModalShow}
          geometricElementFiles={this.state.geometricElementFileGalleryFiles}
          hideModalWindow={() => this.setState({ geometricElementFileGalleryModalShow: false })}
        />
        <div id="map-container">
          <div id="map" className="openlayers-map" style={{ width: "auto", height: "82vh" }}>
          </div>
          <div id="popup" className="ol-popup">
            <a href="." className="ol-popup-closer btn-link" id="popup-closer">
              <i className="fas fa-times"></i>
            </a>
            <div id="popup-content" className="ol-popup-content"></div>
          </div>
        </div>
        <AddGeometricElement
          geom={this.state.geom}
          closeAddGeometricElementForm={this.props.closeAddGeometricElementForm}
          clearDrawSource={this.clearDrawSource}
          removeAllAddedInteractions={this.removeAllAddedInteractions}
          refreshLayersCollection={this.refreshLayersCollection}
          restartAddGeometricElementInteraction={this.state.restartAddGeometricElementInteraction}
          modifyFeature={this.state.featureToModify}
          map={this.olmap}
          resetModifyFeature={this.resetModifyFeature}
          resetInsertFromNavBar={this.resetInsertFromNavBar}
          formatMessage={this.props.intl.formatMessage}
        />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(injectIntl(OlMapTiledWms)));