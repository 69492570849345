import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as FilePond from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as actions from '../actions';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Errors } from '../../common';
import jQuery from 'jquery';
import PropTypes from 'prop-types';

const AddGeometricElementFile = ({ modalShow, geometricElement, hideModalWindow, handleSubmit, ...props }) => {

    FilePond.registerPlugin(FilePondPluginFileEncode);
    let [backendErrors, setBackendErrors] = useState(null);
    let [formikErrors, setFormikErrors] = useState(null);

    let toFileToInsertJSON = (fileItem) => {
        let fileJSON = {
            "size": fileItem.fileSize,
            "type": fileItem.fileType,
            "filename": fileItem.filename,
            "base64": fileItem.file.uuid ? null : fileItem.getFileEncodeBase64String(),
            "uuid": fileItem.file.uuid ? fileItem.file.uuid : null
        }
        return fileJSON;
    }

    if (geometricElement) {

        let initialFiles = []
        geometricElement.listGeometricElementFileDto.forEach(file => {
            initialFiles.push({
                "size": file.size,
                "type": file.contentType,
                "filename": file.originalFilename,
                "base64": null,
                "uuid": file.uuid
            });
        });

        if (formikErrors || backendErrors) {
            let container = jQuery('.modal-body');
            if (backendErrors) {
                container.scrollTop(0);
            } else {
                if (formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }

        return (
            <Formik
                initialValues={{
                    file: []
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting },) => {
                    var json = {
                        "id": geometricElement.id,
                        "description": geometricElement.description,
                        "geom": geometricElement.geom,
                        "internalComments": values.internalComments,
                        "isPublic": geometricElement.isPublic,
                        "isReviewed": geometricElement.isReviewed,
                        "geometricElementTypeDto": geometricElement.geometricElementType,
                        "listAttributeValueDto": []
                    };

                    json.listFileToModifyDto = initialFiles.concat(values.file);

                    geometricElement.listAttributeValueDto.map(attributeValue => json.listAttributeValueDto.push(attributeValue));

                    props.dispatch(actions.modifyGeometricElement(geometricElement.id, json,
                        () => {
                            handleSubmit();
                        },
                        (error) => setBackendErrors(error))
                    );
                    setSubmitting(false);
                }}
            >
                {({ setFieldValue, errors }) =>
                    <Modal show={modalShow} onHide={hideModalWindow} scrollable={true} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {<FormattedMessage id="project.common.addFiles"
                                    values={{
                                        id: geometricElement.id
                                    }}
                                />}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="addGeometricElementFileForm">

                                {errors ? setFormikErrors(errors) : ""}

                                <Errors errors={backendErrors} onClose={() => setBackendErrors(null)} />

                                <FilePond.FilePond
                                    id="filePond"
                                    allowMultiple={true}
                                    labelIdle={props.intl.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                                    labelTapToCancel={props.intl.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                                    labelFileLoading={props.intl.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                                    labelFileWaitingForSize={props.intl.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                                    onupdatefiles={fileItems => {
                                        setFieldValue("file", fileItems.map(fileItem => toFileToInsertJSON(fileItem)));
                                    }}
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary" id="addGeometricElementFileSubmit" form="addGeometricElementFileForm">
                                <FormattedMessage id="project.app.Body.save" />
                            </button>
                            <Button variant="danger" onClick={hideModalWindow} id="addGeometricElementFileCancel">
                                <FormattedMessage id="project.app.Body.cancel" />
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik >
        );
    }

    return null;

}

AddGeometricElementFile.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    geometricElement: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired,
}

export default connect()(injectIntl(AddGeometricElementFile));