import * as actionTypes from './actionTypes';
import * as appSelectors from '../app/selectors';
import * as selectors from './selectors';
import * as appActions from '../app/actions';
import backend from '../../backend';

const findGeometricElementTypeByIdCompleted = geometricElementType => ({
    type: actionTypes.FIND_GEOMETRIC_ELEMENT_TYPE_BY_ID_COMPLETED,
    geometricElementType
});

export const findGeometricElementTypeById = (id, onSuccess) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.getElementTypeById(id,
        (geometricElementType) => {
            dispatch(findGeometricElementTypeByIdCompleted(geometricElementType));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        },
        appSelectors.getLanguage(getState())
    );
}

const findPublicGeometricElementsByElementTypeIdCompleted = geometricElementsByElementType => ({
    type: actionTypes.FIND_PUBLIC_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED,
    geometricElementsByElementType
});

export const findPublicGeometricElementsByElementTypeId = (id, page, size, sortField, sortOrder, geometricElementId, description,
    beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, attributes, onSuccess) => (dispatch, getState) => {
        dispatch(appActions.loading());
        backend.elementService.findPublicGeometricElementsByGeometricElementType(id, page, size, sortField, sortOrder, geometricElementId,
            description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, attributes,
            appSelectors.getLanguage(getState()), (result) => {
                dispatch(findPublicGeometricElementsByElementTypeIdCompleted({
                    id, page, size, sortField, sortOrder,
                    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, attributes, result
                }));
                onSuccess();
                dispatch(appActions.loaded());
            }
        );
    }

const findUserGeometricElementsByElementTypeIdCompleted = geometricElementsByElementType => ({
    type: actionTypes.FIND_USER_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED,
    geometricElementsByElementType
});

export const findUserGeometricElementsByElementTypeId = (id, page, size, sortField, sortOrder, isPublic, ownerId,
    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed, attributes,
    onSuccess) => (dispatch, getState) => {
        dispatch(appActions.loading());
        backend.elementService.findUserGeometricElementsByGeometricElementType(id, page, size, sortField, sortOrder, ownerId, isPublic,
            geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed,
            attributes, appSelectors.getLanguage(getState()), (result) => {
                dispatch(findUserGeometricElementsByElementTypeIdCompleted({
                    id, page, size, sortField, sortOrder, isPublic, ownerId,
                    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate,
                    isReviewed, attributes, result
                }));
                onSuccess();
                dispatch(appActions.loaded());
            }
        );
    }

const findAdminGeometricElementsByElementTypeIdCompleted = geometricElementsByElementType => ({
    type: actionTypes.FIND_ADMIN_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED,
    geometricElementsByElementType
});

export const findAdminGeometricElementsByElementTypeId = (id, page, size, sortField, sortOrder, isPublic, ownerId, geometricElementId,
    description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed, internalComments,
    ownerLogin, attributes, onSuccess) => (dispatch, getState) => {
        dispatch(appActions.loading());
        backend.elementService.findAdminGeometricElementsByGeometricElementType(id, page, size, sortField, sortOrder, ownerId, isPublic,
            geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate, isReviewed,
            internalComments, ownerLogin, attributes, appSelectors.getLanguage(getState()),
            (result) => {
                dispatch(findAdminGeometricElementsByElementTypeIdCompleted({
                    id, page, size, sortOrder, sortField, isPublic, ownerId,
                    geometricElementId, description, beforeCreationDate, laterCreationDate, beforeModificationDate, laterModificationDate,
                    isReviewed, internalComments, ownerLogin, attributes, result
                }));
                onSuccess();
                dispatch(appActions.loaded());
            }
        );
    }

const findAllCodesCompleted = allCodes => ({
    type: actionTypes.FIND_ALL_CODES_COMPLETED,
    allCodes
});

export const findAllCodes = (onSuccess) => (dispatch, getState) => {
    backend.elementService.findAllCodes(
        allCodes => {
            dispatch(findAllCodesCompleted(allCodes));
            onSuccess();
        }, appSelectors.getLanguage(getState())
    );
}

const findListAllGeometricElementTypeCompleted = listAllGeometricElementType => ({
    type: actionTypes.FIND_LIST_ALL_GEOMETRIC_ELEMENT_TYPE_COMPLETED,
    listAllGeometricElementType
});

export const findListAllGeometricElementType = () => (dispatch, getState) => {
    backend.elementService.findListGeometricElementType(appSelectors.getLanguage(getState()),
        result => dispatch(findListAllGeometricElementTypeCompleted(result))
    );
}

const findListAllAttributeTypeCompleted = listAllAttributeType => ({
    type: actionTypes.FIND_LIST_ALL_ATTRIBUTE_TYPE_COMPLETED,
    listAllAttributeType
});

export const findListAllAttributeType = () => (dispatch, getState) => {
    backend.elementService.findListAllAttributeType(appSelectors.getLanguage(getState()),
        result => dispatch(findListAllAttributeTypeCompleted(result))
    );
}

export const addGeometricElement = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.addGeoemtricElement(json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const modifyGeometricElement = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.modifyGeometricElement(id, json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const findAllGeometricElementTypeCompleted = allGeometricElementType => ({
    type: actionTypes.FIND_ALL_GEOMETRIC_ELEMENT_TYPE,
    allGeometricElementType
});

const findTotalGeometricElementTypeCompleted = totalGeometricElementType => ({
    type: actionTypes.FIND_TOTAL_GEOMETRIC_ELEMENT_TYPE_COMPLETED,
    totalGeometricElementType
});

export const findAllGeometricElementType = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findAllGeometricElementType(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()), result => {
        dispatch(findAllGeometricElementTypeCompleted({ page, size, sortField, sortOrder, result }));
        backend.elementService.findAllGeometricElementType(0, result.totalItems, null, null, appSelectors.getLanguage(getState()),
            totalResult => {
                dispatch(findTotalGeometricElementTypeCompleted(totalResult));
                onSuccess();
                dispatch(appActions.loaded());
            }
        )
    }, (errors) => {
        if (typeof onErrors === 'function') {
            onErrors(errors);
        }
        dispatch(appActions.loaded());
    });
}

export const deleteGeometricElementType = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteGeometricElementType(id,
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        }, appSelectors.getLanguage(getState()));
}

export const deleteGeometricElement = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteGeometricElement(id, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

const findAllAttributeTypeCompleted = allAttributeType => ({
    type: actionTypes.FIND_ALL_ATTIBUTE_TYPE,
    allAttributeType
});

const findTotalAttributeTypeCompleted = totalAttributeType => ({
    type: actionTypes.FIND_TOTAL_ATTRIBUTE_TYPE_COMPLETED,
    totalAttributeType
});

export const findAllAttributeType = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.findAllAttributeType(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()), (result) => {
        dispatch(findAllAttributeTypeCompleted({ page, size, result, sortField, sortOrder }));
        backend.elementService.findAllAttributeType(0, result.totalItems, null, null, appSelectors.getLanguage(getState()),
            (totalResult) => {
                dispatch(findTotalAttributeTypeCompleted(totalResult));
                onSuccess();
                dispatch(appActions.loaded());
            }
        )
    }, (errors) => {
        if (typeof onErrors === 'function') {
            onErrors(errors);
        }
        dispatch(appActions.loaded());
    });
}

export const deleteAttributeType = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteAttributeType(id, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const addAttributeType = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.addAttributeType(json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const addGeometricElementType = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.addGeometricElementType(json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const modifyAttributeType = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.modifyAttributeType(id, json, appSelectors.getLanguage(getState()), () => {
        if (typeof onSuccess === 'function') {
            onSuccess();
        }
        dispatch(appActions.loaded());
    }, (errors) => {
        if (typeof onErrors === 'function') {
            onErrors(errors);
        }
        dispatch(appActions.loaded());
    });
}

export const modifyGeometricElementType = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.modifyGeometricElementType(id, json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const deleteGeometricElementFile = (uuid, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.elementService.deleteGeometricElementFile(uuid, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

//Center by entity populations actions

const listProvincesCompleted = listProvinces => ({
    type: actionTypes.LIST_PROVINCES,
    listProvinces
})

export const listProvinces = (listProvinces) => (dispatch, getState) => {

    if (!selectors.getListProvinces(getState())) {
        dispatch(listProvincesCompleted(listProvinces));
    }
};

const listCouncilsCompleted = listCouncils => ({
    type: actionTypes.LIST_COUNCILS,
    listCouncils
});


export const listCouncils = (listCouncils) => (dispatch, getState) => {

    if (!selectors.getListCouncils(getState())) {
        dispatch(listCouncilsCompleted(listCouncils));
    }
};

const listParishesCompleted = listParishes => ({
    type: actionTypes.LIST_PARISHES,
    listParishes
});

export const listParishes = (listParishes) => (dispatch, getState) => {

    if (!selectors.getListParishes(getState())) {
        dispatch(listParishesCompleted(listParishes));
    }
};

const listEntityPopulationsCompleted = listEntityPopulations => ({
    type: actionTypes.LIST_ENTITY_POPULATIONS,
    listEntityPopulations
});

export const listEntityPopulations = (listEntityPopulations) => (dispatch, getState) => {

    if (!selectors.getListEntityPopulations(getState())) {
        dispatch(listEntityPopulationsCompleted(listEntityPopulations));
    }
};

const listCountiesCompleted = listCounties => ({
    type: actionTypes.LIST_COUNTIES,
    listCounties
});

export const listCounties = (listCounties) => (dispatch, getState) => {

    if (!selectors.getListCounties(getState())) {
        dispatch(listCountiesCompleted(listCounties));
    }
};

//GeometricLayerService actions

const findListAllGeometricLayerGroupCompleted = listAllGeometricLayerGroup => ({
    type: actionTypes.FIND_LIST_ALL_GEOMETRIC_LAYER_GROUP_COMPLETED,
    listAllGeometricLayerGroup
});

export const findListAllGeometricLayerGroup = () => (dispatch, getState) => {
    backend.geometricLayerService.findListAllGeometricLayerGroup(appSelectors.getLanguage(getState()),
        result => dispatch(findListAllGeometricLayerGroupCompleted(result))
    );
}

const findAllGeometricLayerGroupCompleted = allGeometricLayerGroup => ({
    type: actionTypes.FIND_ALL_GEOMETRIC_LAYER_GROUP_COMPLETED,
    allGeometricLayerGroup
});

const findTotalGeometricLayerGroupCompleted = totalGeometricLayerGroup => ({
    type: actionTypes.FIND_TOTAL_GEOMETRIC_LAYER_GROUP_COMPLETED,
    totalGeometricLayerGroup
})

export const findAllGeometricLayerGroup = (page, size, sortField, sortOrder, onSuccess) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.findAllGeometricLayerGroup(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        result => {
            dispatch(findAllGeometricLayerGroupCompleted(result));
            backend.geometricLayerService.findAllGeometricLayerGroup(0, result.totalItems, null, null, appSelectors.getLanguage(getState()),
                (totalResult) => {
                    dispatch(findTotalGeometricLayerGroupCompleted(totalResult));
                    onSuccess();
                    dispatch(appActions.loaded());
                }
            );
        });
}

const findTotalGeometricLayerCompleted = totalGeometricLayer => ({
    type: actionTypes.FIND_TOTAL_GEOMETRIC_LAYER_COMPLETED,
    totalGeometricLayer
})

const findAllGeometricLayerCompleted = allGeometricLayer => ({
    type: actionTypes.FIND_ALL_GEOMETRIC_LAYER_COMPLETED,
    allGeometricLayer
});

export const findAllGeometricLayer = (page, size, sortField, sortOrder, onSuccess) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.findAllGeometricLayer(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        result => {
            dispatch(findAllGeometricLayerCompleted(result));
            backend.geometricLayerService.findAllGeometricLayer(0, result.totalItems, null, null, appSelectors.getLanguage(getState()),
                totalResult => {
                    dispatch(findTotalGeometricLayerCompleted(totalResult));
                    onSuccess();
                    dispatch(appActions.loaded());
                }
            );
        }
    );
}

export const addGeometricLayerGroup = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.addGeometricLayerGroup(json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const addGeometricLayer = (json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.addGeometricLayer(json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const modifyGeometricLayerGroup = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.modifyGeometricLayerGroup(id, json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const modifyGeometricLayer = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.modifyGeometricLayer(id, json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const deleteGeometricLayerGroup = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.deleteGeometricLayerGroup(id, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

export const deleteGeometricLayer = (id, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.geometricLayerService.deleteGeometricLayer(id, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}

// Map current zoom
export const mapCurrentExtent = mapCurrentExtent => ({
    type: actionTypes.MAP_CURRENT_EXTENT,
    mapCurrentExtent
});