import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as FilePond from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Button } from 'react-bootstrap';
import * as selectors from '../selectors';
import * as actions from '../actions';
import * as geometricElementsSelector from '../../geometricElements/selectors';
import * as appSelectors from '../../app/selectors';
import { Errors } from '../../common';
import { Editor } from '@tinymce/tinymce-react'
import jQuery from 'jquery'
import 'filepond/dist/filepond.min.css';
import { getInternationalization } from '../../app/components/InternationalizationRender';
import PropTypes from 'prop-types'

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        allConfigurationParameters: selectors.getAllConfigurationParameters(state),
        allCodes: geometricElementsSelector.getAllCodes(state),
        totalConfigurationParameter: selectors.getTotalConfigurationParameters(state)
    }
}

const UpdateConfigurationParameter = ({ modalShow, backendErrors, configurationParameterToUpdate, setBackendErrors, handleSubmit, hideModalWindow, ...props }) => {

    FilePond.registerPlugin(FilePondPluginFileEncode);

    let [formikErrors, setFormikErrors] = useState(null);

    if (configurationParameterToUpdate && modalShow) {

        if (formikErrors || backendErrors) {
            let container = jQuery('.modal-body');
            if (backendErrors) {
                container.scrollTop(0);
            } else {
                if (formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }

        const renderSwitch = (type, setFieldValue, values) => {
            switch (type) {
                case 'IMAGE':
                    return <FilePond.FilePond
                        id="filePond"
                        allowMultiple={false}
                        labelIdle={props.intl.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                        labelTapToCancel={props.intl.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                        labelFileLoading={props.intl.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                        labelFileWaitingForSize={props.intl.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                        onaddfile={(error, fileItem) => {
                            setFieldValue("value", fileItem.getFileEncodeBase64String());
                            setFieldValue("mimeType", fileItem.fileType);
                            setFieldValue("filename", fileItem.filename);
                        }}

                    />;
                case 'FORMATTED_TEXT':
                    return (
                        <Editor
                            id="value"
                            initialValue={configurationParameterToUpdate.value}
                            init={{
                                height: "75vh",
                                menubar: false,
                                language: props.language,
                                language_url: '/tinymce/' + props.language + '.js',
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar: [
                                    'undo redo | formatselect | bold italic underline backcolor forecolor | link image | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist outdent indent | table | removeformat | help'
                                ]
                            }}
                            onEditorChange={(content, editor) => {
                                setFieldValue("value", content);
                            }}
                        />
                    )
                default:
                    return <Field as="input" name="value" className="form-control" id="value" />;
            }
        };

        // Prevent Bootstrap dialog from blocking focusin
        jQuery(document).on('focusin', function (e) {
            if (jQuery(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
                e.stopImmediatePropagation();
            }
        });

        return (
            <Formik
                enableReinitialize={true}
                initialValues={{ value: configurationParameterToUpdate.value, mimeType: "", filename: "" }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting }) => {
                    var json = {
                        "value": values.value,
                        "mimeType": values.mimeType,
                        "filename": values.filename
                    }
                    props.dispatch(actions.updateConfigurationParameter(configurationParameterToUpdate.id, json,
                        () => {
                            handleSubmit();
                        },
                        errors => {
                            setBackendErrors(errors)
                        }));
                    setSubmitting(false);
                }}
            >
                {({ setFieldValue, values, errors }) =>
                    <Modal show={modalShow} onHide={hideModalWindow} scrollable={true} size="lg" enforceFocus={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {<FormattedMessage id="project.elements.modify"
                                    values={{
                                        element:
                                            getInternationalization(props.language, configurationParameterToUpdate.code, props.allCodes).toLowerCase()
                                    }}
                                />}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="updateConfigurationParameterForm">
                                {errors ? setFormikErrors(errors) : ""}
                                <Errors errors={backendErrors} onClose={() => setBackendErrors(null)} />
                                {
                                    renderSwitch(configurationParameterToUpdate.attributeType.dataType, setFieldValue, values)
                                }
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary" id="updateParameterSubmit"
                                form="updateConfigurationParameterForm"
                            >
                                <FormattedMessage id="project.app.Body.save" />
                            </button>
                            <Button variant="danger" onClick={hideModalWindow} id="updateParameterCancel">
                                <FormattedMessage id="project.app.Body.cancel" />
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik >
        );
    }


    return (null);
}

UpdateConfigurationParameter.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    configurationParameterToUpdate: PropTypes.object.isRequired,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(injectIntl(UpdateConfigurationParameter));