import React from 'react';
import OlMapTiledWms from './OlMapTiledWms';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as parameterSelectors from '../../configurationParameter/selectors'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import jQuery from 'jquery';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        parameters: parameterSelectors.getUserInterfaceConfigurationParameters(state),
        allCodes: selectors.getAllCodes(state),
        allGeometricElementType: selectors.getListAllGeometricElementType(state),
        geometricElementType: selectors.getGeometricElementType(state),
        listProvinces: selectors.getListProvinces(state),
        listCouncils: selectors.getListCouncils(state),
        listParishes: selectors.getListParishes(state),
        listEntityPopulation: selectors.getListEntityPopulations(state),
        listCounties: selectors.getListCounties(state),
        listAllGeometricLayerType: selectors.getListAllGeometricLayerGroup(state)
    }
}

const OpenLayersMap = (props) => {

    let openAddGeometricElementForm = (map, geometry) => {
        jQuery('#map-container').css('margin-right', '30vw');
        jQuery('#addGeometricElementContainer').css('display', 'inline');
        map.updateSize();
    }

    let closeAddGeometricElementForm = (map) => {
        jQuery('#map-container').css('margin-right', '0');
        map.updateSize();
        jQuery('#addGeometricElementContainer').css('display', 'none');
    }

    if (props.allGeometricElementType && props.allCodes && props.parameters && props.listProvinces && props.listCouncils
        && props.listParishes && props.listEntityPopulation && props.listCounties && props.listAllGeometricLayerType
    ) {

        let maxExtentParameter = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("MAP_MAX_EXTENT") !== -1);

        let initialExtendParameter = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("MAP_INITIAL_EXTENT") !== -1);

        let clickPixelTolerance = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("CLICK_PIXEL_TOLERANCE") !== -1);

        let provincesBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("PROVINCES_BUFFER") !== -1);

        let councilsBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("COUNCILS_BUFFER") !== -1);

        let parishesBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("PARISHES_BUFFER") !== -1);

        let entityPopulationsBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("POPULATION_ENTITIES_BUFFER") !== -1);

        let countiesBuffer = Object.values(props.parameters).filter(parameter =>
            parameter.code.indexOf("COUNTIES_BUFFER") !== -1);

        if (maxExtentParameter.length > 0) {
            maxExtentParameter = maxExtentParameter[0].value;
            maxExtentParameter = maxExtentParameter.split(",");
            maxExtentParameter = maxExtentParameter.map(Number);
        }

        if (initialExtendParameter.length > 0) {
            initialExtendParameter = initialExtendParameter[0].value;
            initialExtendParameter = initialExtendParameter.split(",");
            initialExtendParameter = initialExtendParameter.map(Number);
        }

        if (clickPixelTolerance.length > 0) {
            clickPixelTolerance = clickPixelTolerance[0].value;
            clickPixelTolerance = Number(clickPixelTolerance);
        }

        if (provincesBuffer.length > 0) {
            provincesBuffer = provincesBuffer[0].value;
            provincesBuffer = Number(provincesBuffer);
        } else {
            provincesBuffer = 50
        }

        if (councilsBuffer.length > 0) {
            councilsBuffer = councilsBuffer[0].value;
            councilsBuffer = Number(councilsBuffer);
        } else {
            councilsBuffer = 50
        }

        if (parishesBuffer.length > 0) {
            parishesBuffer = parishesBuffer[0].value;
            parishesBuffer = Number(parishesBuffer);
        } else {
            parishesBuffer = 50
        }

        if (entityPopulationsBuffer.length > 0) {
            entityPopulationsBuffer = entityPopulationsBuffer[0].value;
            entityPopulationsBuffer = Number(entityPopulationsBuffer);
        } else {
            entityPopulationsBuffer = 100
        }

        if (countiesBuffer.length > 0) {
            countiesBuffer = countiesBuffer[0].value;
            countiesBuffer = Number(countiesBuffer);
        } else {
            countiesBuffer = 50
        }

        return (
            <OlMapTiledWms
                openAddGeometricElementForm={openAddGeometricElementForm}
                closeAddGeometricElementForm={closeAddGeometricElementForm}
                maxExtentParameter={maxExtentParameter}
                initialExtendParameter={initialExtendParameter}
                clickPixelTolerance={clickPixelTolerance}
                provincesBuffer={provincesBuffer}
                councilsBuffer={councilsBuffer}
                parishesBuffer={parishesBuffer}
                entityPopulationsBuffer={entityPopulationsBuffer}
                countiesBuffer={countiesBuffer}
                key={props.language}
            />
        );
    } else {
        return (
            <div></div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(OpenLayersMap));