import React from 'react';
import * as selectors from '../selectors';
import * as userSelector from '../../users/selectors';
import * as appSelectors from '../../app/selectors';
import * as actions from '../actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faChevronCircleDown, faChevronCircleUp, faEye, faPlus } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { DeleteDialog } from '../../common';
import AddModifyAttributeType from './AddModifyAttributeType';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        user: userSelector.getUser(state),
        allCodes: selectors.getAllCodes(state),
        allAttributeType: selectors.getAllAttributeType(state),
        totalAttributeType: selectors.getTotalAttributeType(state)
    }
}

class AllAttributeType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allAttributeType: null,
            listTotalAttributeType: null,
            expandedRows: [],
            currentPage: 1,
            currentSize: 10,
            currentSortField: 'id',
            currentSortOrder: 'asc',
            backendErrors: null,
            deleteAttributeTypeDialogShow: false,
            deleteAttributeTypeId: "",
            deleteAttributeTypeCode: "",
            addModifyAttributeTypeModalShow: false,
            attributeTypeToModify: null
        }
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    hasHelp = (helpCode) => {
        let hasHelp = false;
        if (helpCode) {
            helpCode.listInternationalizationDto.forEach(help => {
                if (help.description !== "") {
                    hasHelp = true;
                } else {
                    hasHelp = false;
                }
            });
        }

        return hasHelp;
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        jQuery(function () {
            jQuery('.actionButton').tooltip({ trigger: "hover" });
        });

        jQuery(function () {
            jQuery('.actionButton').tooltip().click(function () {
                jQuery('.actionButton').tooltip("hide");
            });
        });

        jQuery(function () {
            jQuery('.helpTooltip').tooltip({
                // html: true,
                placement: 'left',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['left'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });

            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('.helpTooltip').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });
        });

        let handleBtnClick = (row) => {

            if (!this.state.expandedRows.includes(row.id)) {
                this.setState({ expandedRows: [row.id] });
            } else {
                this.setState({ expandedRows: [] });
            }
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                let handleTableChange = (page, sizePerPage, sortField, sortOrder, filters) => {
                    if (Object.entries(filters).length > 0) {
                        let result = this.state.listTotalAttributeType.items.filter((row) => {
                            let valid = true;
                            for (const dataField in filters) {
                                const { filterVal, filterType, comparator } = filters[dataField];

                                if (filterType === 'TEXT') {
                                    if (comparator === Comparator.LIKE) {
                                        if (dataField === 'name') {
                                            valid = getInternationalization(
                                                this.props.language,
                                                row.code.code,
                                                this.props.allCodes).toString().toLocaleLowerCase()
                                                .indexOf(filterVal.toLocaleLowerCase()) > -1;
                                        } else if (dataField === "code.code") {
                                            valid = row.code.code.toLocaleLowerCase().indexOf(
                                                filterVal.toLocaleLowerCase()
                                            ) > -1
                                        } else if (dataField === "helpCode") {
                                            if (row.helpCode && this.hasHelp(row.helpCode)) {
                                                valid = getInternationalization(
                                                    this.props.language,
                                                    row.helpCode.code,
                                                    this.props.allCodes).toString().toLocaleLowerCase()
                                                    .indexOf(filterVal.toLocaleLowerCase()) > -1;
                                            } else {
                                                valid = false
                                            }
                                        } else {
                                            valid = row[dataField].toString().toLocaleLowerCase().indexOf(
                                                filterVal.toLocaleLowerCase()) > -1;
                                        }
                                    } else {
                                        valid = row[dataField].toLocaleLowerCase() === filterVal.toLocaleLowerCase();
                                    }
                                }

                                if (filterType === 'SELECT') {
                                    valid = row[dataField].toString() === filterVal;
                                }

                                if (!valid) break;
                            }
                            return valid;
                        });
                        if (sortOrder === 'asc') {
                            result = result.sort((a, b) => {
                                if (sortField === 'code.code') {
                                    if (a.code.code > b.code.code) {
                                        return 1;
                                    } else if (b.code.code > a.code.code) {
                                        return -1;
                                    }
                                    return 0;
                                } else {
                                    if (a[sortField] > b[sortField]) {
                                        return 1;
                                    } else if (b[sortField] > a[sortField]) {
                                        return -1;
                                    }
                                    return 0;
                                }
                            });
                        } else {
                            result = result.sort((a, b) => {
                                if (sortField === 'code.code') {
                                    if (a.code.code > b.code.code) {
                                        return -1;
                                    } else if (b.code.code > a.code.code) {
                                        return 1;
                                    }
                                    return 0;
                                } else {
                                    if (a[sortField] > b[sortField]) {
                                        return -1;
                                    } else if (b[sortField] > a[sortField]) {
                                        return 1;
                                    }
                                    return 0;
                                }
                            });
                        }
                        let newList = this.state.allAttributeType;
                        const currentIndex = (page - 1) * sizePerPage;
                        newList.result.totalItems = result.length;
                        newList.result.items = result.slice(currentIndex, currentIndex + sizePerPage);
                        this.setState({ allAttributeType: newList });
                    } else {
                        this.props.dispatch(actions.findAllAttributeType(page <= 0 ? 0 : page - 1,
                            sizePerPage, sortField, sortOrder, () => {
                                this.setState({
                                    allAttributeType: this.props.allAttributeType,
                                    listTotalAttributeType: this.props.totalAttributeType
                                });
                            }));
                    }
                }

                let nonExpandableRows = [];
                let dataTypeSelectOptions = [];
                let allDataType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('DATA_TYPE') !== -1);
                allDataType.forEach((dataType, index) => {
                    dataTypeSelectOptions[index] = {
                        value: dataType.code,
                        label: getInternationalization(this.props.language, dataType.code, this.props.allCodes)
                    }
                });

                if (this.state.allAttributeType) {
                    this.state.allAttributeType.result.items.forEach(attributeType => {
                        if (!(attributeType.dataType === "SELECT" || attributeType.dataType === "MULTISELECT")) {
                            nonExpandableRows.push(attributeType.id)
                        }
                    });
                }

                let valuesColumns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true
                }, {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }),
                    headerAlign: 'left',
                    sort: true
                }, {
                    dataField: 'description',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.description" }),
                    headerAlign: 'left',
                    isDummyField: true,
                    formatter: (cellContent, row) => (
                        <InternationalizationRender
                            locale={this.props.language}
                            value={row.code}
                            listInternationalization={this.props.allCodes}
                        />
                    )
                }];

                const expandRow = {
                    renderer: row => (
                        <div className="card card-body" key={row.id + "container"}>
                            <h6 className="card-title text-left" key={row.id + "title"}>
                                <FormattedMessage id="project.elements.attributes.values" />
                            </h6>
                            <BootstrapTable
                                bootstrap4
                                keyField='id'
                                columns={valuesColumns}
                                data={row.valueGroupCode.listCode}
                                defaultSorted={[{ dataField: 'id', order: 'asc' }]}
                                rowClasses="text-left"
                                striped
                                condensed
                                key={row.id + 'table'}
                            />
                        </div>
                    ),
                    expandByColumnOnly: true,
                    expanded: this.state.expandedRows
                }

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" })
                    })
                }, {
                    dataField: 'code.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'name',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.name" }),
                    headerAlign: 'left',
                    isDummyField: true,
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.code.code} listInternationalization={this.props.allCodes} />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.name" }).toLocaleLowerCase() })
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.code.code, this.props.allCodes)
                }, {
                    dataField: 'description',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.description" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.description" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'dataType',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.dataType} listInternationalization={this.props.allCodes} />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }).toLocaleLowerCase() }),
                        options: dataTypeSelectOptions
                    })
                }, {
                    dataField: 'helpCode',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.help" }),
                    headerAlign: 'left',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        if (row.helpCode && this.hasHelp(row.helpCode)) {
                            return (
                                <InternationalizationRender
                                    locale={this.props.language}
                                    value={row.helpCode.code}
                                    listInternationalization={this.props.allCodes}
                                />
                            )
                        } else {
                            return <span>-</span>
                        }
                    },
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.help" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatExtraData: this.state.expandedRows,
                    formatter: (cellContent, row, rowIndex, formatExtraData) => {
                        return (
                            <div className="text-left">
                                <span
                                    className="btn-link actionButton"
                                    onClick={() =>
                                        this.setState({
                                            backendErrors: null,
                                            addModifyAttributeTypeModalShow: true,
                                            attributeTypeToModify: row
                                        })
                                    }
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"modify" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </span>
                                <span className="btn-link actionButton"
                                    onClick={() =>
                                        this.setState({
                                            backendErrors: false,
                                            deleteAttributeTypeDialogShow: true,
                                            deleteAttributeTypeCode: row.code.code,
                                            deleteAttributeTypeId: row.id
                                        })
                                    }
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"delete" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </span>
                                {row.helpCode && this.hasHelp(row.helpCode) ?
                                    <span className="btn-link helpTooltip"
                                        style={{
                                            cursor: "pointer",
                                            marginLeft: "0.5em"
                                        }}
                                        id={"help" + row.id}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-html="true"
                                        title={
                                            getInternationalization(this.props.language, row.helpCode.code, this.props.allCodes)
                                        }
                                        data-original-title={
                                            getInternationalization(this.props.language, row.helpCode.code, this.props.allCodes)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faEye} />
                                    </span> : ""}
                                {!nonExpandableRows.includes(row.id) ?
                                    <span className="btn-link actionButton"
                                        id={"showHideValues" + row.id}
                                        onClick={() => handleBtnClick(row)}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        style={{
                                            cursor: "pointer",
                                            marginLeft: "0.5em"
                                        }}
                                        title={this.props.intl.formatMessage({ id: "project.elements.attributes.showHideValues" })}
                                    >
                                        {<FontAwesomeIcon icon={!formatExtraData.includes(row.id) ? faChevronCircleDown : faChevronCircleUp} />}
                                    </span>
                                    :
                                    ""
                                }
                            </div>
                        )
                    }
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.attributeType" /></h3>
                            <div className="text-right">
                                <button type="button" className="btn btn-primary"
                                    onClick={() =>
                                        this.setState({
                                            backendErrors: null,
                                            addModifyAttributeTypeModalShow: true,
                                            attributeTypeToModify: null
                                        })
                                    }
                                    id="addAttributeType"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.elements.attributes.add" />
                                </button>
                            </div>

                            {/* Add/Modify attribute type dialog */}
                            <AddModifyAttributeType
                                modalShow={this.state.addModifyAttributeTypeModalShow}
                                attributeTypeToModify={this.state.attributeTypeToModify}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({ addModifyAttributeTypeModalShow: false });
                                    handleTableChange(this.state.currentPage, this.state.currentSize, this.state.currentSortField,
                                        this.state.currentSortOrder, this.state.filters);
                                    this.props.dispatch(actions.findListAllAttributeType());
                                }}
                                hideModalWindow={() => this.setState({ addModifyAttributeTypeModalShow: false })}
                            />

                            {/* Delete attribute type dialog */}
                            <DeleteDialog
                                modalShow={this.state.deleteAttributeTypeDialogShow}
                                title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                                details={this.props.intl.formatMessage(
                                    { id: 'project.elements.delete.message' },
                                    { element: <b>{this.state.deleteAttributeTypeCode}</b> })
                                }
                                backendErrors={this.state.backendErrors}
                                hideModalWindow={() => { this.setState({ deleteAttributeTypeDialogShow: false }) }}
                                handleSubmit={() => {
                                    this.props.dispatch(actions.deleteAttributeType(this.state.deleteAttributeTypeId,
                                        () => {
                                            this.setState({ deleteAttributeTypeDialogShow: false });
                                            handleTableChange(this.state.currentPage, this.state.currentSize,
                                                this.state.currentSortField, this.state.currentSortOrder, this.state.filters);
                                        },
                                        (error) => setBackendErrors(error)))
                                }}
                                setBackendErrors={setBackendErrors}
                            />

                            <br />
                            <PaginationProvider
                                pagination={paginationFactory({
                                    custom: true,
                                    totalSize: this.state.allAttributeType ?
                                        this.state.allAttributeType.result.totalItems : 0,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    }
                                })}
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) =>
                                        (
                                            <div>
                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.allAttributeType ?
                                                        this.state.allAttributeType.result.items : []
                                                    }
                                                    noDataIndication={this.state.allAttributeType ?
                                                        this.props.intl.formatMessage({ id: "project.elements.attributes.noElements" }) :
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only"></span>
                                                        </div>
                                                    }
                                                    defaultSorted={[{ dataField: 'id', order: 'asc' }]}
                                                    striped
                                                    condensed
                                                    remote
                                                    rowClasses="text-left"
                                                    expandRow={expandRow}
                                                    filter={filterFactory()}
                                                    filterPosition={"top"}
                                                    onTableChange={(type, { page, sizePerPage, sortField, sortOrder, filters }) => {
                                                        handleTableChange(page, sizePerPage, sortField, sortOrder, filters)
                                                        this.setState({
                                                            filters: filters,
                                                            currentPage: page,
                                                            currentSize: sizePerPage,
                                                            currentSortField: sortField,
                                                            currentSortOrder: sortOrder
                                                        });
                                                    }}
                                                    {...paginationTableProps}
                                                />
                                                <div className="text-right">
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                    />
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                            </PaginationProvider>
                            <br />
                        </div>
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;
        }

        if (!this.props.user && this.props.allCodes) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(AllAttributeType)));