export default {
    "project.global.exceptions.NetworkError": 'No se pudo conectar con el servidor',
    'project.app.Header.riskzones': 'Zonas de Riesgo',
    'project.app.Header.viewer': 'Visor geográfico',
    'project.app.Header.geometricElements': 'Tablas de elementos',
    'project.app.Header.admin': 'Administración',
    'project.app.Header.admin.users': 'Usuarios',
    'project.app.Header.admin.elementType': 'Tipos de elementos',
    'project.app.Header.admin.attributeType': 'Tipos de atributos',
    'project.app.Header.admin.configurationParameters': 'Parámetros de configuración',
    'project.app.Header.admin.layer': 'Capas',
    'project.app.Header.admin.layerGroup': 'Grupos de capas',
    'project.app.Header.myContributions': 'Mis contribuciones',
    'project.app.Header.addGeometricElement': 'Añadir nuevo elemento',
    'project.app.Header.login': 'Iniciar sesión',
    'project.app.Header.signUp': 'Crear cuenta',
    'project.app.Header.logout': 'Cerrar sesión',
    'project.app.Header.password': 'Contraseña',
    'project.app.Body.search': 'Buscar',
    'project.app.Body.accept': 'Aceptar',
    'project.app.Body.cancel': 'Cancelar',
    'project.app.Body.back': 'Anterior',
    'project.app.Body.next': 'Siguiente',
    'project.app.Body.save': 'Guardar',
    'project.app.Body.return': '← Volver a la página principal',
    'project.app.Body.field.required': 'El campo "{field}" es obligatorio',
    'project.app.Body.field.positive': 'El campo "{field}" debe ser positivo',

    'project.common.close': 'Cerrar',
    'project.common.ErrorDialog.title': 'Error',
    'project.common.permissionDenied': 'No tienes permiso para ver esta página',
    'project.common.mustLogin': 'Debes iniciar sesión para ver esta página',
    'project.common.action': "Acciones",
    'project.common.modify': 'Modificar',
    'project.common.center': 'Centrar',
    'project.common.modifyOnViewer': 'Modificar en el visor geográfico',
    'project.common.centerOnViewer': 'Ver en el mapa',
    'project.common.selectOneOption': 'Selecciona una opción',
    'project.common.viewElements': 'Ver elementos',
    'project.common.attributes': 'Atributos',
    'project.common.delete': 'Eliminar',
    'project.common.deleteSomething': 'Eliminar {something}',
    'project.common.download': 'Descargar',
    'project.common.viewFiles': 'Ver fotos',
    'project.common.deleteFile': 'Eliminar fichero',
    'project.common.addFilesButton': 'Añadir ficheros',
    'project.common.addFiles': 'Añadir ficheros al elemento {id}',
    'project.common.yes': 'Sí',
    'project.common.no': 'No',
    'project.common.filters': 'Filtros',
    'project.common.filters.clear': 'Quitar todos los filtros',
    'project.common.filepond.labelIdle': 'Arrastra y suelta tus ficheros o <span class="filepond--label-action">Examina</span>',
    'project.common.filepond.labelTapCancel': 'Pulsa para cancelar',
    'project.common.filepond.labelLoading': 'Cargando',
    'project.common.filepond.labelWaitForSize': 'Esperando por el tamaño',
    'project.common.tables.totalElements': 'Mostrando del {from} al {to} de {total} ',
    'project.common.tables.filter.text.placeholder': 'Buscar {column}',
    'project.common.tables.filter.select.placeholder': 'Seleccionar {column}',

    'project.elements.new': 'Crear {element}',
    'project.elements.myElements.message': "En esta tabla se muestran los elementos públicos. Consulte los creados por usted en {link}",
    'project.elements.anonymous.message': "Para introducir un nuevo elemento debe {link}",
    'project.elements.addGeometricElement': 'Añadir nuevo elemento',
    'project.elements.modify': 'Modificar {element}',
    'project.elements.noElements': 'No existen elementos para el tipo seleccionado',
    'project.elements.types.noElements': 'No existen tipos de elementos',
    'project.elements.attributes.noAttributes': 'Este tipo de elemento no tiene tipos de atributos asociados',
    'project.elements.map.fitToExtentTip': 'Ver la máxima extensión',
    'project.elements.map.zoomToExtentTip': 'Zoom por encuadre',
    'project.elements.map.popupTip': 'Consultar información sobre elementos mediante clic',
    'project.elements.map.measureLengthTip': 'Medir distancias',
    'project.elements.map.measureAreaTip': 'Medir áreas',
    'project.elements.map.addElementTip': 'Añadir nuevo elemento',
    'project.elements.map.fullScreenTip': 'Pantalla completa',
    'project.elements.map.background': 'Fondo',
    'project.elements.map.otherLayers': 'Otras capas',
    'project.elements.map.myElements': 'Mis contribuciones',
    'project.elements.map.elements': 'Elementos',
    'project.elements.map.orthophotos': 'Ortofotos',
    'project.elements.map.administrativeLimits': 'Divisiones',
    'project.elements.map.cityCouncils': 'Ayuntamientos',
    'project.elements.map.hygrography': 'Hidrografía',
    'project.elements.map.hydrography.channels': 'Canales',
    'project.elements.map.hydrography.reservoirs': 'Embalses',
    'project.elements.map.hydrography.lagoons': 'Lagunas',
    'project.elements.map.hydrography.rivers': 'Ríos',
    'project.elements.map.provinces': 'Provincias',
    'project.elements.map.councils': 'Ayuntamientos',
    'project.elements.map.parishes': 'Parroquias',
    'project.elements.map.entityPopulations': 'Entidades de población',
    'project.elements.map.counties': 'Comarcas',
    'project.elements.map.entityPopulation': 'Entidad de población',
    'project.elements.map.selectProvince': '-- Seleccione provincia --',
    'project.elements.map.selectCouncil': '-- Seleccione municipio --',
    'project.elements.map.selectParish': '-- Seleccione parroquia --',
    'project.elements.map.selectEntityPopulation': '-- Seleccione entidad de población --',
    'project.elements.map.searchEntityPopulation': 'Buscar',
    'project.elements.map.progressiveCenter': 'Búsqueda progresiva',
    'project.elements.map.searchCenter': 'Búsqueda directa',
    'project.elements.map.searchType': 'Tipo de búsqueda',
    'project.elements.selectOneOption': 'Selecciona una opción',
    'project.elements.files': 'Ficheros asociados',
    'project.elements.files.hideShow': 'Mostrar/Ocultar ficheros asociados',
    'project.elements.files.noFiles': 'Este elemento no tiene ficheros asociados',
    'project.elements.values': 'Valores',
    'project.elements.name': 'Nombre',
    'project.elements.geometryType': 'Tipo de geometría',
    'project.elements.pointStyleName': 'Nombre del estilo para punto',
    'project.elements.lineStyleName': 'Nombre del estilo para línea',
    'project.elements.polygonStyleName': 'Nombre del estilo para polígono',
    'project.elements.description': 'Descripción',
    'project.elements.geom': 'Geometría',
    'project.elements.geom.LineString': 'Línea',
    'project.elements.geom.Point': 'Punto',
    'project.elements.geom.Polygon': 'Polígono',
    'project.elements.geom.LinearRing': 'Anillo lineal',
    'project.elements.geom.MultiPoint': 'Multipunto',
    'project.elements.geom.MultiLineString': 'Multilínea',
    'project.elements.geom.MultiPolygon': 'Multipolígono',
    'project.elements.geom.Circle': 'Círculo',
    'project.elements.files.filename': 'Nombre',
    'project.elements.files.format': 'Formato',
    'project.elements.files.type': 'Tipo mime',
    'project.elements.files.size': 'Tamaño',
    'project.elements.values.attribute': 'Atributo',
    'project.elements.values.value': 'Valor',
    'project.elements.values.order': 'Orden',
    'project.elements.creationDate': 'Introducción',
    'project.elements.modificationDate': 'Última modificación',
    'project.elements.lessCreationDate': 'Fecha de introducción igual o anterior a',
    'project.elements.lessModificationDate': 'Fecha de modificación igual o anterior a',
    'project.elements.moreCreationDate': 'Fecha de introducción igual o posterior a',
    'project.elements.moreModificationDate': 'Fecha de modificación igual o posterior a',
    'project.elements.isPublic': '¿Es público?',
    'project.elements.isReviewed': '¿Está revisado?',
    'project.elements.internalComments': 'Obs. internas',
    'project.elements.owner': 'Autor',
    'project.elements.type.add': 'Añadir tipo de elemento',
    'project.elements.type.add.name': 'Nombre del tipo de elemento',
    'project.elements.type.modify': 'Modificar tipo de elemento',
    'project.elements.type.modify.name': 'Nombre del tipo de elemento',
    'project.elements.type.add.attribute': 'Atributo',
    'project.elements.type.showAttributes': 'Mostrar/Ocultar atributos asociados',
    'project.elements.delete.message': '¿Va a eliminar "{element}", ¿Desea continuar?',
    "project.elements.files.fileNoExistsHeader": 'El fichero no existe',
    "project.elements.files.fileNoExistsBody": 'No se encontró el fichero',

    'project.elements.attributes.noElements': 'No existen tipos de atributos',
    'project.elements.attributes.dataType': 'Tipo de dato',
    'project.elements.attributes.dataType.pick': 'Selecciona el tipo de dato de la lista',
    'project.elements.attributes.attributeOrder': 'Orden',
    'project.elements.attributes.code': 'Código',
    'project.elements.attributes.values': 'Posibles valores',
    'project.elements.attributes.showHideValues': 'Mostrar/Ocultar posibles valores',
    'project.elements.attributes.add': 'Añadir tipo de atributo',
    'project.elements.attributes.modify': 'Modificar tipo de atributo',
    'project.elements.attributes.attributeName': 'Nombre del atributo',
    'project.elements.attributes.help': 'Ayuda',
    'project.elements.attributes.translations': 'Internacionalización',
    'project.elements.attributes.maxSize': 'Tamaño máximo',
    'project.elements.attributes.mandatory': 'Obligatorio',
    'project.elements.attributes.notMandatory': 'No obligatorio',
    'project.elements.attributes.type': 'Tipo de atributo',
    'project.elements.attributes.description': 'Descripción',
    'project.elements.attributes.name': 'Nombre',

    'project.elements.geometricLayer.layerGroup.name': 'Nombre público',
    'project.elements.geometricLayer.layerGroup.includesInsideLayerGroup': '¿Subgrupo de "Otras capas"?',
    'project.elements.geometricLayer.layerGroup.order': 'Orden',
    'project.elements.geometricLayer.layerGroup.initiallyOpen': '¿Inicialmente abierto?',
    'project.elements.geometricLayer.layerGroup.showLayers': 'Mostrar/Ocultar capas asociadas',
    'project.elements.geometricLayer.layerGroup.add': 'Añadir grupo de capas',
    'project.elements.geometricLayer.layerGroup.deleteMessage': 'Va a eliminar el grupo de capas "{element}", ¿Desea continuar?',
    'project.elements.geometricLayer.layerGroup.noElements': 'No existe ningún grupo de capas',
    'project.elements.geometricLayer.layer.internalName': 'Nombre interno',
    'project.elements.geometricLayer.layer.name': 'Nombre público',
    'project.elements.geometricLayer.layer.order': 'Orden',
    'project.elements.geometricLayer.layer.initiallyVisible': '¿Inicialmente visible?',
    'project.elements.geometricLayer.layer.serverUrl': 'URL del servidor',
    'project.elements.geometricLayer.layer.type': 'Tipo',
    'project.elements.geometricLayer.layer.internalComments': 'Observaciones internas',
    'project.elements.geometricLayer.layer.geometricLayerGroup': 'Grupo de capas',
    'project.elements.geometricLayer.layer.add': 'Añadir capa',
    'project.elements.geometricLayer.layer.modify': 'Modificar capa',
    'project.elements.geometricLayer.layer.noElements': 'No existe ninguna capa',
    'project.elements.geometricLayer.layer.deleteMessage': 'Va a eliminar la capa "{element}", ¿Desea continuar?',

    'project.configurationParameter.name': 'Nombre',
    'project.configurationParameter.code': 'Código',
    'project.configurationParameter.value': 'Valor',
    'project.configurationParameter.userInterface': '¿Interfaz de usuario?',
    'project.configurationParameter.noDataIndication': 'No existen parámetros de configuración',

    'project.users.password': 'Contraseña',
    'project.users.confirmPassword': 'Confirmar contraseña',
    'project.users.confirmPassword.error': 'Las contraseñas no coinciden',
    'project.users.locale': 'Idioma',
    'project.users.locale.pick': 'Selecciona un idioma de la lista',
    'project.users.locale.galician': 'Gallego',
    'project.users.locale.spanish': 'Español',
    'project.users.locale.english': 'Inglés',
    'project.users.currentPassword': 'Contraseña actual',
    'project.users.newPassword': 'Nueva contraseña',
    'project.users.confirmNewPassword': 'Confirme la nueva contraseña',
    'project.users.modal.modifyUser': '{user} {login}',
    'project.users.login': 'Nombre de usuario',
    'project.riskzone.back': 'Atrás',
    'project.users.lock': 'Bloquear cuenta',
    'project.users.user': 'Usuario',
    'project.users.username': "Nombre de usuario",
    'project.users.locked': "¿Está bloqueado?",
    'project.users.role': "Rol",
    'project.users.changePassword': 'Cambiar contraseña',
    'project.users.createUser': 'Crear usuario',
    'project.users.noDataIndication': 'No existen cuentas de usuario'
}