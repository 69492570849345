import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as geometricElementsSelector from '../../geometricElements/selectors'
import * as appSelectors from '../../app/selectors'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import LockUserAccount from './LockUserAccount';
import { SignUp } from '..';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        user: selectors.getUser(state),
        allRoles: selectors.getAllRoles(state),
        allUserAccounts: selectors.getAllUsers(state),
        allCodes: geometricElementsSelector.getAllCodes(state),
        totalUserAccounts: selectors.getTotalUserAccounts(state)
    }
}

class AllUserAccounts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allUserAccounts: null,
            listTotalUserAccounts: null,
            filters: {},
            currentPage: 1,
            currentSize: 10,
            currentSortField: 'id',
            currentSortOrder: 'asc',
            backendErrors: null,
            lockUserAccountModalShow: false,
            userAccountToLock: null,
            addUserModalShow: false
        }
    }

    componentDidMount() {
        this.props.dispatch(actions.findAllRoles());
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allRoles) {

                let handleTableChange = (page, sizePerPage, sortField, sortOrder, filters) => {
                    if (Object.entries(filters).length > 0) {
                        let result = this.state.listTotalUserAccounts.items.filter((row) => {
                            let valid = true;
                            for (const dataField in filters) {
                                const { filterVal, filterType, comparator } = filters[dataField];

                                if (filterType === 'TEXT') {
                                    if (comparator === Comparator.LIKE) {
                                        valid = row[dataField].toString().toLocaleLowerCase().indexOf(
                                            filterVal.toLocaleLowerCase()) > -1;

                                    } else {
                                        valid = row[dataField].toLocaleLowerCase() === filterVal.toLocaleLowerCase();
                                    }
                                }

                                if (filterType === 'SELECT') {
                                    if (dataField === "userRoleDto.code") {
                                        valid = row.userRoleDto.code.toString() === filterVal
                                    } else {
                                        valid = row[dataField].toString() === filterVal;
                                    }
                                }

                                if (!valid) break;
                            }
                            return valid;
                        });
                        if (sortOrder === 'asc') {
                            result = result.sort((a, b) => {
                                if (a[sortField] > b[sortField]) {
                                    return 1;
                                } else if (b[sortField] > a[sortField]) {
                                    return -1;
                                }
                                return 0;
                            });
                        } else {
                            result = result.sort((a, b) => {
                                if (a[sortField] > b[sortField]) {
                                    return -1;
                                } else if (b[sortField] > a[sortField]) {
                                    return 1;
                                }
                                return 0;
                            });
                        }
                        let newList = this.state.allUserAccounts;
                        const currentIndex = (page - 1) * sizePerPage;
                        newList.result.totalItems = result.length;
                        newList.result.items = result.slice(currentIndex, currentIndex + sizePerPage);
                        this.setState({ filteredUserAccounts: newList, allUserAccounts: newList });
                    } else {
                        this.props.dispatch(actions.findAllUserAccount(page <= 0 ? 0 : page - 1, sizePerPage,
                            sortField, sortOrder, () => {
                                this.setState({
                                    allUserAccounts: this.props.allUserAccounts,
                                    listTotalUserAccounts: this.props.totalUserAccounts,
                                    filteredUserAccounts: null
                                });
                            })
                        );
                    }
                }

                let booleanFilterOptions = [{
                    value: true,
                    label: this.props.intl.formatMessage({ id: 'project.common.yes' })
                }, {
                    value: false,
                    label: this.props.intl.formatMessage({ id: 'project.common.no' })
                }];

                let rolesFilterOptions = [];
                this.props.allRoles.forEach((role, index) => {
                    rolesFilterOptions[index] = {
                        value: role.code,
                        label: getInternationalization(this.props.language, role.code, this.props.allCodes)
                    }
                })

                jQuery(function () {
                    jQuery('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                    jQuery('[data-toggle="tooltip"]').tooltip().click(function () {
                        jQuery('[data-toggle="tooltip"]').tooltip("hide");
                    });
                });

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" })
                    })
                },
                {
                    dataField: 'login',
                    text: this.props.intl.formatMessage({ id: "project.users.username" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.users.username" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'userRoleDto.code',
                    text: this.props.intl.formatMessage({ id: "project.users.role" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.users.role" }).toLocaleLowerCase() }),
                        options: rolesFilterOptions
                    })
                }, {
                    dataField: 'locked',
                    text: this.props.intl.formatMessage({ id: "project.users.locked" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> : <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.users.locked" }).toLocaleLowerCase() }),
                        options: booleanFilterOptions
                    })
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <span className="btn-link"
                            onClick={() =>
                                this.setState({
                                    backendErrors: null,
                                    lockUserAccountModalShow: true,
                                    userAccountToLock: row
                                })
                            }
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            id={"modify" + row.login}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </span>
                    )
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.users" /></h3>
                            <div className="text-right">
                                <div className="dropdown">
                                    <a className="btn btn-primary dropdown-toggle" href="." role="button" id="addGeometricElementDropdownLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                        &nbsp;
                                        <FormattedMessage
                                            id="project.users.createUser"
                                        />
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="addGeometricElementDropdownLink">
                                        {this.props.allRoles.map(role =>
                                            <Link className="dropdown-item"
                                                key={role.code}
                                                id={role.code}
                                                onClick={() => {
                                                    this.props.dispatch(actions.findRoleByCode(role.code));
                                                    this.setState({
                                                        backendErrors: null,
                                                        addUserModalShow: true
                                                    })
                                                }}
                                            >
                                                <InternationalizationRender locale={this.props.language} value={role.code} listInternationalization={this.props.allCodes} />
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Add user dialog */}
                            <SignUp
                                modalShow={this.state.addUserModalShow}
                                backendErrors={this.state.backendErrors}
                                handleAddNewUser={() => {
                                    handleTableChange(this.state.currentPage,
                                        this.state.currentSize, this.state.currentSortField,
                                        this.state.currentSortOrder, this.state.filters);
                                }}
                                setBackendErrors={setBackendErrors}
                                hideModalWindow={() => this.setState({ addUserModalShow: false })}
                            />

                            {/* Lock user account dialog */}
                            <LockUserAccount
                                modalShow={this.state.lockUserAccountModalShow}
                                userAccountToLock={this.state.userAccountToLock}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({ lockUserAccountModalShow: false });
                                    handleTableChange(this.state.currentPage, this.state.currentSize, this.state.currentSortField,
                                        this.state.currentSortOrder, this.state.filters);
                                }}
                                hideModalWindow={() => this.setState({ lockUserAccountModalShow: false })}
                            />

                            <br />
                            <PaginationProvider
                                pagination={paginationFactory({
                                    custom: true,
                                    totalSize: this.state.allUserAccounts ?
                                        this.state.allUserAccounts.result.totalItems : 0,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    }
                                })}
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) =>
                                        (
                                            <div>
                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.allUserAccounts ?
                                                        this.state.allUserAccounts.result.items : []
                                                    }
                                                    noDataIndication={this.state.allUserAccounts ?
                                                        this.props.intl.formatMessage({ id: 'project.users.noDataIndication' }) :
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only"></span>
                                                        </div>
                                                    }
                                                    defaultSorted={[{ dataField: 'id', order: 'asc' }]}
                                                    filter={filterFactory()}
                                                    filterPosition={"top"}
                                                    striped
                                                    condensed
                                                    remote
                                                    rowClasses="text-left"
                                                    onTableChange={(type, { page, sizePerPage, sortField, sortOrder, filters }) => {
                                                        handleTableChange(page, sizePerPage, sortField, sortOrder, filters);
                                                        this.setState({
                                                            filters: filters,
                                                            currentPage: page,
                                                            currentSize: sizePerPage,
                                                            currentSortField: sortField,
                                                            currentSortOrder: sortOrder
                                                        });
                                                    }}
                                                    {...paginationTableProps}
                                                />
                                                <div className="text-right">
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                    />
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                            </PaginationProvider>
                        </div>
                        <br />
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;
        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }

}

export default withRouter(connect(mapStateToProps)(injectIntl(AllUserAccounts)));