import React from 'react';
import * as actions from '../actions';
import * as selectors from '../selectors';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import { DeleteDialog } from '../../common';
import AddModifyGeometricLayer from './AddModifyGeometricLayer';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        user: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        allGeometricLayer: selectors.getAllGeometricLayer(state),
        totalGeometricLayer: selectors.getTotalGeometricLayer(state)
    }
}

class AllGeometricLayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allGeometricLayer: null,
            listTotalGeometricLayer: null,
            filters: {},
            currentPage: 1,
            currentSize: 10,
            currentSortField: 'id',
            currentSortOrder: 'asc',
            backendErrors: null,
            deleteGeometricLayerDialogShow: false,
            deleteGeometricLayerId: "",
            deleteGeometricLayerName: "",
            addModifyGeometricLayerModalShow: false,
            geometricLayerToModify: null
        }
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        if (this.props.user) {

            let booleanFilterOptions = [{
                value: true,
                label: this.props.intl.formatMessage({ id: 'project.common.yes' })
            }, {
                value: false,
                label: this.props.intl.formatMessage({ id: 'project.common.no' })
            }];

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                let handleTableChange = (page, sizePerPage, sortField, sortOrder, filters) => {
                    if (Object.entries(filters).length > 0) {
                        let result = this.state.listTotalGeometricLayer.items.filter((row) => {
                            let valid = true;
                            for (const dataField in filters) {
                                const { filterVal, filterType, comparator } = filters[dataField];

                                if (filterType === 'TEXT') {
                                    if (comparator === Comparator.LIKE) {
                                        if (dataField === "code.code") {
                                            valid = getInternationalization(
                                                this.props.language,
                                                row.code.code,
                                                this.props.allCodes).toString().toLocaleLowerCase()
                                                .indexOf(filterVal.toLocaleLowerCase()) > -1;
                                        } else if (dataField === "geometricLayerGroup.code.code") {
                                            valid = getInternationalization(
                                                this.props.language,
                                                row.geometricLayerGroup.code.code,
                                                this.props.allCodes).toString().toLocaleLowerCase()
                                                .indexOf(filterVal.toLocaleLowerCase()) > -1;
                                        } else if (dataField === "type.code") {
                                            valid = getInternationalization(
                                                this.props.language,
                                                row.type.code,
                                                this.props.allCodes).toString().toLocaleLowerCase()
                                                .indexOf(filterVal.toLocaleLowerCase()) > -1;
                                        } else {
                                            valid = row[dataField].toString().toLocaleLowerCase().indexOf(
                                                filterVal.toLocaleLowerCase()) > -1;
                                        }
                                    } else {
                                        valid = row[dataField].toLocaleLowerCase() === filterVal.toLocaleLowerCase();
                                    }
                                }

                                if (filterType === 'SELECT') {
                                    valid = row[dataField].toString() === filterVal;
                                }

                                if (!valid) break;
                            }
                            return valid;
                        });
                        if (sortOrder === 'asc') {
                            result = result.sort((a, b) => {
                                if (a[sortField] > b[sortField]) {
                                    return 1;
                                } else if (b[sortField] > a[sortField]) {
                                    return -1;
                                }
                                return 0;
                            });
                        } else {
                            result = result.sort((a, b) => {
                                if (a[sortField] > b[sortField]) {
                                    return -1;
                                } else if (b[sortField] > a[sortField]) {
                                    return 1;
                                }
                                return 0;
                            });
                        }
                        let newList = this.state.allGeometricLayer;
                        const currentIndex = (page - 1) * sizePerPage;
                        newList.totalItems = result.length;
                        newList.items = result.slice(currentIndex, currentIndex + sizePerPage);
                        this.setState({ allGeometricLayer: newList });
                    } else {
                        this.props.dispatch(actions.findAllGeometricLayer(page <= 0 ? 0 : page - 1, sizePerPage,
                            sortField, sortOrder, () => {
                                this.setState({
                                    allGeometricLayer: this.props.allGeometricLayer,
                                    listTotalGeometricLayer: this.props.totalGeometricLayer
                                });
                            }));
                    }
                }

                jQuery(function () {
                    jQuery('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                    jQuery('[data-toggle="tooltip"]').tooltip().click(function () {
                        jQuery('[data-toggle="tooltip"]').tooltip("hide");
                    });
                });

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    headerAlign: 'left',
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" })
                    })
                },
                {
                    dataField: 'internalName',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalName" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalName" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'code.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender
                            locale={this.props.language}
                            value={cellContent}
                            listInternationalization={this.props.allCodes}
                        />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.name" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'order',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.order" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.order" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'initiallyVisible',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.initiallyVisible" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                            <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.initiallyVisible" }).toLocaleLowerCase() }),
                        options: booleanFilterOptions
                    })
                }, {
                    dataField: 'serverUrl',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.serverUrl" }),
                    headerAlign: 'left',
                    sert: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.serverUrl" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'type.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.type" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender
                            locale={this.props.language}
                            value={cellContent}
                            listInternationalization={this.props.allCodes}
                        />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.type" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'internalComments',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalComments" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        cellContent ? cellContent !== "" ? cellContent : "-" : "-"
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalComments" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'geometricLayerGroup.code.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.geometricLayerGroup" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender
                            locale={this.props.language}
                            value={cellContent}
                            listInternationalization={this.props.allCodes}
                        />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.geometricLayerGroup" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <div className="text-left">
                            <span
                                onClick={() => {
                                    this.setState({
                                        backendErrors: null,
                                        addModifyGeometricLayerModalShow: true,
                                        geometricLayerToModify: row
                                    })
                                }}
                                className="btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modify" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            <span onClick={() =>
                                this.setState({
                                    backendErrors: null,
                                    deleteGeometricLayerDialogShow: true,
                                    deleteGeometricLayerId: row.id,
                                    deleteGeometricLayerName: getInternationalization(this.props.language, row.code.code, this.props.allCodes)
                                })
                            }
                                className="btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"delete" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                        </div>
                    )
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.layer" /></h3>
                            <div className="text-right">
                                <button className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({
                                            backendErrors: null,
                                            addModifyGeometricLayerModalShow: true,
                                            geometricLayerToModify: null
                                        })
                                    }}
                                    id="addLayer"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.elements.geometricLayer.layer.add" />
                                </button>
                            </div>

                            {/* Add/modify geometric layer dialog */}
                            <AddModifyGeometricLayer
                                modalShow={this.state.addModifyGeometricLayerModalShow}
                                geometricLayerToModify={this.state.geometricLayerToModify}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({ addModifyGeometricLayerModalShow: false });
                                    handleTableChange(this.state.currentPage, this.state.currentSize, this.state.currentSortField,
                                        this.state.currentSortOrder, this.state.filters);
                                    this.props.dispatch(actions.findListAllGeometricLayerGroup());
                                }}
                                hideModalWindow={() => this.setState({ addModifyGeometricLayerModalShow: false })}
                            />

                            {/* Delete geometric layer dialog */}
                            <DeleteDialog
                                modalShow={this.state.deleteGeometricLayerDialogShow}
                                title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                                details={this.props.intl.formatMessage(
                                    { id: 'project.elements.delete.message' },
                                    { element: <b>{this.state.deleteGeometricLayerName}</b> })
                                }
                                backendErrors={this.state.backendErrors}
                                hideModalWindow={() => { this.setState({ deleteGeometricLayerDialogShow: false }) }}
                                handleSubmit={() => {
                                    this.props.dispatch(actions.deleteGeometricLayer(this.state.deleteGeometricLayerId,
                                        () => {
                                            this.setState({ deleteGeometricLayerDialogShow: false });
                                            handleTableChange(this.state.currentPage, this.state.currentSize,
                                                this.state.currentSortField, this.state.currentSortOrder, this.state.filters);
                                        },
                                        (error) => setBackendErrors(error)))
                                }}
                                setBackendErrors={setBackendErrors}
                            />

                            <br />
                            <PaginationProvider
                                pagination={paginationFactory({
                                    custom: true,
                                    totalSize: this.state.allGeometricLayer ?
                                        this.state.allGeometricLayer.totalItems : 0,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    }
                                })}
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) =>
                                        (
                                            <div>
                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    columns={columns}
                                                    rowClasses="text-left"
                                                    data={this.state.allGeometricLayer ?
                                                        this.state.allGeometricLayer.items : []
                                                    }
                                                    noDataIndication={this.state.allGeometricLayer ?
                                                        this.props.intl.formatMessage(
                                                            { id: "project.elements.geometricLayer.layer.noElements" }) :
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only"></span>
                                                        </div>
                                                    }
                                                    defaultSorted={[{ dataField: 'id', order: 'asc' }]}
                                                    filter={filterFactory()}
                                                    filterPosition={"top"}
                                                    striped
                                                    condensed
                                                    remote
                                                    onTableChange={(type, { page, sizePerPage, sortField, sortOrder, filters }) => {
                                                        handleTableChange(page, sizePerPage, sortField, sortOrder, filters);
                                                        this.setState({
                                                            filters: filters,
                                                            currentPage: page,
                                                            currentSize: sizePerPage,
                                                            currentSortField: sortField,
                                                            currentSortOrder: sortOrder
                                                        });
                                                    }}
                                                    {...paginationTableProps}
                                                />
                                                <div className="text-right">
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                    />
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                            </PaginationProvider>
                        </div>
                        <br />
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;

        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;

    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(AllGeometricLayer)));