import { combineReducers } from 'redux';

import * as actionTypes from './actionTypes';

const initialState = {
    geometricElementType: null,
    geometricElementsByElementType: null,
    allCodes: null,
    allGeometricElementType: null,
    allAttributeType: null,
    listAllAttributeType: null,
    listAllGeometricElementType: null,
    listProvinces: null,
    listCouncils: null,
    listParishes: null,
    listEntityPopulations: null,
    listCounties: null,
    listAllGeometricLayerGroup: null,
    allGeometricLayerGroup: null,
    allGeometricLayer: null,
    totalGeometricElementType: null,
    totalAttributeType: null,
    totalGeometricLayer: null,
    totalGeometricLayerGroup: null,
    mapCurrentExtent: null
};

const geometricElementType = (state = initialState.geometricElementType, action) => {
    switch (action.type) {
        case actionTypes.FIND_GEOMETRIC_ELEMENT_TYPE_BY_ID_COMPLETED:
            return action.geometricElementType;
        default:
            return state;
    }
}

const geometricElementsByElementType = (state = initialState.geometricElementsByElementType, action) => {
    switch (action.type) {
        case actionTypes.FIND_PUBLIC_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED:
            return action.geometricElementsByElementType;
        case actionTypes.FIND_ADMIN_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED:
            return action.geometricElementsByElementType;
        case actionTypes.FIND_USER_GEOMETRIC_ELEMENTS_BY_ELEMENT_TYPE_ID_COMPLETED:
            return action.geometricElementsByElementType;
        default:
            return state;
    }
}

const allCodes = (state = initialState.allCodes, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_CODES_COMPLETED:
            return action.allCodes;
        default:
            return state;
    }
}

const allGeometricElementType = (state = initialState.allGeometricElementType, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_GEOMETRIC_ELEMENT_TYPE:
            return action.allGeometricElementType;
        default:
            return state
    }
}

const listAllGeometricElementType = (state = initialState.listAllGeometricElementType, action) => {
    switch (action.type) {
        case actionTypes.FIND_LIST_ALL_GEOMETRIC_ELEMENT_TYPE_COMPLETED:
            return action.listAllGeometricElementType;
        default:
            return state;
    }
}

const allAttributeType = (state = initialState.allAttributeType, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_ATTIBUTE_TYPE:
            return action.allAttributeType;
        default:
            return state;
    }
}

const listAllAttributeType = (state = initialState.listAllAttributeType, action) => {
    switch (action.type) {
        case actionTypes.FIND_LIST_ALL_ATTRIBUTE_TYPE_COMPLETED:
            return action.listAllAttributeType;
        default:
            return state;
    }
}

//Center by population entities reducers

const listProvinces = (state = initialState.listProvinces, action) => {
    switch (action.type) {
        case actionTypes.LIST_PROVINCES:
            return action.listProvinces;
        default:
            return state;
    }
}

const listCouncils = (state = initialState.listCouncils, action) => {
    switch (action.type) {
        case actionTypes.LIST_COUNCILS:
            return action.listCouncils;
        default:
            return state;
    }
}

const listParishes = (state = initialState.listParishes, action) => {
    switch (action.type) {
        case actionTypes.LIST_PARISHES:
            return action.listParishes;
        default:
            return state;
    }
}

const listEntityPopulations = (state = initialState.listEntityPopulations, action) => {
    switch (action.type) {
        case actionTypes.LIST_ENTITY_POPULATIONS:
            return action.listEntityPopulations;
        default:
            return state;
    }
}

const listCounties = (state = initialState.listCounties, action) => {
    switch (action.type) {
        case actionTypes.LIST_COUNTIES:
            return action.listCounties;
        default:
            return state;
    }
}

//GeometricLayerService reducers

const listAllGeometricLayerGroup = (state = initialState.listAllGeometricLayerGroup, action) => {
    switch (action.type) {
        case actionTypes.FIND_LIST_ALL_GEOMETRIC_LAYER_GROUP_COMPLETED:
            return action.listAllGeometricLayerGroup;
        default:
            return state;
    }
}

const allGeometricLayerGroup = (state = initialState.allGeometricLayerGroup, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_GEOMETRIC_LAYER_GROUP_COMPLETED:
            return action.allGeometricLayerGroup;
        default:
            return state;
    }
}

const allGeometricLayer = (state = initialState.allGeometricLayer, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_GEOMETRIC_LAYER_COMPLETED:
            return action.allGeometricLayer;
        default:
            return state;
    }
}

const totalGeometricElementType = (state = initialState.totalGeometricElementType, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_GEOMETRIC_ELEMENT_TYPE_COMPLETED:
            return action.totalGeometricElementType;
        default:
            return state;
    }
}

const totalAttributeType = (state = initialState.totalAttributeType, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_ATTRIBUTE_TYPE_COMPLETED:
            return action.totalAttributeType;
        default:
            return state;
    }
}

const totalGeometricLayerGroup = (state = initialState.totalGeometricLayerGroup, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_GEOMETRIC_LAYER_GROUP_COMPLETED:
            return action.totalGeometricLayerGroup;
        default:
            return state;
    }
}

const totalGeometricLayer = (state = initialState.totalGeometricLayer, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_GEOMETRIC_LAYER_COMPLETED:
            return action.totalGeometricLayer;
        default:
            return state;
    }
}

const mapCurrentExtent = (state = initialState.mapCurrentExtent, action) => {
    switch (action.type) {
        case actionTypes.MAP_CURRENT_EXTENT:
            return action.mapCurrentExtent;
        default:
            return state;
    }
}

const reducer = combineReducers({
    geometricElementType,
    geometricElementsByElementType,
    allCodes,
    allGeometricElementType,
    allAttributeType,
    listAllAttributeType,
    listAllGeometricElementType,
    listProvinces,
    listCouncils,
    listParishes,
    listEntityPopulations,
    listCounties,
    listAllGeometricLayerGroup,
    allGeometricLayerGroup,
    allGeometricLayer,
    totalAttributeType,
    totalGeometricElementType,
    totalGeometricLayer,
    totalGeometricLayerGroup,
    mapCurrentExtent
});

export default reducer;