import {combineReducers} from 'redux';

import * as actionTypes from './actionTypes';

const initialState = {
    allConfigurationParameters: null,
    userInterfaceConfigurationParameters: null,
    totalConfigurationParameter: null
};

const allConfigurationParameters = (state=initialState.allConfigurationParameters, action) => {
    switch (action.type) {
        case actionTypes.FIND_ALL_CONFIGURATION_PARAMETER_COMPLETED:
            return action.allConfigurationParameters;
        default:
            return state;
    }
}

const userInterfaceConfigurationParameters = (state=initialState.userInterfaceConfigurationParameters, action) => {
    switch (action.type) {
        case actionTypes.FIND_USER_INTERFACE_CONFIGURATION_PARAMETER_COMPLETED:
            return action.userInterfaceConfigurationParameters;
        default:
            return state;
    }
}

const totalConfigurationParameter = (state=initialState.totalConfigurationParameter, action) => {
    switch (action.type) {
        case actionTypes.FIND_TOTAL_CONFIGURATION_PARAMETER_COMPLETED:
            return action.totalConfigurationParameter;
        default:
            return state;
    }
}

const reducer =  combineReducers({
    allConfigurationParameters,
    userInterfaceConfigurationParameters,
    totalConfigurationParameter
});

export default reducer;