import React from 'react';
import jQuery from 'jquery';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as FilePond from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import { groupBy } from 'lodash';
import InternationalizationRender from '../../app/components/InternationalizationRender';
import { Errors } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const mapStateToProps = function (state) {

    return {
        locale: appSelectors.getLanguage(state),
        allCodes: selectors.getAllCodes(state),
        geometricElementType: selectors.getGeometricElementType(state),
        user: userSelectors.getUser(state)
    }
}

class AddGeometricElement extends React.Component {

    toFileToInsertJSON = (fileItem) => {
        let fileJSON = {
            "size": fileItem.fileSize,
            "type": fileItem.fileType,
            "filename": fileItem.filename,
            "base64": fileItem.file.uuid ? null : fileItem.getFileEncodeBase64String(),
            "uuid": fileItem.file.uuid ? fileItem.file.uuid : null
        }
        return fileJSON;
    }

    getInternationalization = (locale, listInternationalization, value) => {
        let listTranslations = Object.values(listInternationalization).filter(internationalization =>
            internationalization.code === value)[0].listInternationalizationDto;
        let description = Object.values(listTranslations)
            .filter(translation => translation.language.indexOf(locale) !== -1)[0].description;
        return description;
    }

    hasHelp = (helpCode) => {
        let hasHelp = false;
        if (helpCode) {
            helpCode.listInternationalizationDto.forEach(help => {
                if (help.description !== "") {
                    hasHelp = true;
                } else {
                    hasHelp = false;
                }
            });
        }

        return hasHelp;
    }

    constructor(props) {
        super(props);
        this.state = {
            modifyFeature: this.props.modifyFeature,
            geom: this.props.geom,
            files: [],
            backendErrors: null,
            formikErrors: null
        };

    }

    componentDidUpdate(prevProps) {
        if (this.props.modifyFeature !== this.state.modifyFeature) {
            this.setState({ modifyFeature: this.props.modifyFeature });
        }
        if (this.props.geom !== this.state.geom) {
            this.setState({ geom: this.props.geom });
        }
        if (this.state.formikErrors || this.state.backendErrors) {
            let container = jQuery('#addGeometricElementContainer');
            if (this.state.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.modifyFeature !== this.state.modifyFeature) {
            return true;
        }
        if (nextProps.geom !== this.state.geom) {
            return true;
        }
        if (nextProps.geometricElementType !== this.props.geometricElementType) {
            return true;
        }
        if (nextProps.locale !== this.props.locale) {
            return true;
        }
        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextState.backendErrors !== this.state.backendErrors)
        ) {
            return true;
        }
        return false;
    }

    render() {

        this.inputSelector = ({ description, placeholder, required, val, multiple, locale, errors, setFieldError, attributeType }) => {
            return (
                <div>
                    <div className="form-group">
                        <label htmlFor={description} className={required ? "font-weight-bold required" : "font-weight-bold"}>
                            {placeholder}
                            &nbsp;
                            {this.hasHelp(attributeType.helpCode) ?
                                <sup className="btn-link" style={{ cursor: "pointer" }}
                                    id={"help" + attributeType.code.code}
                                    aria-hidden={true}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    data-original-title={

                                        this.getInternationalization(
                                            this.props.locale,
                                            this.props.allCodes,
                                            attributeType.helpCode.code
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </sup>
                                : ""
                            }
                        </label>
                        <Field as="select" className="form-control" id={description} name={description} /*required={required}*/ multiple={multiple}>
                            {!multiple ? <option value="">{this.props.formatMessage({ id: 'project.elements.selectOneOption' })}</option> : ""}
                            {val.map(code => <option value={code.code} key={code.code}>{
                                this.getInternationalization(locale, this.props.allCodes, code.code)
                            }</option>)}
                        </Field>
                    </div>
                    <ErrorMessage name={description} render={(msg) =>
                        <div id={description + 'error'} className="alert alert-danger alert-dismissible fade show" role="alert"
                            hidden={typeof errors[description] === "undefined"}
                        >
                            <button type="button" className="close" aria-label="Close"
                                onClick={() => setFieldError(`${description}`, undefined)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            {msg}
                        </div>
                    }
                    />
                </div>
            );
        }

        jQuery(function () {
            //show tooltip
            jQuery('sup').tooltip({
                html: true,
                placement: 'left',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['left'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });
            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('[data-toggle="tooltip"]').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });
        });

        //Prevent clicking on help button activates input associated with label
        jQuery(document).on('tap click', 'label sup', function (event, data) {
            event.stopPropagation();
            event.preventDefault();
            return false;
        });

        if (this.props.geometricElementType && this.props.allCodes && this.props.user) {

            //Attributes order
            let listAttributeType = this.props.geometricElementType.listAttributeType.sort((a, b) => {
                return a.attributeOrder - b.attributeOrder;
            });

            this.initialValues = {
                file: [],
                description: "",
                internalComments: "",
                isPublic: false,
                isReviewed: false,
            };
            this.resetValues = {
                file: [],
                description: "",
                internalComments: "",
                isPublic: false,
                isReviewed: false,
            }

            this.schema = {};

            this.schema["description"] = Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.elements.description" />,
                }} />
            );

            this.props.geometricElementType.listAttributeType.forEach(attributeType => {
                if (attributeType.mandatory) {
                    this.initialValues[attributeType.attributeType.code.code] = "";
                    this.resetValues[attributeType.attributeType.code.code] = "";
                    this.schema[attributeType.attributeType.code.code] = Yup.string().required(<FormattedMessage
                        id="project.app.Body.field.required"
                        values={{
                            field: <InternationalizationRender
                                locale={this.props.locale}
                                listInternationalization={this.props.allCodes}
                                value={attributeType.attributeType.code.code} />,
                        }} />);
                } else {
                    this.initialValues[attributeType.attributeType.code.code] = "";
                    this.resetValues[attributeType.attributeType.code.code] = "";
                }
            });

            this.schema = Yup.object().shape({ ...this.schema });

            if (this.state.modifyFeature) {
                this.initialValues = {
                    file: [],
                    description: this.state.modifyFeature.description,
                };
                this.initialValues.internalComments = this.state.modifyFeature.internalComments ? this.state.modifyFeature.internalComments : "";
                this.initialValues.isPublic = this.state.modifyFeature.isPublic;
                this.initialValues.isReviewed = this.state.modifyFeature.isReviewed;
                let groupedValues = groupBy(this.state.modifyFeature.listAttributeValueDto, "elementTypeAttributeType.attributeType.code.code");
                let attributeNames = Object.keys(groupedValues);
                let values = {};
                attributeNames.forEach(attributeName => {
                    values[attributeName] = [];
                    groupedValues[attributeName].forEach(value => {
                        values[attributeName].push(value.value)
                    });
                });
                attributeNames.forEach(attributeName => {
                    this.initialValues[attributeName] = values[attributeName];
                });
                let initialFiles = []
                this.state.modifyFeature.listGeometricElementFileDto.forEach(file => {
                    let fileOptions = {
                        type: 'local',
                        file: {
                            name: file.originalFilename,
                            size: file.size,
                            type: file.contentType
                        }
                    }
                    fileOptions.file.uuid = file.uuid
                    initialFiles.push({
                        source: file.fileUrl,
                        options: fileOptions
                    });
                });
                this.setState({ files: initialFiles });
            }

            FilePond.registerPlugin(FilePondPluginFileEncode);

            let modalTitle = () => {
                if (this.state.modifyFeature) {
                    return (
                        <FormattedMessage id="project.elements.modify"
                            values={{
                                element: this.getInternationalization(this.props.locale, this.props.allCodes, this.props.geometricElementType.code)
                                    .toLowerCase()
                            }}
                        />
                    )
                } else {
                    return (
                        <FormattedMessage id="project.elements.new"
                            values={{
                                element: this.getInternationalization(this.props.locale, this.props.allCodes, this.props.geometricElementType.code)
                                    .toLowerCase()
                            }}
                        />
                    )
                }
            }

            return (
                <Formik
                    initialValues={this.initialValues}
                    enableReinitialize={true}
                    validationSchema={this.schema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        var json = {
                            "id": this.state.modifyFeature ? this.state.modifyFeature.id : 0,
                            "description": values.description,
                            "geom": this.state.geom ? this.state.geom : this.state.modifyFeature.geom,
                            "internalComments": values.internalComments,
                            "isPublic": values.isPublic,
                            "isReviewed": values.isReviewed,
                            "geometricElementTypeDto": this.props.geometricElementType,
                            "listAttributeValueDto": []
                        };

                        if (this.state.modifyFeature) {
                            json.listFileToModifyDto = values.file;
                        } else {
                            json.listFileToInsertDto = values.file;
                        }

                        const elementType = this.props.geometricElementType;

                        listAttributeType.map(attributeType =>
                            Array.isArray(values[attributeType.attributeType.code.code]) ?
                                values[attributeType.attributeType.code.code].map(value =>
                                    json.listAttributeValueDto.push({
                                        "id": -1,
                                        "value": value,
                                        "elementTypeAttributeType": {
                                            "elementType": {
                                                "id": elementType.id,
                                                "geometryType": elementType.geometryType,
                                                "name": elementType.name
                                            },
                                            "attributeType": {
                                                "id": attributeType.attributeType.id,
                                                "description": attributeType.attributeType.description,
                                                "maxSize": attributeType.attributeType.maxSize,
                                                "dataType": attributeType.attributeType.dataType,
                                                "valueGroupCode": attributeType.attributeType.valueGroupCode,
                                                "code": attributeType.attributeType.code
                                            }
                                        }
                                    }))
                                :
                                json.listAttributeValueDto.push({
                                    "id": -1,
                                    "value": values[attributeType.attributeType.code.code],
                                    "elementTypeAttributeType": {
                                        "elementType": {
                                            "id": elementType.id,
                                            "geometryType": elementType.geometryType,
                                            "name": elementType.name
                                        },
                                        "attributeType": {
                                            "id": attributeType.attributeType.id,
                                            "description": attributeType.attributeType.description,
                                            "maxSize": attributeType.attributeType.maxSize,
                                            "dataType": attributeType.attributeType.dataType,
                                            "valueGroupCode": attributeType.attributeType.valueGroupCode,
                                            "code": attributeType.attributeType.code
                                        }
                                    }
                                })
                        );

                        if (this.state.modifyFeature) {
                            this.props.dispatch(actions.modifyGeometricElement(this.state.modifyFeature.id, json, () => {
                                this.setState({ modifyFeature: null });
                                this.props.clearDrawSource();
                                this.props.removeAllAddedInteractions(this.props.map);
                                this.props.resetModifyFeature();
                                this.props.closeAddGeometricElementForm(this.props.map);
                                this.props.refreshLayersCollection();
                                let resetValues = this.resetValues;
                                resetForm({ values: resetValues });
                                this.setState({ files: [] });
                            },
                                errors => {
                                    this.setState({ backendErrors: errors });
                                }
                            ));

                        } else {
                            this.props.dispatch(actions.addGeometricElement(json, () => {
                                this.props.clearDrawSource();
                                this.props.resetInsertFromNavBar();
                                this.props.closeAddGeometricElementForm(this.props.map);
                                this.props.removeAllAddedInteractions(this.props.map);
                                this.props.refreshLayersCollection();
                                jQuery('#addGeometricElementButton').click();
                                let resetValues = this.resetValues;
                                resetForm({ values: resetValues });
                                this.setState({ files: [] });
                            },
                                errors => {
                                    this.setState({ backendErrors: errors });
                                }
                            ));
                        }

                        this.forceUpdate();
                        setSubmitting(false);
                    }}
                >
                    {({ setFieldValue, setFieldError, values, errors, resetForm }) =>
                        <div id="addGeometricElementContainer">
                            <Modal.Header>
                                <Modal.Title>
                                    {modalTitle()}
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Form className="needs-validation novalidate" id="addGeometricElementForm">
                                    {errors ? this.setState({ formikErrors: errors }) : ""}
                                    <Errors errors={this.state.backendErrors} onClose={() => this.setState({ backendErrors: null })} />
                                    {this.props.geometricElementType.listAttributeType.map(attributeType =>

                                        (attributeType.attributeType.dataType === "MULTISELECT" || attributeType.attributeType.dataType === "SELECT") ?
                                            <this.inputSelector
                                                description={attributeType.attributeType.code.code}
                                                placeholder={<InternationalizationRender
                                                    locale={this.props.locale}
                                                    listInternationalization={this.props.allCodes}
                                                    value={attributeType.attributeType.code.code}
                                                />
                                                }
                                                required={attributeType.mandatory}
                                                key={attributeType.attributeType.id}
                                                val={
                                                    Object.values(this.props.allCodes).filter(code => code.codeGroup.id ===
                                                        attributeType.attributeType.valueGroupCode.id)
                                                }
                                                multiple={attributeType.attributeType.dataType === "MULTISELECT"}
                                                locale={this.props.locale}
                                                errors={errors}
                                                setFieldError={setFieldError}
                                                attributeType={attributeType.attributeType}
                                            />
                                            :
                                            attributeType.attributeType.dataType === "TEXT" ?
                                                <div key={attributeType.attributeType.id + "div1"}>
                                                    <div className="form-group" key={attributeType.attributeType.id + "div"}>
                                                        <label htmlFor={attributeType.attributeType.code.code}
                                                            className={attributeType.mandatory ? "font-weight-bold required" : "font-weight-bold"}
                                                            key={attributeType.attributeType.id + "label"}
                                                        >
                                                            <InternationalizationRender
                                                                locale={this.props.locale}
                                                                listInternationalization={this.props.allCodes}
                                                                value={attributeType.attributeType.code.code}
                                                            />&nbsp;
                                                            {this.hasHelp(attributeType.attributeType.helpCode) ?
                                                                <sup className="btn-link" style={{ cursor: "pointer" }}
                                                                    id={"help" + attributeType.attributeType.code.code}
                                                                    aria-hidden={true}
                                                                    data-toggle="tooltip"
                                                                    data-placement="left"
                                                                    data-original-title={

                                                                        this.getInternationalization(
                                                                            this.props.locale,
                                                                            this.props.allCodes,
                                                                            attributeType.attributeType.helpCode.code
                                                                        )
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                                                </sup>
                                                                : ""
                                                            }
                                                        </label>
                                                        <Field as="input" type="text" className="form-control"
                                                            id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                                            key={attributeType.attributeType.id + "field"}
                                                        />
                                                    </div>
                                                    <ErrorMessage name={attributeType.attributeType.code.code} render={(msg) =>
                                                        <div id={attributeType.attributeType.code.code + 'error'}
                                                            className="alert alert-danger alert-dismissible fade show" role="alert"
                                                            hidden={typeof errors[attributeType.attributeType.code.code] === "undefined"}
                                                            key={attributeType.attributeType.id + "divError"}
                                                        >
                                                            <button type="button" className="close" aria-label="Close"
                                                                onClick={() => setFieldError(`${attributeType.attributeType.code.code}`, undefined)}
                                                                key={attributeType.attributeType.id + "buttonError"}
                                                            >
                                                                <span aria-hidden="true" key={attributeType.attributeType.id + "span"}>&times;</span>
                                                            </button>
                                                            {msg}
                                                        </div>
                                                    }
                                                        key={attributeType.attributeType.id + "errorMessage"}
                                                    />
                                                </div>
                                                :
                                                attributeType.attributeType.dataType === "DATE" ?
                                                    <div key={attributeType.attributeType.id + "div1"}>
                                                        <div className="form-group" key={attributeType.attributeType.id + "div"}>
                                                            <label htmlFor={attributeType.attributeType.code.code}
                                                                className={attributeType.mandatory ? "font-weight-bold required" : "font-weight-bold"}
                                                                key={attributeType.attributeType.id + "label"}
                                                            >
                                                                <InternationalizationRender
                                                                    locale={this.props.locale}
                                                                    listInternationalization={this.props.allCodes}
                                                                    value={attributeType.attributeType.code.code}
                                                                />
                                                                {this.hasHelp(attributeType.attributeType.helpCode) ?
                                                                    <sup className="btn-link" style={{ cursor: "pointer" }}
                                                                        id={"help" + attributeType.attributeType.code.code}
                                                                        data-toggle="tooltip"
                                                                        data-placement="left"
                                                                        data-original-title={

                                                                            this.getInternationalization(
                                                                                this.props.locale,
                                                                                this.props.allCodes,
                                                                                attributeType.attributeType.helpCode.code
                                                                            )
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                                                    </sup>
                                                                    : ""
                                                                }
                                                            </label>

                                                            <Field as="input" type="date" className="form-control"
                                                                id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                                                key={attributeType.attributeType.id + "field"}
                                                            />
                                                        </div>
                                                        <ErrorMessage name={attributeType.attributeType.code.code} render={(msg) =>
                                                            <div id={attributeType.attributeType.code.code + 'error'}
                                                                className="alert alert-danger alert-dismissible fade show" role="alert"
                                                                hidden={typeof errors[attributeType.attributeType.code.code] === "undefined"}
                                                                key={attributeType.attributeType.id + "divError"}
                                                            >
                                                                <button type="button" className="close" aria-label="Close"
                                                                    onClick={() => setFieldError(`${attributeType.attributeType.code.code}`, undefined)}
                                                                    key={attributeType.attributeType.id + "buttonError"}
                                                                >
                                                                    <span aria-hidden="true" key={attributeType.attributeType.id + "span"}>&times;</span>
                                                                </button>
                                                                {msg}
                                                            </div>
                                                        }
                                                            key={attributeType.attributeType.id + "error"}
                                                        />
                                                    </div>
                                                    :
                                                    attributeType.attributeType.dataType === "DATE_TIME" ?
                                                        <div key={attributeType.attributeType.id + "div1"}>
                                                            <div className="form-group" key={attributeType.attributeType.id + "div"}>
                                                                <label htmlFor={attributeType.attributeType.code.code}
                                                                    className={attributeType.mandatory ? "font-weight-bold required" : "font-weight-bold"}
                                                                    key={attributeType.attributeType.id + "label"}
                                                                >
                                                                    <InternationalizationRender
                                                                        locale={this.props.locale}
                                                                        listInternationalization={this.props.allCodes}
                                                                        value={attributeType.attributeType.code.code}
                                                                    />
                                                                    &nbsp;
                                                                    {this.hasHelp(attributeType.attributeType.helpCode) ?
                                                                        <sup className="btn-link" style={{ cursor: "pointer" }}
                                                                            id={"help" + attributeType.attributeType.code.code}
                                                                            data-toggle="tooltip"
                                                                            data-placement="left"
                                                                            data-original-title={

                                                                                this.getInternationalization(
                                                                                    this.props.locale,
                                                                                    this.props.allCodes,
                                                                                    attributeType.attributeType.helpCode.code
                                                                                )
                                                                            }
                                                                            data-html="true"
                                                                        >
                                                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                                                        </sup>
                                                                        : ""
                                                                    }
                                                                </label>
                                                                <Field type="datetime-local" className="form-control"
                                                                    id={attributeType.attributeType.code.code}
                                                                    name={attributeType.attributeType.code.code}
                                                                    key={attributeType.attributeType.id + "field"}
                                                                />
                                                            </div>
                                                            <ErrorMessage name={attributeType.attributeType.code.code} render={(msg) =>
                                                                <div id={attributeType.attributeType.code.code + 'error'}
                                                                    className="alert alert-danger alert-dismissible fade show" role="alert"
                                                                    hidden={typeof errors[attributeType.attributeType.code.code] === "undefined"}
                                                                    key={attributeType.attributeType.id + "divError"}
                                                                >
                                                                    <button type="button" className="close" aria-label="Close"
                                                                        onClick={() =>
                                                                            setFieldError(`${attributeType.attributeType.code.code}`, undefined)
                                                                        }
                                                                        key={attributeType.attributeType.id + "buttonError"}
                                                                    >
                                                                        <span aria-hidden="true" key={attributeType.attributeType.id + "span"}>
                                                                            &times;
                                                                        </span>
                                                                    </button>
                                                                    {msg}
                                                                </div>
                                                            }
                                                            />
                                                        </div>
                                                        :
                                                        attributeType.attributeType.dataType === "NUMBER" ?
                                                            <div key={attributeType.attributeType.id + "div1"}>
                                                                <div className="form-group" key={attributeType.attributeType.id + "div"}>
                                                                    <label htmlFor={attributeType.attributeType.code.code}
                                                                        className={attributeType.mandatory ?
                                                                            "font-weight-bold required" : "font-weight-bold"
                                                                        }
                                                                        key={attributeType.attributeType.id + "label"}
                                                                    >
                                                                        <InternationalizationRender
                                                                            locale={this.props.locale}
                                                                            listInternationalization={this.props.allCodes}
                                                                            value={attributeType.attributeType.code.code}
                                                                        />
                                                                        &nbsp;
                                                                        {this.hasHelp(attributeType.attributeType.helpCode) ?
                                                                            <sup className="btn-link" style={{ cursor: "pointer" }}
                                                                                id={"help" + attributeType.attributeType.code.code}
                                                                                data-toggle="tooltip"
                                                                                data-placement="left"
                                                                                data-original-title={

                                                                                    this.getInternationalization(
                                                                                        this.props.locale,
                                                                                        this.props.allCodes,
                                                                                        attributeType.attributeType.helpCode.code
                                                                                    )
                                                                                }
                                                                                data-html="true"
                                                                            >
                                                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                                                            </sup>
                                                                            : ""
                                                                        }
                                                                    </label>
                                                                    <Field as="input" type="number" className="form-control"
                                                                        id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                                                                        key={attributeType.attributeType.id + "field"}
                                                                    />
                                                                </div>
                                                                <ErrorMessage name={attributeType.attributeType.code.code} render={(msg) =>
                                                                    <div id={attributeType.attributeType.code.code + 'error'}
                                                                        className="alert alert-danger alert-dismissible fade show" role="alert"
                                                                        hidden={typeof errors[attributeType.attributeType.code.code] === "undefined"}
                                                                        key={attributeType.attributeType.id + "divError"}
                                                                    >
                                                                        <button type="button" className="close" aria-label="Close"
                                                                            onClick={() =>
                                                                                setFieldError(`${attributeType.attributeType.code.code}`, undefined)
                                                                            }
                                                                            key={attributeType.attributeType.id + "buttonError"}
                                                                        >
                                                                            <span aria-hidden="true" key={attributeType.attributeType.id + "span"}>
                                                                                &times;
                                                                            </span>
                                                                        </button>
                                                                        {msg}
                                                                    </div>
                                                                }
                                                                />
                                                            </div>
                                                            :
                                                            attributeType.attributeType.dataType === "FLOAT" ?
                                                                <div key={attributeType.attributeType.id + "div1"}>
                                                                    <div className="form-group" key={attributeType.attributeType.id + "div"}>
                                                                        <label htmlFor={attributeType.attributeType.code.code}
                                                                            className={attributeType.mandatory ?
                                                                                "font-weight-bold required" : "font-weight-bold"
                                                                            }
                                                                            key={attributeType.attributeType.id + "label"}
                                                                        >
                                                                            <InternationalizationRender
                                                                                locale={this.props.locale}
                                                                                listInternationalization={this.props.allCodes}
                                                                                value={attributeType.attributeType.code.code}
                                                                            />
                                                                            &nbsp;
                                                                            {this.hasHelp(attributeType.attributeType.helpCode) ?
                                                                                <sup className="btn-link" style={{ cursor: "pointer" }}
                                                                                    id={"help" + attributeType.attributeType.code.code}
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="left"
                                                                                    data-original-title={

                                                                                        this.getInternationalization(
                                                                                            this.props.locale,
                                                                                            this.props.allCodes,
                                                                                            attributeType.attributeType.helpCode.code
                                                                                        )
                                                                                    }
                                                                                    data-html="true"
                                                                                >
                                                                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                                                                </sup>
                                                                                : ""
                                                                            }
                                                                        </label>
                                                                        <Field as="input" type="number" step="any" className="form-control"
                                                                            id={attributeType.attributeType.code.code}
                                                                            name={attributeType.attributeType.code.code}
                                                                            key={attributeType.attributeType.id + "field"}
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name={attributeType.attributeType.code.code} render={(msg) =>
                                                                        <div id={attributeType.attributeType.code.code + 'error'}
                                                                            className="alert alert-danger alert-dismissible fade show" role="alert"
                                                                            hidden={typeof errors[attributeType.attributeType.code.code] === "undefined"}
                                                                            key={attributeType.attributeType.id + "divError"}
                                                                        >
                                                                            <button type="button" className="close" aria-label="Close"
                                                                                onClick={() =>
                                                                                    setFieldError(`${attributeType.attributeType.code.code}`, undefined)
                                                                                }
                                                                                key={attributeType.attributeType.id + "buttonError"}
                                                                            >
                                                                                <span aria-hidden="true" key={attributeType.attributeType.id + "span"}>
                                                                                    &times;
                                                                                </span>
                                                                            </button>
                                                                            {msg}
                                                                        </div>
                                                                    }
                                                                    />
                                                                </div>
                                                                : ""

                                    )}

                                    <div className="form-group">
                                        <label htmlFor="description" className="font-weight-bold required">
                                            <FormattedMessage id="project.elements.description" />
                                        </label>
                                        <Field as="textarea" name="description" className="form-control"
                                            id="description" rows="3" />
                                    </div>
                                    <ErrorMessage name="description" render={(msg) =>
                                        <div id='descriptionError' className="alert alert-danger alert-dismissible fade show" role="alert"
                                            hidden={typeof errors.description === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("description", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field as="input" type="checkbox" id="isPublic" name="isPublic" />
                                            </div>
                                        </div>
                                        <label htmlFor="isPublic" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.isPublic" />
                                        </label>
                                    </div>

                                    {this.props.user && this.props.user.userRoleDto.code === "ADMIN" ?
                                        <div>

                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <Field as="input" type="checkbox" id="isReviewed" name="isReviewed" />
                                                    </div>
                                                </div>
                                                <label htmlFor="isReviewed" className="btn input-group-text">
                                                    <FormattedMessage id="project.elements.isReviewed" />
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label className="font-weight-bold" htmlFor="internalComments">
                                                    <FormattedMessage id="project.elements.internalComments" />
                                                </label>
                                                <Field as="textarea" name="internalComments" className="form-control" id="internalComments" />
                                            </div>
                                        </div>
                                        : ""}

                                    <div className="form-group">
                                        <label className="font-weight-bold" htmlFor="filePond">
                                            <FormattedMessage id="project.elements.files" />
                                        </label>
                                        <FilePond.FilePond
                                            ref={ref => this.pond = ref}
                                            id="filePond"
                                            allowMultiple={true}
                                            labelIdle={this.props.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                                            labelTapToCancel={this.props.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                                            labelFileLoading={this.props.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                                            labelFileWaitingForSize={this.props.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                                            onupdatefiles={fileItems => {
                                                this.setState({ files: fileItems });
                                                setFieldValue("file", fileItems.map(fileItem => this.toFileToInsertJSON(fileItem)));
                                            }}
                                            files={this.state.files}
                                        />
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button id="addGeometricElementSubmit" type="submit" className="btn btn-primary"
                                    form="addGeometricElementForm"
                                >
                                    <FormattedMessage id="project.app.Body.accept" />
                                </button>
                                <Button id="addGeometricElementCancel" type="reset" variant="danger"
                                    onClick={() => {
                                        if (this.state.modifyFeature) {
                                            this.setState({ modifyFeature: null });
                                            this.props.clearDrawSource();
                                            this.props.removeAllAddedInteractions(this.props.map);
                                            this.props.closeAddGeometricElementForm(this.props.map);
                                            this.props.resetModifyFeature();
                                            let resetValues = this.resetValues;
                                            resetForm({ values: resetValues });
                                            this.setState({ files: [] });
                                        } else {
                                            this.props.clearDrawSource();
                                            this.setState({ modifyFeature: null });
                                            this.props.removeAllAddedInteractions(this.props.map);
                                            this.props.resetInsertFromNavBar();
                                            this.props.closeAddGeometricElementForm(this.props.map);
                                            jQuery('#addGeometricElementButton').click();
                                            let resetValues = this.resetValues;
                                            resetForm({ values: resetValues });
                                            this.setState({ files: [] });
                                        }
                                    }}
                                >
                                    <FormattedMessage id="project.app.Body.cancel" />
                                </Button>
                            </Modal.Footer>
                        </div>
                    }
                </Formik>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(connect(mapStateToProps)(AddGeometricElement));