const getModuleState = state => state.geometricElement;

export const getGeometricElementType = state =>
    getModuleState(state).geometricElementType;

export const getGeometricElementsByElementType = state =>
    getModuleState(state).geometricElementsByElementType;

export const getAllCodes = state =>
    getModuleState(state).allCodes;

export const getAllGeometricElementType = state =>
    getModuleState(state).allGeometricElementType;

export const getAllAttributeType = state =>
    getModuleState(state).allAttributeType;

export const getListAllAttributeType = state =>
    getModuleState(state).listAllAttributeType;

export const getListAllGeometricElementType = state =>
    getModuleState(state).listAllGeometricElementType;

export const getTotalAttributeType = state =>
    getModuleState(state).totalAttributeType;

export const getTotalGeometricElementType = state =>
    getModuleState(state).totalGeometricElementType;

//Center by population entities selectors

export const getListProvinces = state =>
    getModuleState(state).listProvinces;

export const getListCouncils = state =>
    getModuleState(state).listCouncils;

export const getListParishes = state =>
    getModuleState(state).listParishes;

export const getListEntityPopulations = state =>
    getModuleState(state).listEntityPopulations;

export const getListCounties = state =>
    getModuleState(state).listCounties;

//GeometricLayerService selectors

export const getListAllGeometricLayerGroup = state =>
    getModuleState(state).listAllGeometricLayerGroup

export const getAllGeometricLayerGroup = state =>
    getModuleState(state).allGeometricLayerGroup

export const getAllGeometricLayer = state =>
    getModuleState(state).allGeometricLayer

export const getTotalGeometricLayerGroup = state =>
    getModuleState(state).totalGeometricLayerGroup

export const getTotalGeometricLayer = state =>
    getModuleState(state).totalGeometricLayer

// Map current zoom
export const getMapCurrentExtent = state => 
    getModuleState(state).mapCurrentExtent