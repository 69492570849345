import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/app/components/App';
import * as serviceWorker from './serviceWorker';
import backend from './backend';
import app from './modules/app';
import { NetworkError } from './backend';
import { Provider } from 'react-redux';
import configureStore from './store';
import jQuery from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

//In order to avoid name conflicts with jQuery-UI
jQuery.fn.bstooltip = jQuery.fn.tooltip;

//Configure store 
const store = configureStore();

/* Configure backend proxy. */
backend.init(error => store.dispatch(app.actions.error(new NetworkError())));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

serviceWorker.register();
