import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'react-bootstrap';
import * as selectors from '../selectors';
import * as actions from '../actions';
import { Errors } from '../../common';
import * as Yup from 'yup';
import jQuery from 'jquery';


const mapStateToProps = function (state) {

    return {
        allUsers: selectors.getAllUsers(state),
        totalUserAccounts: selectors.getTotalUserAccounts(state)
    }
}

const Schema = Yup.object().shape({
    newPassword: Yup.string().when("changePassword", {
        is: (val) => val === true,
        then: Yup.string().required(<FormattedMessage
            id="project.app.Body.field.required"
            values={{
                field: <FormattedMessage id="project.users.newPassword" />,
            }} />
        ),
        otherwise: Yup.string().notRequired()
    }),
    confirmNewPassword: Yup.string().when("changePassword", {
        is: (val) => val === true,
        then: Yup.string().when("newPassword", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("newPassword")],
                <FormattedMessage id="project.users.confirmPassword.error" />
            ).required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.users.confirmNewPassword" />
                }} />)
        }),
        otherwise: Yup.string().notRequired()
    })
});

const LockUserAccount = ({ backendErrors, modalShow, userAccountToLock, setBackendErrors, handleSubmit, hideModalWindow, ...props }) => {

    var [formikErrors, setFormikErrors] = useState(null);

    if (userAccountToLock && modalShow) {

        if (formikErrors || backendErrors) {
            let container = jQuery('.modal-body');
            if (backendErrors) {
                container.scrollTop(0);
            } else {
                if (formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }

        return (
            <Formik
                initialValues={{
                    lock: userAccountToLock.locked,
                    changePassword: false,
                    newPassword: '',
                    confirmNewPassword: ''
                }}
                validationSchema={Schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting }) => {
                    let lockedUserAccount = userAccountToLock;
                    lockedUserAccount.locked = values.lock;
                    if (values.changePassword) {
                        lockedUserAccount.password = values.newPassword;
                    }

                    props.dispatch(actions.lockUserAccount(userAccountToLock.id, lockedUserAccount,
                        () => {
                            handleSubmit();
                        },
                        errors => {
                            setBackendErrors(errors)
                        }));
                    setSubmitting(false);
                }}
            >
                {({ values, errors, setFieldError }) =>
                    <Modal show={modalShow} onHide={hideModalWindow} scrollable={true} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage
                                    id="project.users.modal.modifyUser"
                                    values={{
                                        user: <FormattedMessage id="project.users.user" />,
                                        login: userAccountToLock.login
                                    }} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="lockUserAccountForm">

                                {errors ? setFormikErrors(errors) : ""}
                                <Errors errors={backendErrors} onClose={() => setBackendErrors(null)} />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <Field as="input" type="checkbox" id="lock" name="lock" />
                                        </div>
                                    </div>
                                    <label htmlFor="lock" className="btn input-group-text">
                                        <FormattedMessage id="project.users.lock" />
                                    </label>
                                </div>
                                <div className="card">
                                    <h5 className="card-header">
                                        <FormattedMessage id="project.users.password" />
                                    </h5>
                                    <div className="card-body">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <Field as="input" type="checkbox" id="changePassword" name="changePassword" />
                                                </div>
                                            </div>
                                            <label htmlFor="changePassword" className="btn input-group-text">
                                                <FormattedMessage id="project.users.changePassword" />
                                            </label>
                                        </div>
                                        <div>
                                            <div className="form-group">
                                                <label className={values.changePassword ? "font-weight-bold required" : "font-weight-bold"}
                                                    htmlFor="newPassword"
                                                >
                                                    <FormattedMessage id='project.users.newPassword' />
                                                </label>
                                                <Field id="newPassword" name="newPassword" type="password" className="form-control"
                                                    disabled={!values.changePassword}
                                                />
                                            </div>
                                            <ErrorMessage name="newPassword" render={(msg) =>
                                                <div id="newPasswordError" className="alert alert-danger alert-dismissible fade show"
                                                    role="alert" hidden={typeof errors.newPassword === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("newPassword", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />

                                            <div className="form-group">
                                                <label className={values.changePassword ? "font-weight-bold required" : "font-weight-bold"}
                                                    htmlFor="confirmNewPassword"
                                                >
                                                    <FormattedMessage id='project.users.confirmNewPassword' />
                                                </label>
                                                <Field id="confirmNewPassword" name="confirmNewPassword" type="password" className="form-control"
                                                    disabled={!values.changePassword}
                                                />
                                            </div>
                                            <ErrorMessage name="confirmNewPassword" render={(msg) =>
                                                <div id="confirmNewPasswordError" className="alert alert-danger alert-dismissible fade show"
                                                    role="alert"
                                                    hidden={typeof errors.confirmNewPassword === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("confirmNewPassword", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                        </div>
                                    </div>
                                </div>

                            </Form>

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" id="lockUserSubmit" className="btn btn-primary"
                                form="lockUserAccountForm"
                            >
                                <FormattedMessage id="project.app.Body.save" />
                            </button>
                            <Button variant="danger" onClick={hideModalWindow}>
                                <FormattedMessage id="project.app.Body.cancel" />
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik>
        );
    }

    return (null);
}

export default connect(mapStateToProps)(LockUserAccount);