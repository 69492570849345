export default {
    "project.global.exceptions.NetworkError": 'Non se puido conectar co servidor',
    'project.app.Header.riskzones': 'Zonas de Risco',
    'project.app.Header.viewer': 'Visor xeográfico',
    'project.app.Header.geometricElements': 'Táboas de elementos',
    'project.app.Header.admin': 'Administración',
    'project.app.Header.admin.users': 'Usuarios',
    'project.app.Header.admin.elementType': 'Tipos de elementos',
    'project.app.Header.admin.attributeType': 'Tipos de atributos',
    'project.app.Header.admin.configurationParameters': 'Parámetros de configuración',
    'project.app.Header.admin.layer': 'Capas',
    'project.app.Header.admin.layerGroup': 'Grupos de capas',
    'project.app.Header.myContributions': 'As miñas contribucións',
    'project.app.Header.addGeometricElement': 'Engadir novo elemento',
    'project.app.Header.login': 'Iniciar sesión',
    'project.app.Header.signUp': 'Crear conta',
    'project.app.Header.logout': 'Pechar sesión',
    'project.app.Header.password': 'Contrasinal',
    'project.app.Body.search': 'Buscar',
    'project.app.Body.accept': 'Aceptar',
    'project.app.Body.cancel': 'Cancelar',
    'project.app.Body.back': 'Anterior',
    'project.app.Body.next': 'Seguinte',
    'project.app.Body.save': 'Gardar',
    'project.app.Body.return': '← Volver á páxina principal',
    'project.app.Body.field.required': 'O campo "{field}" é obrigatorio',
    'project.app.Body.field.positive': 'O campo "{field}" debe ser positivo',

    'project.common.close': 'Pechar',
    'project.common.ErrorDialog.title': 'Erro',
    'project.common.permissionDenied': 'Non tes permiso para ver esta páxina',
    'project.common.mustLogin': 'Debes iniciar sesión para ver esta páxina',
    'project.common.action': 'Accións',
    'project.common.modify': 'Modificar',
    'project.common.center': 'Centrar',
    'project.common.modifyOnViewer': 'Modificar no visor xeográfico',
    'project.common.centerOnViewer': 'Ver no mapa',
    'project.common.selectOneOption': 'Selecciona unha opción',
    'project.common.viewElements': 'Ver elementos',
    'project.common.attributes': 'Atributos',
    'project.common.delete': 'Eliminar',
    'project.common.deleteSomething': 'Eliminar {something}',
    'project.common.download': 'Descargar',
    'project.common.viewFiles': 'Ver fotos',
    'project.common.deleteFile': 'Eliminar ficheiro',
    'project.common.addFilesButton': 'Engadir ficheiros',
    'project.common.addFiles': 'Engadir ficheiros ao elemento {id}',
    'project.common.yes': 'Si',
    'project.common.no': 'Non',
    'project.common.filters': 'Filtros',
    'project.common.filters.clear': 'Quitar todos os filtros',
    'project.common.filepond.labelIdle': 'Arrastra e solta os teus ficheiros ou <span class="filepond--label-action">Examina</span>',
    'project.common.filepond.labelTapCancel': 'Pulsa para cancelar',
    'project.common.filepond.labelLoading': 'Cargando',
    'project.common.filepond.labelWaitForSize': 'Esperando polo tamaño',
    'project.common.tables.totalElements': 'Amosando do {from} ao {to} de {total} ',
    'project.common.tables.filter.text.placeholder': 'Buscar {column}',
    'project.common.tables.filter.select.placeholder': 'Seleccionar {column}',

    'project.elements.new': 'Crear {element}',
    'project.elements.myElements.message': "Nesta táboa amósanse os elementos públicos. Consulte os creados por vostede en {link}",
    'project.elements.anonymous.message': "Para introducir un novo elemento debe {link}",
    'project.elements.addGeometricElement': 'Engadir novo elemento',
    'project.elements.modify': 'Modificar {element}',
    'project.elements.noElements': 'Non existen elementos para o tipo seleccionado',
    'project.elements.types.noElements': 'Non existen tipos de elementos',
    'project.elements.attributes.noElements': 'Non existen tipos de atributos',
    'project.elements.map.fitToExtentTip': 'Ver a máxima extensión',
    'project.elements.map.zoomToExtentTip': 'Zoom por encadre',
    'project.elements.map.popupTip': 'Consultar información sobre elementos mediante clic',
    'project.elements.map.measureLengthTip': 'Medir distancias',
    'project.elements.map.measureAreaTip': 'Medir áreas',
    'project.elements.map.addElementTip': 'Engadir novo elemento',
    'project.elements.map.fullScreenTip': 'Pantalla completa',
    'project.elements.map.background': 'Fondo',
    'project.elements.map.otherLayers': 'Outras capas',
    'project.elements.map.myElements': 'As miñas contribucións',
    'project.elements.map.elements': 'Elementos',
    'project.elements.map.orthophotos': 'Ortofotos',
    'project.elements.map.administrativeLimits': 'Divisións',
    'project.elements.map.cityCouncils': 'Concellos',
    'project.elements.map.hygrography': 'Hidrografía',
    'project.elements.map.hydrography.channels': 'Canles',
    'project.elements.map.hydrography.reservoirs': 'Encoros',
    'project.elements.map.hydrography.lagoons': 'Lagoas',
    'project.elements.map.hydrography.rivers': 'Ríos',
    'project.elements.map.provinces': 'Provincias',
    'project.elements.map.councils': 'Concellos',
    'project.elements.map.parishes': 'Parroquias',
    'project.elements.map.entityPopulations': 'Entidades de poboación',
    'project.elements.map.counties': 'Comarcas',
    'project.elements.map.entityPopulation': 'Entidade de poboación',
    'project.elements.map.selectProvince': '-- Seleccione provincia --',
    'project.elements.map.selectCouncil': '-- Seleccione concello --',
    'project.elements.map.selectParish': '-- Seleccione parroquia --',
    'project.elements.map.selectEntityPopulation': '-- Seleccione entidade de poboación --',
    'project.elements.map.searchEntityPopulation': 'Buscar',
    'project.elements.map.progressiveCenter': 'Busca progresiva',
    'project.elements.map.searchCenter': 'Busca directa',
    'project.elements.map.searchType': 'Tipo de busca',
    'project.elements.selectOneOption': 'Selecciona unha opción',
    'project.elements.files': 'Ficheiros asociados',
    'project.elements.files.hideShow': 'Amosar/Ocultar ficheiros asociados',
    'project.elements.files.noFiles': 'Este elemento non ten ficheiros asociados',
    'project.elements.values': 'Valores',
    'project.elements.name': 'Nome',
    'project.elements.geometryType': 'Tipo de xeometría',
    'project.elements.pointStyleName': 'Nome do estilo para punto',
    'project.elements.lineStyleName': 'Nome do estilo para liña',
    'project.elements.polygonStyleName': 'Nome do estilo para polígono',
    'project.elements.description': 'Descrición',
    'project.elements.geom': 'Xeometría',
    'project.elements.geom.LineString': 'Liña',
    'project.elements.geom.Point': 'Punto',
    'project.elements.geom.Polygon': 'Polígono',
    'project.elements.geom.LinearRing': 'Anel lineal',
    'project.elements.geom.MultiPoint': 'Multipunto',
    'project.elements.geom.MultiLineString': 'Multilínea',
    'project.elements.geom.MultiPolygon': 'Multipolígono',
    'project.elements.geom.Circle': 'Círculo',
    'project.elements.files.filename': 'Nome',
    'project.elements.files.format': 'Formato',
    'project.elements.files.type': 'Tipo mime',
    'project.elements.files.size': 'Tamaño',
    'project.elements.values.attribute': 'Atributo',
    'project.elements.values.value': 'Valor',
    'project.elements.values.order': 'Orde',
    'project.elements.creationDate': 'Introdución',
    'project.elements.modificationDate': 'Última modificación',
    'project.elements.lessCreationDate': 'Data de introdución igual ou anterior a',
    'project.elements.lessModificationDate': 'Data de modificación igual ou anterior a',
    'project.elements.moreCreationDate': 'Data de introdución igual ou posterior a',
    'project.elements.moreModificationDate': 'Data de modificación igual ou posterior a',
    'project.elements.isPublic': 'É público?',
    'project.elements.isReviewed': 'Está revisado?',
    'project.elements.internalComments': 'Obs. internas',
    'project.elements.owner': 'Autor',
    'project.elements.type.add': 'Engadir tipo de elemento',
    'project.elements.type.add.name': 'Nome do tipo de elemento',
    'project.elements.type.modify': 'Modificar tipo de elemento',
    'project.elements.type.modify.name': 'Nome do tipo de elemento',
    'project.elements.type.add.attribute': 'Atributo',
    'project.elements.type.showAttributes': 'Amosar/Ocultar atributos asociados',
    'project.elements.delete.message': 'Vai a eliminar "{element}", desexa continuar?',
    "project.elements.files.fileNoExistsHeader": 'O ficheiro non existe',
    "project.elements.files.fileNoExistsBody": 'Non se atopou o ficheiro',

    'project.elements.attributes.noAttributes': 'Este tipo elemento non ten atributos asociados',
    'project.elements.attributes.dataType': 'Tipo de dato',
    'project.elements.attributes.dataType.pick': 'Selecciona o tipo de dato da lista',
    'project.elements.attributes.attributeOrder': 'Orde',
    'project.elements.attributes.code': 'Código',
    'project.elements.attributes.values': 'Posibles valores',
    'project.elements.attributes.showHideValues': 'Amosar/Ocultar posibles valores',
    'project.elements.attributes.add': 'Engadir tipo de atributo',
    'project.elements.attributes.modify': 'Modificar tipo de atributo',
    'project.elements.attributes.attributeName': 'Nome do atributo',
    'project.elements.attributes.help': 'Axuda',
    'project.elements.attributes.translations': 'Internacionalización',
    'project.elements.attributes.maxSize': 'Tamaño máximo',
    'project.elements.attributes.mandatory': 'Obrigatorio',
    'project.elements.attributes.notMandatory': 'Non obrigatorio',
    'project.elements.attributes.type': 'Tipo de atributo',
    'project.elements.attributes.description': 'Descrición',
    'project.elements.attributes.name': 'Nome',

    'project.elements.geometricLayer.layerGroup.name': 'Nome público',
    'project.elements.geometricLayer.layerGroup.includesInsideLayerGroup': 'Subgrupo de "Outras capas"?',
    'project.elements.geometricLayer.layerGroup.order': 'Orde',
    'project.elements.geometricLayer.layerGroup.initiallyOpen': 'Inicialmente aberto?',
    'project.elements.geometricLayer.layerGroup.showLayers': 'Amosar/Ocultar capas asociadas',
    'project.elements.geometricLayer.layerGroup.add': 'Engadir grupo de capas',
    'project.elements.geometricLayer.layerGroup.modify': 'Modificar grupo de capas',
    'project.elements.geometricLayer.layerGroup.deleteMessage': 'Vai eliminar o grupo de capas "{element}", desexa continuar?',
    'project.elements.geometricLayer.layerGroup.noElements': 'Non existe ningún grupo de capas',
    'project.elements.geometricLayer.layer.internalName': 'Nome interno',
    'project.elements.geometricLayer.layer.name': 'Nome público',
    'project.elements.geometricLayer.layer.order': 'Orde',
    'project.elements.geometricLayer.layer.initiallyVisible': 'Inicialmente visible?',
    'project.elements.geometricLayer.layer.serverUrl': 'URL do servidor',
    'project.elements.geometricLayer.layer.type': 'Tipo',
    'project.elements.geometricLayer.layer.internalComments': 'Observacións internas',
    'project.elements.geometricLayer.layer.geometricLayerGroup': 'Grupo de capas',
    'project.elements.geometricLayer.layer.add': 'Engadir capa',
    'project.elements.geometricLayer.layer.modify': 'Modificar capa',
    'project.elements.geometricLayer.layer.noElements': 'Non existe ningunha capa',
    'project.elements.geometricLayer.layer.deleteMessage': 'Vai eliminar a capa "{element}", ¿Desexa continuar?',
    
    'project.configurationParameter.name': 'Nome',
    'project.configurationParameter.code': 'Código',
    'project.configurationParameter.value': 'Valor',
    'project.configurationParameter.userInterface': 'Interface de usuario?',
    'project.configurationParameter.noDataIndication': 'Non existen parámetros de configuración',

    'project.users.password': 'Contrasinal',
    'project.users.confirmPassword': 'Confirmar contrasinal',
    'project.users.confirmPassword.error': 'Os contrasinais non coinciden',
    'project.users.locale': 'Idioma',
    'project.users.locale.pick': 'Selecciona un idioma da lista',
    'project.users.locale.galician': 'Galego',
    'project.users.locale.spanish': 'Español',
    'project.users.locale.english': 'Inglés',
    'project.users.login': 'Nome de usuario',
    'project.users.currentPassword': 'Contrasinal actual',
    'project.users.newPassword': 'Novo contrasinal',
    'project.users.confirmNewPassword': 'Confirme o novo contrasinal',
    'project.users.modal.modifyUser': '{user} {login}',
    'project.users.lock': 'Bloquear conta',
    'project.users.user': 'Usuario',
    'project.users.username': "Nome de usuario",
    'project.users.locked': "Está bloqueado?",
    'project.users.role': "Rol",
    'project.users.changePassword': 'Cambiar contrasinal',
    'project.users.createUser': 'Crear usuario',
    'project.users.noDataIndication': 'Non existen contas de usuario'
}