const getModuleState = state => state.users;

export const getUser = state =>
    getModuleState(state).user;

export const isLoggedIn = state =>
    getUser(state) !== null

export const getLogin = state =>
    isLoggedIn(state) ? getUser(state).login : null

export const getAllUsers = state =>
    getModuleState(state).allUsers

export const getAllRoles = state =>
    getModuleState(state).allRoles

export const getRoleByCode = state =>
    getModuleState(state).roleByCode

export const getTotalUserAccounts = state =>
    getModuleState(state).totalUserAccount