import * as actionTypes from './actionTypes';
import * as appSelectors from '../app/selectors';
import * as appActions from '../app/actions';
import backend from '../../backend';

const findAllConfigurationParametersCompleted = allConfigurationParameters => ({
    type: actionTypes.FIND_ALL_CONFIGURATION_PARAMETER_COMPLETED,
    allConfigurationParameters
});

const findTotalConfigurationParametersCompleted = totalConfigurationParameter => ({
    type: actionTypes.FIND_TOTAL_CONFIGURATION_PARAMETER_COMPLETED,
    totalConfigurationParameter
});


export const findAllConfigurationParameters = (page, size, sortField, sortOrder, onSuccess) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.configurationParameterService.findAllConfigurationParameter(page, size, sortField, sortOrder, result => {
        dispatch(findAllConfigurationParametersCompleted({ page, size, sortField, sortOrder, result }));
        backend.configurationParameterService.findAllConfigurationParameter(0, result.totalItems, null, null, totalResult => {
            dispatch(findTotalConfigurationParametersCompleted(totalResult));
            onSuccess();
            dispatch(appActions.loaded());
        })
    }, appSelectors.getLanguage(getState()));
};

const findUserInterfaceConfigurationParametersCompleted = userInterfaceConfigurationParameters => ({
    type: actionTypes.FIND_USER_INTERFACE_CONFIGURATION_PARAMETER_COMPLETED,
    userInterfaceConfigurationParameters
});

export const findUserInterfaceConfigurationParameters = () => (dispatch, getState) => {
    backend.configurationParameterService.findUserInterfaceConfigurationParameter((result) => {
        dispatch(findUserInterfaceConfigurationParametersCompleted(result));
    },
        appSelectors.getLanguage(getState())
    );
};

export const updateConfigurationParameter = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    dispatch(appActions.loading());
    backend.configurationParameterService.updateConfigurationParameter(id, json, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded());
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded());
        });
}