import React from 'react';
import * as actions from '../actions';
import * as selectors from '../selectors';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faListAlt, faChevronCircleDown, faChevronCircleUp, faPlus } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import { DeleteDialog } from '../../common';
import AddModifyGeometricElementType from './AddModifyGeometricElementType';
const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        user: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        allGeometricElementType: selectors.getAllGeometricElementType(state),
        totalGeometricElementType: selectors.getTotalGeometricElementType(state)
    }
}

class AllGeometricElementType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allGeometricElementType: null,
            listTotalGeometricElementType: null,
            filters: {},
            expandedRows: [],
            currentPage: 1,
            currentSize: 10,
            currentSortField: 'id',
            currentSortOrder: 'asc',
            backendErrors: null,
            deleteGeometricElementTypeDialogShow: false,
            deleteGeometricElementTypeId: "",
            deleteGeometricElementTypeCode: "",
            addModifyGeometricElementTypeModalShow: false,
            geometricElementTypeToModify: null
        }
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        let setBackendErrors = (backendError) => {
            this.setState({ backendErrors: backendError });
        }

        let handleTableChange = (page, sizePerPage, sortField, sortOrder, filters) => {
            if (Object.entries(filters).length > 0) {
                let result = this.state.listTotalGeometricElementType.items.filter((row) => {
                    let valid = true;
                    for (const dataField in filters) {
                        const { filterVal, filterType, comparator } = filters[dataField];

                        if (filterType === 'TEXT') {
                            if (comparator === Comparator.LIKE) {
                                if (dataField === 'name') {
                                    valid = getInternationalization(
                                        this.props.language,
                                        row.code,
                                        this.props.allCodes).toLocaleLowerCase()
                                        .indexOf(filterVal.toLocaleLowerCase()) > -1;
                                } else {
                                    valid = row[dataField].toString().toLocaleLowerCase().indexOf(
                                        filterVal.toLocaleLowerCase()) > -1;
                                }
                            } else {
                                valid = row[dataField].toLocaleLowerCase() === filterVal.toLocaleLowerCase();
                            }
                        }

                        if (filterType === 'SELECT') {
                            valid = row[dataField].toString() === filterVal;
                        }

                        if (!valid) break;
                    }
                    return valid;
                });
                if (sortOrder === 'asc') {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return 1;
                        } else if (b[sortField] > a[sortField]) {
                            return -1;
                        }
                        return 0;
                    });
                } else {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return -1;
                        } else if (b[sortField] > a[sortField]) {
                            return 1;
                        }
                        return 0;
                    });
                }
                let newList = this.state.allGeometricElementType;
                const currentIndex = (page - 1) * sizePerPage;
                newList.result.totalItems = result.length;
                newList.result.items = result.slice(currentIndex, currentIndex + sizePerPage);
                this.setState({ allGeometricElementType: newList });
            } else {
                this.props.dispatch(actions.findAllGeometricElementType(page <= 0 ? 0 : page - 1, sizePerPage,
                    sortField, sortOrder, () => {
                        this.setState({
                            allGeometricElementType: this.props.allGeometricElementType,
                            listTotalGeometricElementType: this.props.totalGeometricElementType
                        });
                    }));
            }
        }

        let handleBtnClick = (row) => {
            if (!this.state.expandedRows.includes(row.id)) {
                this.setState({ expandedRows: [row.id] });
            } else {
                this.setState({ expandedRows: [] });
            }
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                let geometryTypeFilterOptions = [];
                let allGeometryType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('GEOMETRY_TYPE') !== -1);
                allGeometryType.forEach((geometryType, index) => {
                    geometryTypeFilterOptions[index] = {
                        value: geometryType.code,
                        label: getInternationalization(this.props.language, geometryType.code, this.props.allCodes)
                    }
                });

                jQuery(function () {
                    jQuery('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                    jQuery('[data-toggle="tooltip"]').tooltip().click(function () {
                        jQuery('[data-toggle="tooltip"]').tooltip("hide");
                    });
                });

                const attributeColumns = [{
                    dataField: 'attributeType.id',
                    text: 'Id',
                    sort: true,
                    headerAlign: 'left'
                },
                {
                    dataField: 'attributeType.description',
                    text: this.props.intl.formatMessage({ id: "project.elements.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.attributeType.code.code} listInternationalization={this.props.allCodes} />
                    )
                }, {
                    dataField: 'attributeType.dataType',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.attributeType.dataType} listInternationalization={this.props.allCodes} />
                    )
                }, {
                    dataField: 'attributeOrder',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.attributeOrder" }),
                    headerAlign: 'left',
                    sort: true
                }
                ];

                const expandRow = {
                    renderer: row => (
                        <div className="card card-body" key={row.id + "container"}>
                            <h6 className="card-title text-left" key={row.id + "title"}>
                                <FormattedMessage id="project.common.attributes" />
                            </h6>
                            <BootstrapTable
                                bootstrap4
                                keyField='id'
                                columns={attributeColumns}
                                data={row.listAttributeType}
                                rowClasses="text-left"
                                defaultSorted={[{ dataField: 'attributeType.id', order: 'asc' }]}
                                noDataIndication={<FormattedMessage id="project.elements.attributes.noAttributes" />}
                                striped
                                condensed
                                key={row.id + 'table'}
                            />
                        </div>
                    ),
                    expandByColumnOnly: true,
                    expanded: this.state.expandedRows
                }

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    headerAlign: 'left',
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" })
                    })
                }, {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.code" }).toLocaleLowerCase() })
                    })
                }, {
                    dataField: 'name',
                    text: this.props.intl.formatMessage({ id: "project.elements.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.code} listInternationalization={this.props.allCodes} />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.name" }).toLocaleLowerCase() })
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.code, this.props.allCodes)
                }, {
                    dataField: 'geometryType',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometryType" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometryType" }).toLocaleLowerCase() }),
                        options: geometryTypeFilterOptions
                    })
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatExtraData: this.state.expandedRows,
                    formatter: (cellContent, row, rowIndex, formatExtraData) => (
                        <div className="text-left">
                            <span onClick={() => {
                                this.props.history.push({
                                    pathname: `/geometric_elements/${row.id}`,
                                    state: {
                                        row: row
                                    }
                                })
                                this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(row.id, 0, 10, null, null, null, null,
                                    null, null, null, null, null, null, null, null, null, null, () => { }));
                            }
                            }
                                className="btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"viewElements" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.viewElements" })}
                            >
                                <FontAwesomeIcon icon={faListAlt} />
                            </span>
                            <span
                                onClick={() => {
                                    this.props.dispatch(actions.findAdminGeometricElementsByElementTypeId(row.id, 0, 10, null, null, null, null, null,
                                        null, null, null, null, null, null, null, null, null,
                                        () => {
                                            this.setState({
                                                backendErrors: null,
                                                addModifyGeometricElementTypeModalShow: true,
                                                geometricElementTypeToModify: row
                                            })
                                        }));
                                }}
                                className="btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modify" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            <span onClick={() =>
                                this.setState({
                                    backendErrors: null,
                                    deleteGeometricElementTypeDialogShow: true,
                                    deleteGeometricElementTypeId: row.id,
                                    deleteGeometricElementTypeCode: row.code
                                })
                            }
                                className="btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"delete" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                            <span className="btn-link" id={"showHideAttributes" + row.id}
                                onClick={() => handleBtnClick(row)}
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.elements.type.showAttributes" })}
                            >
                                {<FontAwesomeIcon icon={!formatExtraData.includes(row.id) ? faChevronCircleDown : faChevronCircleUp} />}
                            </span>
                        </div>
                    )
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.elementType" /></h3>
                            <div className="text-right">
                                <button className="btn btn-primary" onClick={() => {
                                    this.setState({
                                        backendErrors: null,
                                        addModifyGeometricElementTypeModalShow: true,
                                        geometricElementTypeToModify: null
                                    })
                                }}
                                    id="addGeometricElementType"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.elements.type.add" />
                                </button>
                            </div>

                            {/* Add/Modify geometric element type dialog */}
                            <AddModifyGeometricElementType
                                modalShow={this.state.addModifyGeometricElementTypeModalShow}
                                geometricElementTypeToModify={this.state.geometricElementTypeToModify}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({ addModifyGeometricElementTypeModalShow: false });
                                    handleTableChange(this.state.currentPage, this.state.currentSize, this.state.currentSortField,
                                        this.state.currentSortOrder, this.state.filters);
                                    this.props.dispatch(actions.findListAllGeometricElementType());
                                }}
                                hideModalWindow={() => this.setState({ addModifyGeometricElementTypeModalShow: false })}
                            />

                            {/* Delete geometric element type dialog */}
                            <DeleteDialog
                                modalShow={this.state.deleteGeometricElementTypeDialogShow}
                                title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                                details={this.props.intl.formatMessage(
                                    { id: 'project.elements.delete.message' },
                                    { element: <b>{this.state.deleteGeometricElementTypeCode}</b> })
                                }
                                backendErrors={this.state.backendErrors}
                                hideModalWindow={() => { this.setState({ deleteGeometricElementTypeDialogShow: false }) }}
                                handleSubmit={() => {
                                    this.props.dispatch(actions.deleteGeometricElementType(this.state.deleteGeometricElementTypeId,
                                        () => {
                                            this.setState({ deleteGeometricElementTypeDialogShow: false });
                                            handleTableChange(this.state.currentPage, this.state.currentSize,
                                                this.state.currentSortField, this.state.currentSortOrder, this.state.filters);
                                            this.props.dispatch(actions.findListAllGeometricElementType());
                                        },
                                        (error) => setBackendErrors(error)))
                                }}
                                setBackendErrors={setBackendErrors}
                            />

                            <br />
                            <PaginationProvider
                                pagination={paginationFactory({
                                    custom: true,
                                    totalSize: this.state.allGeometricElementType ?
                                        this.state.allGeometricElementType.result.totalItems : 0,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    }
                                })}
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) =>
                                        (
                                            <div>
                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    columns={columns}
                                                    rowClasses="text-left"
                                                    data={
                                                        this.state.allGeometricElementType ?
                                                            this.state.allGeometricElementType.result.items : []
                                                    }
                                                    noDataIndication={this.state.allGeometricElementType ?
                                                        this.props.intl.formatMessage({ id: "project.elements.types.noElements" }) :
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only"></span>
                                                        </div>
                                                    }
                                                    defaultSorted={[{ dataField: 'id', order: 'asc' }]}
                                                    expandRow={expandRow}
                                                    filter={filterFactory()}
                                                    filterPosition={"top"}
                                                    striped
                                                    condensed
                                                    remote
                                                    onTableChange={(type, { page, sizePerPage, sortField, sortOrder, filters }) => {
                                                        handleTableChange(page, sizePerPage, sortField, sortOrder, filters);
                                                        this.setState({
                                                            filters: filters,
                                                            currentPage: page,
                                                            currentSize: sizePerPage,
                                                            currentSortField: sortField,
                                                            currentSortOrder: sortOrder,
                                                            expandedRows: []
                                                        });

                                                    }}
                                                    {...paginationTableProps}
                                                />
                                                <div className="text-right">
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                    />
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                            </PaginationProvider>
                        </div>
                        <br />
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;

        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }

}


export default withRouter(connect(mapStateToProps)(injectIntl(AllGeometricElementType)));