import React from "react";
import * as appSelectors from '../../app/selectors'
import * as actions from '../actions';
import * as selectors from '../selectors';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import jQuery from 'jquery';
import { getInternationalization } from '../../app/components/InternationalizationRender';
import PropTypes from 'prop-types';

const mapStateToProps = function (state) {
    return ({
        locale: appSelectors.getLanguage(state),
        allCodes: selectors.getAllCodes(state),
        listAllGeometricLayerGroup: selectors.getListAllGeometricLayerGroup(state),
    });
}

const Schema = Yup.object().shape({
    internalName: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layer.internalName" />,
        }} />),
    geometricLayerGroup: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layer.geometricLayerGroup" />,
        }} />),
    serverUrl: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layer.serverUrl" />,
        }} />),
    type: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layer.type" />,
        }} />),
    geometricLayerGl: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.users.locale.galician" />,
        }} />),
    geometricLayerEs: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.users.locale.spanish" />,
        }} />),
    geometricLayerEn: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.users.locale.english" />,
        }} />),
    order: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layer.order" />,
        }} />)
});

class AddModifyGeometricLayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null
        };
    }

    geometricLayerTypeSelectorRender = () => {
        if (this.props.allCodes) {
            let geometricLayerTypeList = this.props.allCodes.filter(code => code.codeGroup.groupCode
                .indexOf('GEOMETRIC_LAYER_TYPE_GROUP_CODE') !== -1);
            return (
                geometricLayerTypeList.map(geometricLayerType =>
                    <option value={geometricLayerType.code} key={geometricLayerType.code}>
                        {getInternationalization(this.props.locale, geometricLayerType.code, geometricLayerTypeList)}
                    </option>
                )
            );
        } else {
            return (null)
        }
    }

    geometricLayerGroupSelectorRender = () => {
        if (this.props.listAllGeometricLayerGroup && this.props.allCodes) {
            return (
                this.props.listAllGeometricLayerGroup.map(geometricLayerGroup =>
                    <option value={geometricLayerGroup.code.code} key={geometricLayerGroup.code.code}>
                        {getInternationalization(this.props.locale, geometricLayerGroup.code.code, this.props.allCodes)}
                    </option>
                )
            )
        } else {
            return (null)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.geometricLayerToModify !== this.props.geometricLayerToModify) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        if (this.state.formikErrors || this.props.backendErrors) {
            let container = jQuery('.modal-body');
            if (this.props.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }
    }

    render() {

        let initialValues = {};
        if (this.props.geometricLayerToModify) {
            initialValues = {
                internalName: this.props.geometricLayerToModify.internalName,
                order: this.props.geometricLayerToModify.order,
                geometricLayerGroup: this.props.geometricLayerToModify.geometricLayerGroup.code.code,
                type: this.props.geometricLayerToModify.type.code,
                internalComments: this.props.geometricLayerToModify.internalComments,
                serverUrl: this.props.geometricLayerToModify.serverUrl,
                geometricLayerCode: this.props.geometricLayerToModify.code.code,
                geometricLayerGl: getInternationalization("gl", this.props.geometricLayerToModify.code.code, this.props.allCodes),
                geometricLayerEs: getInternationalization("es", this.props.geometricLayerToModify.code.code, this.props.allCodes),
                geometricLayerEn: getInternationalization("en", this.props.geometricLayerToModify.code.code, this.props.allCodes),
                initiallyVisible: this.props.geometricLayerToModify.initiallyVisible
            }
        } else {
            initialValues = {
                internalName: "",
                order: "",
                geometricLayerGroup: "",
                type: "",
                internalComments: "",
                serverUrl: "",
                geometricLayerEn: "",
                geometricLayerEs: "",
                geometricLayerGl: "",
                initiallyVisible: false
            }
        }

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.props.setBackendErrors(null);
                    let geometricLayerType = this.props.allCodes.filter(code => code.code
                        .indexOf(values.type) !== -1)[0];
                    let geometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(geometricLayerGroup =>
                        geometricLayerGroup.code.code.indexOf(values.geometricLayerGroup) !== -1)[0];
                    let geometricLayerForInsert = {
                        "code": {
                            "id": -1,
                            "code": this.props.geometricLayerToModify ?
                                values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                            "codeGroup": {
                                "id": -1,
                                "codeGroup": "GEOMETRIC_LAYER_GROUP_CODE"
                            },
                            "listInternationalizationDto": [{
                                "codeDto": {
                                    "id": -1,
                                    "code": this.props.geometricLayerToModify ?
                                        values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": this.props.geometricLayerToModify ?
                                            values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    }
                                },
                                "language": "gl",
                                "description": values.geometricLayerGl
                            }, {
                                "codeDto": {
                                    "id": -1,
                                    "code": this.props.geometricLayerToModify ?
                                        values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": this.props.geometricLayerToModify ?
                                            values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    }
                                },
                                "language": "es",
                                "description": values.geometricLayerEs
                            }, {
                                "codeDto": {
                                    "id": -1,
                                    "code": this.props.geometricLayerToModify ?
                                        values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    "codeGroup": {
                                        "id": -1,
                                        "groupCode": this.props.geometricLayerToModify ?
                                            values.geometricLayerCode : Math.random().toString(36).substring(2).toUpperCase() + '_LAYER',
                                    }
                                },
                                "language": "en",
                                "description": values.geometricLayerEn
                            }]
                        },
                        "internalName": values.internalName,
                        "type": geometricLayerType,
                        "geometricLayerGroup": geometricLayerGroup,
                        "order": values.order,
                        "serverUrl": values.serverUrl,
                        "internalComments": values.internalComments,
                        "initiallyVisible": values.initiallyVisible
                    }
                    if (this.props.geometricLayerToModify) {
                        this.props.dispatch(actions.modifyGeometricLayer(this.props.geometricLayerToModify.id, geometricLayerForInsert, () => {
                            this.props.dispatch(actions.findAllCodes(
                                () => {
                                    this.props.handleSubmit()
                                    resetForm();
                                }
                            ));
                        },
                            errors => {
                                this.props.setBackendErrors(errors);
                            }));
                    } else {
                        this.props.dispatch(actions.addGeometricLayer(geometricLayerForInsert, () => {
                            this.props.dispatch(actions.findAllCodes(
                                () => {
                                    this.props.handleSubmit();
                                    resetForm();
                                }
                            ));
                        },
                            errors => {
                                this.props.setBackendErrors(errors);
                            }));
                    }
                    setSubmitting(false);
                }}
            >
                {({ errors, setFieldError, resetForm }) =>
                    <Modal show={this.props.modalShow} onHide={this.props.hideModalWindow} scrollable={true} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.geometricLayerToModify ?
                                    <FormattedMessage id="project.elements.geometricLayer.layer.modify" />
                                    :
                                    <FormattedMessage id="project.elements.geometricLayer.layer.add" />
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="addGeometricLayerForm">
                                {errors ? this.setState({ formikErrors: errors }) : ""}
                                <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                <div className="form-group">
                                    <label htmlFor="internalName" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.internalName" />
                                    </label>
                                    <Field id="internalName" name="internalName" className="form-control" />
                                </div>
                                <ErrorMessage name="internalName" render={(msg) =>
                                    <div id="internalNameError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.internalName === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("internalName", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <Field id="geometricLayerCode" name="geometricLayerCode"
                                    disabled={true} hidden={true} />

                                <div className="card" style={{ marginBottom: "1rem" }}>

                                    <h5 className="card-header">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.name" />
                                    </h5>

                                    <div className="card-body">
                                        <div className="form-group">
                                            <div className="input-group mb-3 no-gutters">
                                                <div className="input-group-prepend col-2">
                                                    <span className="input-group-text col-12 required">
                                                        <FormattedMessage id="project.users.locale.galician" />
                                                    </span>
                                                </div>
                                                <Field id="geometricLayerGl" name="geometricLayerGl" as="input" className="form-control" />
                                            </div>
                                            <ErrorMessage name="geometricLayerGl" render={(msg) =>
                                                <div id="geometricLayerGlError" className="alert alert-danger alert-dismissible fade show"
                                                    role="alert" hidden={typeof errors.geometricLayerGl === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("geometricLayerGl", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                            <div className="input-group mb-3 no-gutters">
                                                <div className="input-group-prepend col-2">
                                                    <span className="input-group-text col-12 required">
                                                        <FormattedMessage id="project.users.locale.spanish" />
                                                    </span>
                                                </div>
                                                <Field id="geometricLayerEs" name="geometricLayerEs" as="input" className="form-control" />
                                            </div>
                                            <ErrorMessage name="geometricLayerEs" render={(msg) =>
                                                <div id="geometricLayerEsError" className="alert alert-danger alert-dismissible fade show"
                                                    role="alert" hidden={typeof errors.geometricLayerEs === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("geometricLayerEs", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                            <div className="input-group mb-3 no-gutters">
                                                <div className="input-group-prepend col-2">
                                                    <span className="input-group-text col-12 required">
                                                        <FormattedMessage id="project.users.locale.english" />
                                                    </span>
                                                </div>
                                                <Field id="geometricLayerEn" name="geometricLayerEn" as="input" className="form-control" />
                                            </div>
                                            <ErrorMessage name="geometricLayerEn" render={(msg) =>
                                                <div id="geometricLayerEnError" className="alert alert-danger alert-dismissible fade show"
                                                    role="alert" hidden={typeof errors.geometricLayerEn === "undefined"}
                                                >
                                                    <button type="button" className="close" aria-label="Close"
                                                        onClick={() => setFieldError("geometricLayerEn", undefined)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    {msg}
                                                </div>
                                            }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="order" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.order" />
                                    </label>
                                    <Field type="number" id="order" name="order" className="form-control" />
                                </div>
                                <ErrorMessage name="order" render={(msg) =>
                                    <div id="orderError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.order === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("order", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <Field type="checkbox" id="initiallyVisible" name="initiallyVisible" />
                                        </div>
                                    </div>
                                    <label htmlFor="initiallyVisible" className="btn input-group-text">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.initiallyVisible" />
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="serverUrl" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.serverUrl" />
                                    </label>
                                    <Field id="serverUrl" name="serverUrl" className="form-control" />
                                </div>
                                <ErrorMessage name="serverUrl" render={(msg) =>
                                    <div id="serverUrlError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.serverUrl === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("serverUrl", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label htmlFor="geometricLayerGroup" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.geometricLayerGroup" />
                                    </label>
                                    <Field as="select" id="geometricLayerGroup" name="geometricLayerGroup" className="form-control">
                                        <FormattedMessage id="project.common.selectOneOption">
                                            {(msg) => <option value="">{msg}</option>}
                                        </FormattedMessage>
                                        {this.geometricLayerGroupSelectorRender()}
                                    </Field>
                                </div>
                                <ErrorMessage name="geometricLayerGroup" render={(msg) =>
                                    <div id="geometricLayerGroupError" className="alert alert-danger alert-dismissible fade show"
                                        role="alert" hidden={typeof errors.geometricLayerGroup === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("geometricLayerGroup", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label htmlFor="type" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.type" />
                                    </label>
                                    <Field as="select" id="type" name="type" className="form-control">
                                        <FormattedMessage id="project.common.selectOneOption">
                                            {(msg) => <option value="">{msg}</option>}
                                        </FormattedMessage>
                                        {this.geometricLayerTypeSelectorRender()}
                                    </Field>
                                </div>
                                <ErrorMessage name="type" render={(msg) =>
                                    <div id="typeError" className="alert alert-danger alert-dismissible fade show"
                                        role="alert" hidden={typeof errors.type === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("type", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label htmlFor="internalComments" className="font-weight-bold">
                                        <FormattedMessage id="project.elements.geometricLayer.layer.internalComments" />
                                    </label>
                                    <Field as="textarea" id="internalComments" name="internalComments" className="form-control" />
                                </div>
                                <ErrorMessage name="internalComments" render={(msg) =>
                                    <div id="internalCommentsError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.internalComments === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("internalComments", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button id="createGeometricLayerSubmit" type="submit" className="btn btn-primary"
                                form="addGeometricLayerForm"
                            >
                                <FormattedMessage id="project.app.Body.accept" />
                            </button>
                            <button id="createGeometricLayerGroupCancel" type="button" className="btn btn-danger"
                                onClick={() => {
                                    this.props.hideModalWindow();
                                    resetForm();
                                }}
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik >
        );
    }
}

AddModifyGeometricLayer.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    geometricLayerToModify: PropTypes.object,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired
}


export default connect(mapStateToProps)(AddModifyGeometricLayer);