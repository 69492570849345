import { Control } from 'ol/control';
import { getArea, getLength } from 'ol/sphere';
import * as actions from '../actions';
import './openlayersControls.css';
import jQuery from 'jquery';
import { Draw, Modify, Snap } from 'ol/interaction';
import { noModifierKeys } from "ol/events/condition";
import DragZoom from 'ol/interaction/DragZoom';
import { unByKey } from 'ol/Observable';
import { boundingExtent, buffer as createBuffer } from 'ol/extent';
import { transform } from 'ol/proj';
import autocomplete from 'autocompleter';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js'

const hideCenterControl = () => {
  jQuery('#progressiveCenterDiv').removeClass('show');
  jQuery('#searchCenterDiv').removeClass('show');
}

export const PopupController = (function (Control) {
  var options;
  function PopupController(opt_options) {
    options = opt_options || {};

    var button = document.createElement('button');

    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="currentcolor">' +
      '<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 ' +
      '1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 ' +
      '1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-' +
      '.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/></svg>';

    button.id = "poupcontrollerbutton";
    button.title = options.formatMessage({ id: "project.elements.map.popupTip" });

    var element = document.createElement('div');
    element.id = 'popupcontrollerdiv';
    element.className = 'ol-popup-control ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.handlePopupController.bind(this), false);

  }

  if (Control) PopupController.__proto__ = Control;
  PopupController.prototype = Object.create(Control && Control.prototype);
  PopupController.prototype.constructor = PopupController;

  PopupController.prototype.handlePopupController = function handlePopupController() {

    options.disableAllInteractions(this.getMap());
    options.hideAllOverlays(this.getMap());
    options.closeAddGeometricElementForm(this.getMap());
    options.source.forEachFeature(feature => {
      options.source.removeFeature(feature);
    });
    jQuery('#addGeometricElementSelector').prop("disabled", true);
    hideCenterControl();

    if (jQuery('#popupcontrollerdiv').hasClass('ol-control-active')) {
      jQuery('#popupcontrollerdiv').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');
    } else {
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#popupcontrollerdiv').addClass('ol-control-active');
      jQuery('#map').css('cursor', 'help');
      jQuery('.layer-switcher').css('cursor', 'default');
      jQuery('.ol-zoomslider').css('cursor', 'default');
      jQuery('.ol-popup').css('cursor', 'default');
      this.getMap().on('singleclick', (event) => options.showPopup(event, options.formatMessage, options.findGeometricElementType, options.history));
    }
  }

  return PopupController;
}(Control));

export const AddGeometricElementController = (function (Control) {
  var options = null;
  function AddGeometricElementController(opt_options) {
    options = opt_options || {};

    var rootElement = document.createElement('div');
    rootElement.className = 'ol-add-geometric-element-control ol-unselectable ol-control';
    rootElement.id = 'addGeometricElementRootDiv';

    var selector = document.createElement('select');
    selector.id = 'addGeometricElementSelector';
    selector.disabled = true;
    var selectorOptions = "";
    selector.className = 'ol-unselectable ol-control';
    if (options.allGeometricElementType.length === 0) {
      selectorOptions = '<option value="">' + options.formatMessage({ id: "project.elements.types.noElements" }) + '</option>';
    } else {
      options.allGeometricElementType.forEach(geometricElementType => {
        if (geometricElementType.geometryType === "ANY_GEOMETRY") {
          selectorOptions += '<option value="' + geometricElementType.id + '-Point">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.Point" }) + '</option>'
          selectorOptions += '<option value="' + geometricElementType.id + '-LineString">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.LineString" }) + '</option>'
          selectorOptions += '<option value="' + geometricElementType.id + '-Polygon">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.Polygon" }) + '</option>'
        }
        if (geometricElementType.geometryType === "POINT_POLYGON") {
          selectorOptions += '<option value="' + geometricElementType.id + '-Point">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.Point" }) + '</option>'
          selectorOptions += '<option value="' + geometricElementType.id + '-Polygon">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.Polygon" }) + '</option>'
        }
        if (geometricElementType.geometryType === "POINT_LINE") {
          selectorOptions += '<option value="' + geometricElementType.id + '-Point">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.Point" }) + '</option>'
          selectorOptions += '<option value="' + geometricElementType.id + '-LineString">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.LineString" }) + '</option>'
        }
        if (geometricElementType.geometryType === "LINE_POLYGON") {
          selectorOptions += '<option value="' + geometricElementType.id + '-LineString">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.LineString" }) + '</option>'
          selectorOptions += '<option value="' + geometricElementType.id + '-Polygon">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.Polygon" }) + '</option>'
        }
        if (geometricElementType.geometryType === "POINT") {
          selectorOptions += '<option value="' + geometricElementType.id + '-Point">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.Point" }) + '</option>'
        }
        if (geometricElementType.geometryType === "POLYGON") {
          selectorOptions += '<option value="' + geometricElementType.id + '-Polygon">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.Polygon" }) + '</option>'
        }
        if (geometricElementType.geometryType === "LINE") {
          selectorOptions += '<option value="' + geometricElementType.id + '-LineString">' +
            options.getInternationalization(options.locale, geometricElementType.code, options.allCodes) +
            ' - ' + options.formatMessage({ id: "project.elements.geom.LineString" }) + '</option>'
        }
      }
      );
    }

    selector.innerHTML = selectorOptions;

    var element = document.createElement('div');
    element.className = 'ol-unselectable ol-control';
    element.id = "addGeometricElementDiv";

    var button = document.createElement('button');

    button.innerHTML = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" fill="currentColor">' +
      '<path d="M20 15h4.071v2h-4.071v4.071h-2v-4.071h-4.071v-2h4.071v-4.071h2v4.071zm-8 6h-12v-2h12v2zm0-4.024h-12v-2h12v2zm0-3.976h-12v-2h12v2zm12-' +
      '4h-24v-2h24v2zm0-4h-24v-2h24v2z"/></svg>';

    button.id = "addGeometricElementButton";
    button.title = options.formatMessage({ id: "project.elements.map.addElementTip" });

    if (options.allGeometricElementType.length === 0) {
      button.disabled = true;
    }

    element.appendChild(button);

    rootElement.appendChild(element);
    rootElement.appendChild(selector);

    Control.call(this, {
      element: rootElement,
      target: options.target
    });

    button.addEventListener('click', this.handleAddGeometricElementController.bind(this), false);
    selector.addEventListener('change', this.handleAddGeometricElementSelector.bind(this), false);
  }

  if (Control) AddGeometricElementController.__proto__ = Control;
  AddGeometricElementController.prototype = Object.create(Control && Control.prototype);
  AddGeometricElementController.prototype.constructor = AddGeometricElementController;

  AddGeometricElementController.prototype.handleAddGeometricElementController = function handleAddGeometricElementController(e) {
    options.disableAllInteractions(this.getMap());
    options.closeAddGeometricElementForm(this.getMap());
    options.hideAllOverlays(this.getMap());
    options.source.forEachFeature(feature => {
      options.source.removeFeature(feature);
    });
    jQuery('#addGeometricElementSelector').prop("disabled", true);
    hideCenterControl();

    if (!jQuery('#addGeometricElementDiv').hasClass('ol-control-active')) {
      jQuery('#map').css('cursor', 'default');
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#addGeometricElementDiv').addClass('ol-control-active');
      jQuery('#addGeometricElementSelector').prop("disabled", false);
      jQuery('.layer-switcher').removeClass('shown');
      jQuery('.layer-switcher button').text("");
      let drawControl = new Draw({
        source: options.source,
        type: jQuery('#addGeometricElementSelector').val().split("-")[1],
        stopClick: true
      });

      let modifyInteraction = new Modify({
        source: options.source
      });
      let snapInteraction = new Snap({
        source: options.source
      });
      options.setInteractions(drawControl, null, modifyInteraction, snapInteraction);
      this.getMap().addInteraction(drawControl);
      this.getMap().addInteraction(modifyInteraction);
      this.getMap().addInteraction(snapInteraction);
      let map = this.getMap();
      modifyInteraction.setActive(false);
      options.dispatch(actions.findGeometricElementTypeById(jQuery("#addGeometricElementSelector").val().split("-")[0]));

      drawControl.on('drawend', function (event) {
        var feature = event.feature;
        var featureToTransform = feature.clone();
        var geometry = featureToTransform.getGeometry();
        geometry.transform('EPSG:3857', 'EPSG:4326');
        options.handleGeom({
          "type": `${geometry.getType()}`,
          "coordinates": geometry.getCoordinates()
        });
        options.openAddGeometricElementForm(map, geometry.transform('EPSG:4326', 'EPSG:3857'));
        drawControl.setActive(false);
        modifyInteraction.setActive(true);
      });

      modifyInteraction.on('modifyend', function (event) {
        var features = event.features;
        var featureToTransform = features.array_[0].clone()
        var geometry = featureToTransform.getGeometry();
        geometry.transform('EPSG:3857', 'EPSG:4326')
        options.handleGeom({
          "type": `${geometry.getType()}`,
          "coordinates": geometry.getCoordinates()
        });
      });

    } else {
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');
    }

  }

  AddGeometricElementController.prototype.handleAddGeometricElementSelector = function handleAddGeometricElementSelector(e) {
    options.source.forEachFeature(feature => {
      options.source.removeFeature(feature);
    });
    options.disableAllInteractions(this.getMap());
    options.closeAddGeometricElementForm(this.getMap());

    if (jQuery('#addGeometricElementDiv').hasClass('ol-control-active')) {
      let drawControl = new Draw({
        source: options.source,
        type: jQuery('#addGeometricElementSelector').val().split("-")[1],
        stopClick: true
      });

      let modifyInteraction = new Modify({
        source: options.source
      });

      let snapInteraction = new Snap({
        source: options.source
      })

      options.setInteractions(drawControl, null, modifyInteraction, snapInteraction);

      this.getMap().addInteraction(drawControl);
      this.getMap().addInteraction(modifyInteraction);
      this.getMap().addInteraction(snapInteraction);
      let map = this.getMap();
      modifyInteraction.setActive(false);
      options.dispatch(actions.findGeometricElementTypeById(jQuery("#addGeometricElementSelector").val().split("-")[0]));

      drawControl.on('drawend', function (event) {
        var feature = event.feature;
        var featureToTransform = feature.clone()
        var geometry = featureToTransform.getGeometry();
        geometry.transform('EPSG:3857', 'EPSG:4326')
        options.handleGeom({
          "type": `${geometry.getType()}`,
          "coordinates": geometry.getCoordinates()
        });
        options.openAddGeometricElementForm(map, geometry.transform('EPSG:4326', 'EPSG:3857'));
        geometry.transform('EPSG:4326', 'EPSG:3857')
        drawControl.setActive(false);
        modifyInteraction.setActive(true);
      });

      modifyInteraction.on('modifyend', function (event) {
        var features = event.features;
        var featureToTransform = features.array_[0].clone()
        var geometry = featureToTransform.getGeometry();
        geometry.transform('EPSG:3857', 'EPSG:4326')
        options.handleGeom({
          "type": `${geometry.getType()}`,
          "coordinates": geometry.getCoordinates()
        });
      });
    }

  }

  return AddGeometricElementController;

}(Control));

export const AreaMeasureController = (function (Control) {
  var options = null;

  function AreaMeasureController(opt_options) {
    options = opt_options || {};

    var button = document.createElement('button');

    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">' +
      '<path d="M22 15v2h-1v-14h-14v-1h2v-2h-6v3h-3v2h3v16h16v3h2v-3h3v-6h-2zm-3 2h-1v-2h-2v2h-1v-2h-2v2h-1v-2h-2v2h-1v-2h-2v-1h2v-2h' +
      '-2v-1h2v-2h-2v-1h2v-2h-2v-1h12v12z"/></svg>';
    button.title = options.formatMessage({ id: "project.elements.map.measureAreaTip" });

    var element = document.createElement('div');
    element.className = 'ol-measure-area-control ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.handleAreaMeasureController.bind(this), false);

  }

  if (Control) AreaMeasureController.__proto__ = Control;
  AreaMeasureController.prototype = Object.create(Control && Control.prototype);
  AreaMeasureController.prototype.constructor = AreaMeasureController;

  AreaMeasureController.prototype.handleAreaMeasureController = function handleAreaMeasureController() {

    options.disableAllInteractions(this.getMap());
    options.hideAllOverlays(this.getMap());
    options.closeAddGeometricElementForm(this.getMap());
    options.source.forEachFeature(feature => {
      options.source.removeFeature(feature);
    });
    hideCenterControl();
    jQuery('#addGeometricElementSelector').prop("disabled", true);

    if (jQuery('.ol-measure-area-control').hasClass('ol-control-active')) {
      jQuery('.ol-measure-area-control').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');
    } else {
      var areaMeasureDraw = new Draw({
        source: options.source,
        type: 'Polygon',
        stopClick: true
      });
      let modifyInteraction = new Modify({
        source: options.source
      });
      let snapInteraction = new Snap({
        source: options.source
      });
      options.setInteractions(areaMeasureDraw, null, modifyInteraction, snapInteraction);
      this.getMap().addInteraction(areaMeasureDraw);
      this.getMap().addInteraction(modifyInteraction);
      this.getMap().addInteraction(snapInteraction);
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'crosshair');
      jQuery('.layer-switcher').css('cursor', 'default');
      jQuery('.ol-zoomslider').css('cursor', 'default');
      jQuery('.ol-measure-area-control').addClass('ol-control-active');

      let formatArea = function (polygon) {
        var area = getArea(polygon);
        var output;
        if (area > 1000000) {
          output = (Math.round(area / 1000000 * 100) / 100) + ' km<sup>2</sup>';
        } else {
          if (area > 10000) {
            output = (Math.round(area / 10000 * 100) / 100) + ' ha'
          } else {
            output = (Math.round(area * 100) / 100) + ' m<sup>2</sup>'
          }
        }
        return output;
      }

      var feature;
      var listener;

      areaMeasureDraw.on('drawstart', function (event) {
        modifyInteraction.setActive(false);
        snapInteraction.setActive(false);
        options.source.forEachFeature(feature => {
          options.source.removeFeature(feature);
        });
        feature = event.feature;
        listener = feature.getGeometry().on('change', function (event) {
          var geom = event.target;
          options.overlayElement.innerHTML = formatArea(geom);
          options.overlay.setPosition(geom.getInteriorPoint().getCoordinates())
        });
      })

      areaMeasureDraw.on('drawend', function (event) {
        options.overlay.setOffset([0, -7]);
        unByKey(listener);
        modifyInteraction.setActive(true);
        snapInteraction.setActive(true);
      });

      modifyInteraction.on('modifystart', function (event) {
        listener = feature.getGeometry().on('change', function (event) {
          var geom = event.target;
          options.overlayElement.innerHTML = formatArea(geom);
          options.overlay.setPosition(geom.getInteriorPoint().getCoordinates())
        });
      });

      modifyInteraction.on('modifyend', function (event) {
        options.overlay.setOffset([0, -7]);
        unByKey(listener);
      });
    }
  }

  return AreaMeasureController;
}(Control));

export const LengthMeasureController = (function (Control) {
  var options = null;

  function LengthMeasureController(opt_options) {
    options = opt_options || {};

    var button = document.createElement('button');

    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">' +
      '<path d="M18.342 0l-2.469 2.47 2.121 2.121-.707.707-2.121-2.121-1.414 1.414 1.414 1.414-.707.707-1.414-1.414-1.414 1.414 1.414' +
      ' 1.414-.707.707-1.414-1.414-1.414 1.414 2.121 2.122-.707.707-2.121-2.121-1.414 1.414 1.414 1.414-.708.707-1.414-1.414-1.414 1.414' +
      ' 1.414 1.414-.708.709-1.414-1.414-1.414 1.413 2.121 2.121-.706.706-2.122-2.121-2.438 2.439 5.656 5.657 18.344-18.343z"/></svg>';

    button.title = options.formatMessage({ id: "project.elements.map.measureLengthTip" });

    var element = document.createElement('div');
    element.className = 'ol-measure-length-control ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.handleLengthMeasureController.bind(this), false);

  }

  if (Control) LengthMeasureController.__proto__ = Control;
  LengthMeasureController.prototype = Object.create(Control && Control.prototype);
  LengthMeasureController.prototype.constructor = LengthMeasureController;

  LengthMeasureController.prototype.handleLengthMeasureController = function handleLengthMeasureController() {

    options.disableAllInteractions(this.getMap());
    options.hideAllOverlays(this.getMap());
    options.closeAddGeometricElementForm(this.getMap());
    options.source.forEachFeature(feature => {
      options.source.removeFeature(feature);
    });
    hideCenterControl();
    jQuery('#addGeometricElementSelector').prop("disabled", true);

    if (jQuery('.ol-measure-length-control').hasClass('ol-control-active')) {
      jQuery('.ol-measure-length-control').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');
    } else {
      var lengthMeasureDraw = new Draw({
        source: options.source,
        type: 'LineString',
        stopClick: true
      });
      let modifyInteraction = new Modify({
        source: options.source
      });
      let snapInteraction = new Snap({
        source: options.source
      });
      options.setInteractions(lengthMeasureDraw, null, modifyInteraction, snapInteraction);
      this.getMap().addInteraction(lengthMeasureDraw);
      this.getMap().addInteraction(modifyInteraction);
      this.getMap().addInteraction(snapInteraction);
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'crosshair');
      jQuery('.layer-switcher').css('cursor', 'default');
      jQuery('.ol-zoomslider').css('cursor', 'default');
      jQuery('.ol-measure-length-control').addClass('ol-control-active');

      let formatLength = function (line) {
        var length = getLength(line);
        var output;
        if (length > 1000) {
          output = (Math.round(length / 1000 * 100) / 100) + ' km';
        } else {
          output = (Math.round(length * 100) / 100) + ' m'
        }

        return output;
      }

      var feature;
      var listener;

      lengthMeasureDraw.on('drawstart', function (event) {
        modifyInteraction.setActive(false);
        snapInteraction.setActive(false);
        options.source.forEachFeature(feature => {
          options.source.removeFeature(feature);
        });
        feature = event.feature
        listener = feature.getGeometry().on('change', function (event) {
          var geom = event.target;
          options.overlayElement.innerHTML = formatLength(geom);
          options.overlay.setPosition(geom.getLastCoordinate());
        })
      })

      lengthMeasureDraw.on('drawend', function (event) {
        options.overlay.setOffset([0, -7]);
        unByKey(listener);
        modifyInteraction.setActive(true);
        snapInteraction.setActive(true);
      })

      modifyInteraction.on('modifystart', function (event) {
        listener = feature.getGeometry().on('change', function (event) {
          var geom = event.target;
          options.overlayElement.innerHTML = formatLength(geom);
          options.overlay.setPosition(geom.getLastCoordinate());
        })
        event.features = null;
      })

      modifyInteraction.on('modifyend', function (event) {
        options.overlay.setOffset([0, -7]);
        event.features = null;
        unByKey(listener);
      })
    }
  }

  return LengthMeasureController;
}(Control));

export const ZoomToExtentController = (function (Control) {
  var options = null;

  function ZoomToExtentController(opt_options) {
    options = opt_options || {};

    var button = document.createElement('button');

    button.innerHTML = '<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">' +
      '<path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514' +
      ' 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7zm-3-8c.552 0 1 .448' +
      ' 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1' +
      ' 1-1-.448-1-1 .448-1 1-1z"/></svg>';
    button.title = options.formatMessage({ id: "project.elements.map.zoomToExtentTip" });

    var element = document.createElement('div');
    element.className = 'ol-zoom-extent-control ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.handleZoomToExtentController.bind(this), false);

  }

  if (Control) ZoomToExtentController.__proto__ = Control;
  ZoomToExtentController.prototype = Object.create(Control && Control.prototype);
  ZoomToExtentController.prototype.constructor = ZoomToExtentController;

  ZoomToExtentController.prototype.handleZoomToExtentController = function handleZoomToExtentController() {

    options.disableAllInteractions(this.getMap());
    options.hideAllOverlays(this.getMap());
    options.closeAddGeometricElementForm(this.getMap());
    options.source.forEachFeature(feature => {
      options.source.removeFeature(feature);
    });
    hideCenterControl();
    jQuery('#addGeometricElementSelector').prop("disabled", true);

    if (jQuery('.ol-zoom-extent-control').hasClass('ol-control-active')) {
      jQuery('.ol-zoom-extent-control').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');
    } else {
      var dragZoom = new DragZoom({ condition: noModifierKeys });
      options.setInteractions(null, dragZoom, null, null);
      this.getMap().addInteraction(dragZoom);
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'crosshair');
      jQuery('.layer-switcher').css('cursor', 'default');
      jQuery('.ol-zoomslider').css('cursor', 'default');
      jQuery('.ol-zoom-extent-control').addClass('ol-control-active');
    }
  }

  return ZoomToExtentController;
}(Control));

//Center from population entity

export const EntityPopulationCenterControl = (function (Control) {
  var options = null;
  var searchType = "PROGRESSIVE";
  function EntityPopulationCenterControl(opt_options) {
    options = opt_options || {};

    var rootElement = document.createElement('div');
    rootElement.className = 'ol-center-entity-population ol-unselectable ol-control';
    rootElement.id = 'centerEntityPopulationRootDiv';

    var element = document.createElement('div');
    element.className = 'btn-group dropright';
    element.id = "centerEntityPopulationDiv";

    var button = document.createElement('button');

    button.innerHTML = '<i class="fas fa-search-location"></i>';

    button.id = "centerEntityPopulationButton";
    button.title = options.formatMessage({ id: 'project.elements.map.searchEntityPopulation' });
    jQuery(button).attr('data-toggle', 'toggle');
    jQuery(button).attr('data-target', '#search');

    var expandButton = document.createElement('button');
    expandButton.id = "centerEntityPopulationExpandButton";
    expandButton.className = "dropdown-toggle dropdown-toggle-split";
    expandButton.title = options.formatMessage({ id: 'project.elements.map.searchType' });
    jQuery(expandButton).attr('data-toggle', 'dropdown');
    jQuery(expandButton).attr('aria-haspopup', 'true');
    jQuery(expandButton).attr('aria-expanded', 'false');

    var dropdownMenu = document.createElement('div');
    dropdownMenu.className = "dropdown-menu";

    var firstDropdownItem = document.createElement('a');
    var secondDropdownItem = document.createElement('a');

    firstDropdownItem.id = "progressiveCenter";
    secondDropdownItem.id = "searchCenter";

    firstDropdownItem.className = "btn dropdown-item";
    secondDropdownItem.className = "btn dropdown-item";

    firstDropdownItem.innerHTML = options.formatMessage({ id: 'project.elements.map.progressiveCenter' });
    secondDropdownItem.innerHTML = options.formatMessage({ id: 'project.elements.map.searchCenter' });

    //Progressive center elements
    let progressiveCenterDiv = document.createElement('div');
    progressiveCenterDiv.id = 'progressiveCenterDiv';

    var selector1 = document.createElement('select');
    var selector2 = document.createElement('select');
    var selector3 = document.createElement('select');
    var selector4 = document.createElement('select');

    var centerProgressiveButton = document.createElement('button');
    centerProgressiveButton.innerHTML = '<i class="fas fa-map-marked"></i>';
    centerProgressiveButton.id = "center";
    centerProgressiveButton.title = options.formatMessage({ id: 'project.common.center' });
    let selector1Html = '<option value="">' + options.formatMessage({ id: "project.elements.map.selectProvince" }) + '</option>';
    options.listProvinces.forEach(prov => {
      selector1Html += '<option value="' + prov.c + '">' + prov.n + '</option>';
    });
    selector1.innerHTML = selector1Html;
    selector2.innerHTML = '<option value="">' + options.formatMessage({ id: "project.elements.map.selectCouncil" }) + '</option>';
    selector3.innerHTML = '<option value="">' + options.formatMessage({ id: "project.elements.map.selectParish" }) + '</option>';
    selector4.innerHTML = '<option value="">' + options.formatMessage({ id: "project.elements.map.selectEntityPopulation" }) + '</option>';
    selector1.id = 'centerProvince';
    selector2.id = 'centerCouncil';
    selector3.id = 'centerParish';
    selector4.id = 'centerEntityPopulation';
    selector1.title = options.formatMessage({ id: "project.elements.map.provinces" });
    selector2.title = options.formatMessage({ id: "project.elements.map.councils" });
    selector3.title = options.formatMessage({ id: "project.elements.map.parishes" });
    selector4.title = options.formatMessage({ id: "project.elements.map.entityPopulations" });
    selector2.disabled = true;
    selector3.disabled = true;
    selector4.disabled = true;
    progressiveCenterDiv.appendChild(selector1);
    progressiveCenterDiv.appendChild(selector2);
    progressiveCenterDiv.appendChild(selector3);
    progressiveCenterDiv.appendChild(selector4);
    progressiveCenterDiv.appendChild(centerProgressiveButton);

    //Search center elements 
    let searchCenterDiv = document.createElement('div');
    searchCenterDiv.id = 'searchCenterDiv';
    var centerSearchButton = document.createElement('button');
    centerSearchButton.innerHTML = '<i class="fas fa-map-marked"></i>';
    centerSearchButton.id = "center";
    centerSearchButton.title = options.formatMessage({ id: 'project.common.center' });

    var input = document.createElement('input');
    input.placeholder = options.formatMessage({ id: 'project.elements.map.searchEntityPopulation' });
    input.title = options.formatMessage({ id: 'project.elements.map.searchEntityPopulation' });
    input.id = 'centerSearchInput';
    searchCenterDiv.appendChild(input);
    searchCenterDiv.appendChild(centerSearchButton);

    dropdownMenu.appendChild(firstDropdownItem);
    dropdownMenu.appendChild(secondDropdownItem);

    element.appendChild(button);
    element.appendChild(expandButton);
    element.appendChild(dropdownMenu);

    rootElement.appendChild(element);
    rootElement.appendChild(progressiveCenterDiv);
    rootElement.appendChild(searchCenterDiv);

    Control.call(this, {
      element: rootElement,
      target: options.target
    });

    button.addEventListener('click', this.handleEntityPopulationCenterControl.bind(this), false);
    firstDropdownItem.addEventListener('click', this.handleFirstDropdownItemControl.bind(this), false);
    secondDropdownItem.addEventListener('click', this.handleSecondDropdownItemControl.bind(this), false);
    selector1.addEventListener('change', this.handleSelector1Control.bind(this), false);
    selector2.addEventListener('change', this.handleSelector2Control.bind(this), false);
    selector3.addEventListener('change', this.handleSelector3Control.bind(this), false);
    input.addEventListener('keyup', this.handleInputSearch.bind(this), false);
    centerProgressiveButton.addEventListener('click', this.handleCenterProgressiveButton.bind(this), false);
    centerSearchButton.addEventListener('click', this.handleCenterSearchButton.bind(this), false);
  }

  if (Control) EntityPopulationCenterControl.__proto__ = Control;
  EntityPopulationCenterControl.prototype = Object.create(Control && Control.prototype);
  EntityPopulationCenterControl.prototype.constructor = EntityPopulationCenterControl;

  let firstStateHandler = () => {
    jQuery('#centerProvince').val('').change();
    jQuery('#centerCouncil').val('').change();
    jQuery('#centerParish').val('').change();
    jQuery('#centerEntityPopulation').val('').change();
    jQuery('#centerCouncil').attr('disabled', true);
    jQuery('#centerParish').attr('disabled', true);
    jQuery('#centerEntityPopulation').attr('disabled', true);
    jQuery('#centerSearchInput').val("").change();
  }

  EntityPopulationCenterControl.prototype.handleEntityPopulationCenterControl = function handleEntityPopulationCenterControl(e) {
    options.disableAllInteractions(this.getMap());
    options.closeAddGeometricElementForm(this.getMap());
    options.hideAllOverlays(this.getMap());
    options.source.forEachFeature(feature => {
      options.source.removeFeature(feature);
    });
    jQuery('#addGeometricElementSelector').prop("disabled", true);

    if (!jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      firstStateHandler();
      jQuery('#map').css('cursor', 'default');
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#centerEntityPopulationDiv').addClass('ol-control-active');
      if (searchType === "PROGRESSIVE") {
        jQuery('#progressiveCenterDiv').css('transition', 'max-width .8s ease-in');
        jQuery('#progressiveCenterDiv').addClass('show');
      }
      if (searchType === "SEARCH") {
        jQuery('#searchCenterDiv').css('transition', 'max-width .8s ease-in');
        jQuery('#searchCenterDiv').addClass('show');
      }
    } else {
      firstStateHandler();
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');
      jQuery('#progressiveCenterDiv').css('transition', 'max-width .8s ease-in');
      jQuery('#progressiveCenterDiv').removeClass('show');
      jQuery('#searchCenterDiv').css('transition', 'max-width .8s ease-in');
      jQuery('#searchCenterDiv').removeClass('show');
    }

  }

  EntityPopulationCenterControl.prototype.handleFirstDropdownItemControl = function handleFirstDropdownItemControl(e) {

    if (!jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      options.disableAllInteractions(this.getMap());
      options.closeAddGeometricElementForm(this.getMap());
      options.hideAllOverlays(this.getMap());
      options.source.forEachFeature(feature => {
        options.source.removeFeature(feature);
      });
      jQuery('#map').css('cursor', 'default');
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#centerEntityPopulationDiv').addClass('ol-control-active');
      searchType = 'PROGRESSIVE';
      jQuery('#progressiveCenterDiv').css('transition', 'max-width .8s ease-in');
      jQuery('#progressiveCenterDiv').addClass('show');
      firstStateHandler();
    } else {
      searchType = 'PROGRESSIVE';
      firstStateHandler();
      jQuery('#searchCenterDiv').css('transition', 'none');
      jQuery('#progressiveCenterDiv').css('transition', 'none');
      jQuery('#searchCenterDiv').removeClass('show');
      jQuery('#progressiveCenterDiv').addClass('show');
    }

  }

  EntityPopulationCenterControl.prototype.handleSecondDropdownItemControl = function handleSecondDropdownItemControl(e) {

    if (!jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      options.disableAllInteractions(this.getMap());
      options.closeAddGeometricElementForm(this.getMap());
      options.hideAllOverlays(this.getMap());
      options.source.forEachFeature(feature => {
        options.source.removeFeature(feature);
      });
      jQuery('#map').css('cursor', 'default');
      jQuery('.ol-control-active').removeClass('ol-control-active');
      jQuery('#centerEntityPopulationDiv').addClass('ol-control-active');
      searchType = 'SEARCH';
      jQuery('#searchCenterDiv').css('transition', 'max-width .8s ease-in');
      jQuery('#searchCenterDiv').addClass('show');
    } else {
      searchType = 'SEARCH';
      jQuery('#progressiveCenterDiv').css('transition', 'none');
      jQuery('#searchCenterDiv').css('transition', 'none');
      jQuery('#progressiveCenterDiv').removeClass('show');
      jQuery('#searchCenterDiv').addClass('show');
    }

  }

  EntityPopulationCenterControl.prototype.handleSelector1Control = function handleSelector1Control(e) {

    if (jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      if (jQuery('#centerProvince').val() !== "") {
        let councils = jQuery('#centerCouncil');
        let parishes = jQuery('#centerParish');
        let entityPopulations = jQuery('#centerEntityPopulation');
        let selector2Html = '<option value="">' + options.formatMessage({ id: "project.elements.map.selectCouncil" }) + '</option>';
        options.listCouncils.forEach(coun => {
          if (coun.c.startsWith(jQuery('#centerProvince').val())) {
            selector2Html += '<option value="' + coun.c + '">' + coun.n + '</option>';
          }
        });
        councils.html(selector2Html);
        councils.attr('disabled', false);
        parishes.val("").change();
        parishes.attr("disabled", true);
        entityPopulations.val("").change();
        entityPopulations.attr("disabled", true);
      } else {
        firstStateHandler();
      }
    }

  }

  EntityPopulationCenterControl.prototype.handleSelector2Control = function handleSelector2Control(e) {

    if (jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      let parishes = jQuery('#centerParish');
      let entityPopulations = jQuery('#centerEntityPopulation');
      if (jQuery('#centerCouncil').val() !== "") {
        let selector3Html = '<option value="">' + options.formatMessage({ id: "project.elements.map.selectParish" }) + '</option>';
        options.listParishes.forEach(par => {
          if (par.c.startsWith(jQuery('#centerCouncil').val())) {
            selector3Html += '<option value="' + par.c + '">' + par.n + '</option>';
          }
        });
        parishes.html(selector3Html);
        parishes.attr('disabled', false);
        entityPopulations.val("").change();
        entityPopulations.attr("disabled", true);
      } else {
        parishes.val("").change();
        parishes.attr('disabled', true);
        entityPopulations.val("").change();
        entityPopulations.attr("disabled", true);
      }
    }

  }

  EntityPopulationCenterControl.prototype.handleSelector3Control = function handleSelector3Control(e) {

    if (jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      let entityPopulations = jQuery('#centerEntityPopulation');
      if (jQuery('#centerParish').val() !== "") {
        let selector4Html = '<option value="">' + options.formatMessage({ id: "project.elements.map.selectEntityPopulation" }) + '</option>';
        options.listEntityPopulations.forEach(ep => {
          if (ep.c.startsWith(jQuery('#centerParish').val())) {
            selector4Html += '<option value="' + ep.b + '">' + ep.n + '</option>';
          }
        });
        entityPopulations.html(selector4Html);
        entityPopulations.attr('disabled', false);
      } else {
        entityPopulations.val("").change();
        entityPopulations.attr('disabled', true);
      }
    }

  }

  let createBoundingExtent = (bbox, buffer) => {
    bbox = bbox.split(",");
    bbox = bbox.map(coordinate => coordinate.split(" "));
    bbox = bbox.map(coordinate => coordinate.map(Number));
    let bboxExtent = boundingExtent([
      transform(bbox[0], 'EPSG:4326', 'EPSG:3857'),
      transform(bbox[1], 'EPSG:4326', 'EPSG:3857')
    ]);

    return createBuffer(bboxExtent, buffer);
  }

  EntityPopulationCenterControl.prototype.handleCenterProgressiveButton = function handleCenterProgressiveButton(e) {
    let provinces = jQuery('#centerProvince');
    let councils = jQuery('#centerCouncil');
    let parishes = jQuery('#centerParish');
    let entityPopulations = jQuery('#centerEntityPopulation');

    if (provinces.val() !== "" && councils.val() === "" && parishes.val() === "" && entityPopulations.val() === "") {
      let province = options.listProvinces.filter(province => province.c === provinces.val());
      this.getMap().getView().fit(
        createBoundingExtent(province[0].b, options.provincesBuffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    } else if (provinces.val() !== "" && councils.val() !== "" && parishes.val() === "" && entityPopulations.val() === "") {
      let council = options.listCouncils.filter(council => council.c === councils.val());
      this.getMap().getView().fit(
        createBoundingExtent(council[0].b, options.councilsBuffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    } else if (provinces.val() !== "" && councils.val() !== "" && parishes.val() !== "" && entityPopulations.val() === "") {
      let parish = options.listParishes.filter(parish => parish.c === parishes.val());
      this.getMap().getView().fit(
        createBoundingExtent(parish[0].b, options.parishesBuffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    } else if (provinces.val() !== "" && councils.val() !== "" && parishes.val() !== "" && entityPopulations.val() !== "") {
      this.getMap().getView().fit(
        createBoundingExtent(entityPopulations.val(), options.entityPopulationsBuffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    }
  }

  let searchCenterBbox = "";
  let buffer = 100;

  EntityPopulationCenterControl.prototype.handleInputSearch = function handleInputSearch(e) {
    searchCenterBbox = "";
    autocomplete({
      minLength: 3,
      input: document.getElementById('centerSearchInput'),
      fetch: function (text, update) {
        text = text.toLowerCase();
        var suggestions = options.listProvinces.filter(val => {
          return val.n.toLowerCase().includes(text)
        });
        suggestions = options.listCouncils.filter(val => {
          return val.n.toLowerCase().includes(text)
        });
        suggestions = suggestions.concat(options.listParishes.filter(val => {
          return val.n.toLowerCase().includes(text)
        }));
        suggestions = suggestions.concat(options.listEntityPopulations.filter(val => {
          return val.n.toLowerCase().includes(text)
        }));
        suggestions = suggestions.concat(options.listCounties.filter(val => {
          return val.n.toLowerCase().includes(text)
        }));
        update(suggestions);
      },
      render: function (item, currentValue) {
        var div = document.createElement('div');
        if (item.t === "EP" || item.t === "PA") {
          let councilCode = item.c.substring(0, 5);
          let councilName = options.listCouncils.filter(val => {
            return val.c === councilCode
          });
          item.label = "* " + item.n + " - " + councilName[0].n
        } else {
          item.label = "* " + item.n;
        }
        div.textContent = item.label;
        if (item.t === "PR") {
          item.group = options.formatMessage({ id: "project.elements.map.provinces" });
        } else if (item.t === "MU") {
          item.group = options.formatMessage({ id: "project.elements.map.councils" });
        } else if (item.t === "PA") {
          item.group = options.formatMessage({ id: "project.elements.map.parishes" });
        } else if (item.t === "EP") {
          item.group = options.formatMessage({ id: "project.elements.map.entityPopulations" });
        } else if (item.t === "CO") {
          item.group = options.formatMessage({ id: "project.elements.map.counties" });
        }

        return div;
      },
      onSelect: function (item, input) {
        input.value = item.label.substring(2, item.label.length);
        if (item.t === "PR") {
          buffer = options.provincesBuffer;
        } else if (item.t === "MU") {
          buffer = options.councilsBuffer;
        } else if (item.t === "PA") {
          buffer = options.parishesBuffer;
        } else if (item.t === "EP") {
          buffer = options.entityPopulationsBuffer;
        } else if (item.t === "CO") {
          buffer = options.countiesBuffer;
        }
        searchCenterBbox = item.b;
        jQuery('div.autocomplete').hide();
      },

    });
  }

  EntityPopulationCenterControl.prototype.handleCenterSearchButton = function handleCenterSearchButton(e) {
    jQuery('#centerSearchInput').removeClass('inputError');
    if (searchCenterBbox !== "") {
      this.getMap().getView().fit(
        createBoundingExtent(searchCenterBbox, buffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    } else {
      // Get input text, delete council name (if entity population or parish) and text to lower case
      let text = document.getElementById('centerSearchInput').value;
      if (text.split("-").length > 0) {
        text = text.split("-")[0].trim();
      }
      text = text.toLowerCase();

      let provinceSearch = options.listProvinces.filter(province => province.n.toLowerCase().includes(text));
      // Search on provinces
      if (provinceSearch.length > 0) {
        this.getMap().getView().fit(
          createBoundingExtent(provinceSearch[0].b, options.provincesBuffer),
          {
            size: this.getMap().getSize(),
            duration: 100
          }
        );
        // If not province, search on counties
      } else {
        let countySearch = options.listCounties.filter(county => county.n.toLowerCase().includes(text));
        if (countySearch.length > 0) {
          this.getMap().getView().fit(
            createBoundingExtent(countySearch[0].b, options.countiesBuffer),
            {
              size: this.getMap().getSize(),
              duration: 100
            }
          );
          // If not county, search on councils
        } else {
          let councilSearch = options.listCouncils.filter(council => council.n.toLowerCase().includes(text));
          if (councilSearch.length > 0) {
            this.getMap().getView().fit(
              createBoundingExtent(councilSearch[0].b, options.councilsBuffer),
              {
                size: this.getMap().getSize(),
                duration: 100
              }
            );
            // If not council, search on parishes
          } else {
            let parishSearch = options.listParishes.filter(parish => parish.n.toLowerCase().includes(text));
            if (parishSearch.length > 0) {
              this.getMap().getView().fit(
                createBoundingExtent(parishSearch[0].b, options.parishesBuffer),
                {
                  size: this.getMap().getSize(),
                  duration: 100
                }
              );
              // If not parish, search on population entities
            } else {
              let populationEntitySearch = options.listEntityPopulations.filter(ep => ep.n.toLowerCase().includes(text));
              if (populationEntitySearch.length > 0) {
                this.getMap().getView().fit(
                  createBoundingExtent(populationEntitySearch[0].b, options.entityPopulationsBuffer),
                  {
                    size: this.getMap().getSize(),
                    duration: 100
                  }
                );
                // If not population entity, show error
              } else {
                jQuery('#centerSearchInput').addClass('inputError');
              }
            }
          }
        }
      }
    }
  }

  return EntityPopulationCenterControl;

}(Control));