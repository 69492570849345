import React from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Errors } from '..'

const DeleteDialog = ({ modalShow, title, details, backendErrors, hideModalWindow, handleSubmit, setBackendErrors }) => {

    if (!modalShow) {
        return null;
    }

    return (
        <Modal show={modalShow} onClose={hideModalWindow} onHide={hideModalWindow} scrollable={true}>
            <Modal.Header closeButton>
                <ModalTitle>
                    {title}
                </ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <Errors errors={backendErrors} onClose={() => setBackendErrors(null)} />
                <p>{details}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary"
                    onClick={handleSubmit}
                >
                    <FormattedMessage id="project.common.delete" />
                </button>
                <button className="btn btn-danger"
                    onClick={hideModalWindow}
                >
                    <FormattedMessage id="project.app.Body.cancel" />
                </button>
            </Modal.Footer>
        </Modal>
    );
}

DeleteDialog.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    title: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired,
    backendErrors: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired,
    setBackendErrors: PropTypes.func.isRequired
}

export default DeleteDialog;